import React from 'react';
import ReactDOM from 'react-dom/client';
import './view/style/reset.scss';
import App from './App';
import './locales'
import axios from "axios";

axios.defaults.withCredentials = true; // withCredentials 전역 설정

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <App />
);

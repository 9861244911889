import React from 'react';
import Modal from "../../component-library/modal/Modal";
import './RecordingEndModal.scss';
import {useTranslation} from "react-i18next";
import Button from "../../component-library/button/Button";

type SignInModalProps = {
    isOpen: boolean;
    onClose: () => void;
    endRecording: () => void;
}

function RecordingEndModal({isOpen, onClose, endRecording}: SignInModalProps) {

    const {t} = useTranslation('translation', {keyPrefix: "RecordingEndModal"});
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} className={"recording-end-modal"}>
            <div className={"recording-end-modal-h1"}>{t('end-check')}</div> {/* 녹음을 종료할까요? */}
            <div className={"recording-end-modal-h2"}>{t('end-info')}</div> {/* 종료하면 음성을 스크립트로 변환합니다. */}
            <div className={"recording-end-modal-button-wrapper"}>
                <Button className={"recording-end-modal-cancel-button"} onClick={onClose}>
                    {t('no')} {/* "아니오" */}
                </Button>
                <Button onClick={() => {
                    endRecording()
                    onClose()
                }}>
                    {t('end')} {/* 종료하기 */}
                </Button>
            </div>
        </Modal>
    );
}

export default RecordingEndModal;

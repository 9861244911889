// src/view/component/note/ReferenceView.tsx

import React, {useCallback, useEffect, useRef} from 'react';
import './ReferenceView.scss'
import {Document, pdfjs} from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {useDebouncedCallback} from "use-debounce";
import DroppablePageView from "./DroppablePageView";
import {RxEnterFullScreen} from "react-icons/rx";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import ReferenceFullScreenView from "./ReferenceFullScreenView";
import {useDispatch, useSelector} from "react-redux";
import useTooltip from "../../../hook/useTooltip";
import {selectAllTextboxes, Textbox} from "../../../redux/appNote";
import {deleteTextboxAction} from "../../../action/appNoteTextboxAction";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {
    fullScreenButtonClickedEvent,
    pageScrolledEvent,
    pageKeyPressedEvent,
} from "../../../analytics/ga";
import {RiText} from "react-icons/ri";
import useTextMemo from "../../../hook/useTextMemo";

// 튜토리얼
import {RootState} from "../../../redux";
import TutorialOverlay from "../../component/tutorial/TutorialOverlay";
import {setTutorialStep, skipAllTutorials} from "../../../redux/noteTutorialSlice";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type ReferenceViewProps = {
    referenceFile?: File
    blocks: any[]
    setBlocks: (blocks: any) => void
    width: number
    focusedPageIndex: number
    setFocusedPageIndex: (args: any) => void,
    isKeyboardBlock: boolean
    setIsKeyboardBlock: (args: any) => void
    innerWidth: number
}

export const DEFAULT_WIDTH = 960;

export const DELETE_TEXTBOX_ID = -3;
export const NEW_TEXTBOX_ID = -2;
export const EDITING_TEXTBOX_ID = -1;

export const DEFAULT_TEXTBOX: Textbox = {
    id: EDITING_TEXTBOX_ID,
    noteId: 0,
    text: "",
    referenceFileIndex: -1,
    xLocation: 0,
    yLocation: 0,
    fontSize: 16,
    fontWeight: "normal",
    fontColor: "#FF0000",
    textBoxWidth: 360,
    textBoxHeight: 36,
    createdAt: "",
    updatedAt: "",
}

function ReferenceView({referenceFile, width, focusedPageIndex, setFocusedPageIndex, isKeyboardBlock, setIsKeyboardBlock, innerWidth}: ReferenceViewProps) {

    const { t } = useTranslation('translation', { keyPrefix: "ReferenceView" });

    const scrollViewerRef = useRef<any>(null);

    const appNoteTextboxes = useSelector(selectAllTextboxes)

    // 튜토리얼
    const dispatch = useDispatch();

    // 튜토리얼 상태 가져오기
    const { currentStep, refControl, memoInfo, noteTitle, scriptInfoRef } = useSelector((state: RootState) => state.noteTutorial);
    const firstRef = useRef<HTMLDivElement | null>(null); // 첫 번째 PDF 페이지 참조
    const memoButtonRef = useRef<HTMLButtonElement | null>(null);// 메모 버튼 참조

    // refControl 튜토리얼 완료 처리 함수
    const handlerefControlCompleteTutorial = useCallback(() => {
        dispatch(setTutorialStep('refControl'));
    }, [dispatch]);

    // memoInfo 튜토리얼 완료 처리 함수
    const handlememoInfoCompleteTutorial = useCallback(() => {
        dispatch(setTutorialStep('memoInfo'));
    }, [dispatch]);

    // 모든 튜토리얼 건너뛰기 처리 함수
    const handleSkipTutorial = useCallback(() => {
        // @ts-ignore
        dispatch(skipAllTutorials());
    }, [dispatch]);

    // '텍스트박스' 툴팁
    const memoTooltip = useTooltip({
        modalComponent: ({ ref }) => (
        <div ref={ref} className="tooltip">{t('text-box')}</div> /* 텍스트 박스 */
        ),
    });

    // '전체화면' 툴팁
    const fullscreenTooltip = useTooltip({
        modalComponent: ({ ref }) => (
        <div ref={ref} className="tooltip">{t('full-screen')}</div> /* 전체화면 */
        ),
    });

    const {
        ratio,
        editingTextBoxRef,
        onChangeTextboxSize,
        changeEditingTextBox,
        createTextBox,
        dragBind,
        onDropScriptBlock,
        editingTextBox,
        setEditingTextBox,
        isActiveTextMemo,
        setIsActiveTextMemo,
        movingVector,
        setLastFont,
        useColorPicker,
        setUseColorPicker
    } = useTextMemo({
        width
    })

    const [pdfInfo, setPdfInfo] = React.useState<{
        numPages: number
    }>({
        numPages: 0,
    });
    const [pageWidth, setPageWidth] = React.useState<number>(100);
    const [pageHeight, setPageHeight] = React.useState<number>(100);

    const [lastScrollTop, setLastScrollTop] = React.useState<number>(0)

    const onFileLoad = useCallback((pdf: any) => {
        setPdfInfo(pdf._pdfInfo)
        setFocusedPageIndex(0)
    }, [])

    useEffect(() => {
        setPageWidth(width - 8)
    }, [width])

    const onScroll = useDebouncedCallback((e: any) => {
        let focusedPageNum = focusedPageIndex
        if (lastScrollTop - e.target?.scrollTop < 0) {
            focusedPageNum = Math.ceil((e.target?.scrollTop - pageHeight / 2) / (pageHeight + 17))
        }
        if (lastScrollTop - e.target?.scrollTop > 0) {
            focusedPageNum = Math.floor((e.target?.scrollTop + pageHeight / 2) / (pageHeight + 17))
        }
        setLastScrollTop(e.target?.scrollTop)
        setFocusedPageIndex(focusedPageNum)
        pageScrolledEvent(focusedPageNum); // 스크롤에 의한 페이지 넘김 이벤트 트래킹
    }, 100)

    const fullscreenHandle = useFullScreenHandle();

    useEffect(() => {
        let onKeydown: any;

        if (!isKeyboardBlock) {
            onKeydown = (e: KeyboardEvent) => {
                if (e.key === 'ArrowRight') {
                    const nextPage = Math.min(focusedPageIndex + 1, pdfInfo?.numPages - 1);
                    scrollViewerRef.current.scrollTo({
                        top: (pageHeight + 17) * nextPage,
                    });
                    pageKeyPressedEvent(nextPage); // 키보드로 페이지 넘김 이벤트 트래킹
                }
                if (e.key === 'ArrowLeft') {
                    const prevPage = Math.max(focusedPageIndex - 1, 0);
                    scrollViewerRef.current.scrollTo({
                        top: (pageHeight + 17) * prevPage,
                    });
                    pageKeyPressedEvent(prevPage); // 키보드로 페이지 넘김 이벤트 트래킹
                }
                if (e.key === 'Backspace') {
                    if (editingTextBox.id > 0) {
                        deleteTextboxAction({textboxId: editingTextBox.id}).then((response) => {
                            if (response) {
                                changeEditingTextBox({textboxId: DELETE_TEXTBOX_ID})
                            }
                        })
                    }
                }
                if (fullscreenHandle.active){
                    if (e.key === 'ArrowUp') {
                        const prevPage = Math.max(focusedPageIndex - 1, 0);
                        scrollViewerRef.current.scrollTo({
                            top: (pageHeight + 17) * prevPage,
                        });
                        pageKeyPressedEvent(prevPage); // 키보드로 페이지 넘김 이벤트 트래킹
                    }
                    if (e.key === 'ArrowDown' || e.key === ' ' || e.key === 'Enter') {
                        const nextPage = Math.min(focusedPageIndex + 1, pdfInfo?.numPages - 1);
                        scrollViewerRef.current.scrollTo({
                            top: (pageHeight + 17) * nextPage,
                        });
                        pageKeyPressedEvent(nextPage); // 키보드로 페이지 넘김 이벤트 트래킹
                    }
                }
            }

            window.addEventListener('keydown', onKeydown)
        }

        return () => {
            window.removeEventListener('keydown', onKeydown)
        }
    }, [focusedPageIndex, isKeyboardBlock, pageHeight, pdfInfo?.numPages, setFocusedPageIndex, editingTextBox, changeEditingTextBox, fullscreenHandle.active]);

    useEffect(() => {
        if (focusedPageIndex === -1) {
            return
        }
        scrollViewerRef.current.scrollTo({
            top: (pageHeight + 17) * focusedPageIndex,
            behavior: 'smooth',
        })
    }, [focusedPageIndex, pageHeight]);

    return (
        <>
            <div style={{display: fullscreenHandle.active ? 'initial' : 'none'}}>
                <FullScreen handle={fullscreenHandle}>
                    <ReferenceFullScreenView referenceFile={referenceFile}
                                             numPages={pdfInfo.numPages}
                                             focusedPageIndex={focusedPageIndex}
                                             onExitFullScreen={() => { fullscreenHandle.exit() }}
                                             pageRatio={pageWidth / pageHeight}
                                             setIsKeyboardBlock={setIsKeyboardBlock}
                                             innerWidth={innerWidth}
                    />
                </FullScreen>
            </div>
            <div className={cn('reference-view')} ref={scrollViewerRef} onScroll={onScroll}>
                {referenceFile ?
                    <Document file={referenceFile} onLoadSuccess={onFileLoad} loading={<></>}>
                        <div className={"reference-view-page-list"}>
                            {pdfInfo && Array.from(new Array(pdfInfo.numPages), (el, index) => (
                                <DroppablePageView
                                    key={`page_${index + 1}`}
                                    index={index}
                                    pageWidth={pageWidth - 12}
                                    ratio={ratio}
                                    textBoxes={[
                                        ...appNoteTextboxes.filter(textbox => textbox.referenceFileIndex === index && textbox.id !== editingTextBox.id),
                                        ...(editingTextBox.referenceFileIndex === index ? [{
                                            ...editingTextBox,
                                            isEdit: true
                                        }] : [])
                                    ]}
                                    editingTextBoxRef={editingTextBoxRef}
                                    setEditingTextBox={setEditingTextBox}
                                    onChangeTextboxSize={onChangeTextboxSize}
                                    changeEditingTextBox={changeEditingTextBox}
                                    createTextBox={createTextBox}
                                    movingVector={movingVector}
                                    dragBind={dragBind} focusedPageIndex={focusedPageIndex}
                                    onDrop={(item) => onDropScriptBlock(item.block, index)}
                                    setLastFont={setLastFont}
                                    setPageHeight={setPageHeight}
                                    setUseColorPicker={setUseColorPicker}
                                    useColorPicker={useColorPicker}
                                    setIsKeyboardBlock={setIsKeyboardBlock}
                                    isActiveTextMemo={isActiveTextMemo}
                                    {...(index === 0 && {ref: firstRef})}
                                />
                            ))}
                        </div>
                    </Document>
                    :
                    <div className={'reference-view-empty'}>
                        <h3>{t('no-ref-warn')}</h3>
                        <p>{t('upload-ref-warn')}</p>
                    </div>
                }
                <div className={"reference-view-toolbar"} style={{left: pageWidth + 4}}>
                    <button
                        className={cn("reference-view-toolbar-button", {active: isActiveTextMemo})}
                        onClick={() => {
                            setIsActiveTextMemo(!isActiveTextMemo)
                        }}
                        onMouseEnter={memoTooltip.onActive}
                        onMouseLeave={memoTooltip.onInactive}
                        ref={memoButtonRef}
                        >
                        {memoTooltip.modalComponent}
                        <RiText />
                    </button>
                    <button
                        className={"reference-view-toolbar-button"}
                        onClick={() => {
                            fullScreenButtonClickedEvent();  // 전체화면 버튼 클릭 이벤트 트래킹
                            fullscreenHandle.enter();
                        }}
                        onMouseEnter={fullscreenTooltip.onActive}
                        onMouseLeave={fullscreenTooltip.onInactive}
                        >
                        {fullscreenTooltip.modalComponent}
                        <RxEnterFullScreen/>
                    </button>
                </div>

                {/* refControl 튜토리얼 - referenceFile 있을 때 */}
                {scriptInfoRef && noteTitle && !refControl && (
                    <TutorialOverlay
                        content={t('ref-control-tutorial')} //좌우 방향키로 페이지를 넘길 수 있어요
                        onComplete={handlerefControlCompleteTutorial}
                        onSkip={handleSkipTutorial}
                        targetRef={firstRef}
                        tutorialName="refControl"
                    />
                )}

                {/* memoInfo 튜토리얼 - referenceFile 있을 때 */}
                {refControl && !memoInfo && (
                    <TutorialOverlay
                        content={t('memo-info-tutorial')} // 메모 활성화 버튼을 눌러 \\n PDF 위에 메모를 추가하세요
                        onComplete={handlememoInfoCompleteTutorial}
                        onSkip={handleSkipTutorial}
                        targetRef={memoButtonRef}
                        tutorialName="memoInfo"
                    />
                )}
            </div>
        </>

    );
}

export default ReferenceView;

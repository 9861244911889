import React, {useCallback, useMemo, useState} from 'react';
import './SettingNote.scss'
import Toggle from "react-toggle";
import "react-toggle/style.css"
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectSettingByType, SettingData} from "../../../redux/setting";
import {RootState} from "../../../redux";
import {activateSetting, deactivateSetting} from "../../../action/settingAction";

const NOTE_SETTING_TYPE = 1

function SettingNote() {

    const {t: settingTranslation} = useTranslation('translation', {keyPrefix: "_setting"});

    const noteSettings = useSelector((state: RootState) => selectSettingByType(state, NOTE_SETTING_TYPE))

    const translatedSettingsData = useMemo(() => {
        return noteSettings.map(setting => {
            return {
                ...setting,
                name: settingTranslation(setting.name),
                description: settingTranslation(setting.description)
            }
        })
    }, [noteSettings, settingTranslation])

    const handleSetting = useCallback(async (setting: SettingData, isActive: boolean) => {
        if (isActive && setting.activeSettingId) {
            await deactivateSetting({activeSettingId: setting.activeSettingId})
            return
        }
        await activateSetting({settingId: setting.id})
    }, [])

    return (
        <div className={"setting-note"}>
            {translatedSettingsData.map(setting => {
                return (<div className={"setting-note-row"}>
                    <div className={"setting-note-label"}>
                        <span className={"setting-note-title"}>
                            {setting.name}
                        </span>
                        <span className={"setting-note-description"}>
                            {setting.description}
                        </span>
                    </div>
                    <Toggle
                        checked={setting.isActive}
                        // defaultChecked={setting.active}
                        icons={false}
                        onChange={() => handleSetting(setting, setting.isActive)}/>
                </div>)
            })}
        </div>
    );
}

export default SettingNote;

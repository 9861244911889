import {API} from "../class";
import {NotifaiServer} from "../index";
import joi from "joi";
import {TextBoxFromDB} from "./type";

export const createTextboxAPI = new API<
    {
        body: {
            note_id: number;
            reference_file_index: number;
            text: string;
            x_location: number;
            y_location: number;
            font_size: number;
            font_weight: string;
            font_color: string;
            text_box_width: number;
            text_box_height: number;
        }
    },
    TextBoxFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/text-boxes/",
    method: "post",
    requestValidator: joi.object({
        body: joi.object({
            note_id: joi.number().required(),
            reference_file_index: joi.number().required(),
            x_location: joi.number().required(),
            y_location: joi.number().required(),
            text: joi.string().allow(""),
            font_size: joi.number().required(),
            font_weight: joi.string().required(),
            font_color: joi.string().required(),
            text_box_width: joi.number().required(),
            text_box_height: joi.number().required(),
        }),
    }),
});

export const updateTextboxAPI = new API<
    {
        params: {
            textboxId: number;
        },
        body: {
            note_id: number;
            reference_file_index: number;
            text: string;
            x_location: number;
            y_location: number;
            font_size: number;
            font_weight: string;
            font_color: string;
            text_box_width: number;
            text_box_height: number;
        }
    },
    TextBoxFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/text-boxes/:textboxId",
    method: "put",
    requestValidator: joi.object({
        params: joi.object({
            textboxId: joi.number().required(),
        }),
        body: joi.object({
            note_id: joi.number().required(),
            reference_file_index: joi.number().required(),
            x_location: joi.number().required(),
            y_location: joi.number().required(),
            text: joi.string().allow(""),
            font_size: joi.number().required(),
            font_weight: joi.string().required(),
            font_color: joi.string().required(),
            text_box_width: joi.number().required(),
            text_box_height: joi.number().required(),
        }),
    }),
});

export const deleteTextboxAPI = new API<
    {
        params: {
            textboxId: number;
        },
    },
    any
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/text-boxes/:textboxId",
    method: "delete",
    requestValidator: joi.object({
        params: joi.object({
            textboxId: joi.number().required(),
        })
    }),
});

export const getTextboxesByPageIndexAPI = new API<
    {
        params: {
            noteId: number;
        },
        query: {
            reference_file_index: number;
        }
    },
    TextBoxFromDB[]
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/text-boxes/:noteId/",
    method: "get",
    requestValidator: joi.object({
        params: joi.object({
            noteId: joi.number().required(),
        }),
        query: joi.object({
            reference_file_index: joi.number().required(),
        }),
    }),
});
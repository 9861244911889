import React from 'react';
import './ReferenceInfoTooltips.scss';
import i18n from "i18next"

function ReferenceInfoTooltips({ onInactive, ref }: { onInactive: () => void; ref: any }) {

  return (
    <div className="tooltip-component" ref={ref}>
      <div className="tooltip-content">
        {i18n.t('NoteInit.pdf-upload')}
      </div>
    </div>
  );
}

export default ReferenceInfoTooltips;
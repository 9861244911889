// src/view/component/note/NoteItem.tsx

import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import './NoteItem.scss';
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {selectSubject} from "../../../redux/folderAndSubject";
import {RootState} from "../../../redux";
import {useTranslation} from "react-i18next";
import {deleteNoteAction, updateNoteAction} from "../../../action/appNoteAction";
import {AppNote} from "../../../redux/appNote";
import ItemMenu from "../menu/ItemMenu";
import {toast} from "react-toastify";
import ConfirmModal from "../modal/ConfirmModal";
import {DUMMY_SUBJECT} from "../nav/LeftNav";
import cn from "classnames";
import SignInModal from "../modal/SignInModal";

type NoteItemProps = {
    note: AppNote;
}

function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}

function NoteItem({note}: NoteItemProps) {

    const {t} = useTranslation('translation', {keyPrefix: "NoteItem"});

    const navigate = useNavigate()

    const appUser = useSelector((state: RootState) => state.appUser);
    const subject = useSelector((state: RootState) => selectSubject(state, note?.subjectId))
    const subjectData = useMemo(() => {
        if (!appUser.id) {
            return DUMMY_SUBJECT
        }
        return subject
    }, [appUser, subject])

    const [isEditName, setIsEditName] = React.useState<boolean>(false)
    const [editingName, setEditingName] = React.useState<string>(note.name)

    useEffect(() => {
        if (!isEditName) {
            setEditingName(note.name)
        }
    }, [note.name, isEditName]);

    const updateNote = useCallback(async () => {

        if (editingName === "" || editingName === "\n") {
            setEditingName(note.name)
            setIsEditName(false)
            toast.warning(t('note-title-toast')); // 노트명을 입력해주세요.
            return
        }

        if (editingName.length > 20) {
            setEditingName(note.name)
            setIsEditName(false)
            toast.warning(t('note-title-limit-toast')); // 노트명은 20자 이내로 입력해주세요.
            return
        }

        if (editingName === note.name) {
            setIsEditName(false)
            return
        }

        await updateNoteAction({noteId: note.id, name: editingName})
    }, [editingName, note])

    const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState<boolean>(false)

    const deleteNote = useCallback(async () => {
        await deleteNoteAction({noteId: note.id})
    }, [note])

    const {
        render
    } = ItemMenu({
        active: true,
        onEditName: e => {
            setIsEditName(true)
            setTimeout(() => {
                inputRef.current?.select()
            }, 10)
        },
        onDelete: () => {
            setIsOpenDeleteModal(true)
        }
    })

    const inputRef = React.useRef<HTMLInputElement>(null)

    const [isOpenSignInModal, setIsOpenSignInModal] = React.useState<boolean>(false)

    if (note.id === DUMMY_SUBJECT.id) {
        return (
            <>
                <SignInModal isOpen={isOpenSignInModal} onClose={() => {
                    setIsOpenSignInModal(false)
                }}/>
                <button className={'note-item'} onClick={() => {
                    setIsOpenSignInModal(true)
                }}>

                    <div className={'note-item-thumbnail-wrapper'}>
                        {note.thumbnailS3Path ?
                            <img className={'note-item-thumbnail'} src={note.thumbnailS3Path} alt={'note-thumbnail'}/>
                            :
                            note.previewText ?
                                <div className={'note-item-preview-text'}>
                                    {note.previewText}
                                </div>
                                :
                                <div className={'note-item-no-thumbnail'}>
                                    {t('ref-info')} {/* 강의자료를 올리고\n녹음을 시작해보세요! */}
                                </div>
                        }
                    </div>
                    <div className={'note-item-content'}>
                        <div className={'note-item-content-header'}>
                            {isEditName ?
                                <input ref={inputRef}
                                       className={'note-item-title-input'}
                                       value={editingName}
                                       onChange={(e) => setEditingName(e.target.value)}
                                       onClick={(e) => {
                                           e.preventDefault()
                                           e.stopPropagation()
                                       }}
                                       onKeyDown={async (e) => {
                                           if (e.key === 'Enter') {
                                               setIsEditName(false)
                                               await updateNote()
                                           }
                                       }}
                                       maxLength={30}
                                />
                                :
                                <div className={'note-item-title'}>{note.name}</div>
                            }
                        </div>
                        <div className={"note-item-content-bottom"}>
                            <div className={'note-item-date'}>
                                {dayjs(note.updatedAt).format('YYYY.M.D')}
                            </div>
                            {subjectData?.name && <div className={'note-item-subject'}
                                                       style={{
                                                           backgroundColor: `rgba(${hexToRgb(subjectData.color)}, 0.7)`,
                                                           borderColor: subjectData.color,
                                                       }}
                            >
                                {subjectData.name}
                            </div>}
                        </div>
                    </div>
                </button>
            </>
        )
    }

    return (
        <>
            <ConfirmModal title={t('note-delete')} // 노트 삭제
                          subtitle={t('note-delete-confirm')} // 정말로 삭제하시겠습니까?
                          isOpen={isOpenDeleteModal}
                          onClose={() => setIsOpenDeleteModal(false)}
                          onConfirm={deleteNote}/>
            <button className={'note-item'} onClick={() => {
                if (!isEditName && !isOpenDeleteModal) {
                    navigate(`/notes/${note.id}`)
                }
            }}>

                    <div className={'note-item-thumbnail-wrapper'}>
                        {note.thumbnailS3Path ?
                            <img className={'note-item-thumbnail'} src={note.thumbnailS3Path} alt={'note-thumbnail'}/>
                            :
                            note.previewText ?
                                <div className={'note-item-preview-text'}>
                                    {note.previewText}
                                </div>
                                :
                                <div className={'note-item-no-thumbnail'}>
                                    {t('ref-info')} {/* 강의자료를 올리고\n녹음을 시작해보세요! */}
                                </div>
                        }
                    </div>
                    <div className={'note-item-content'}>
                        <div className={'note-item-content-header'}>
                            {isEditName ?
                                <input ref={inputRef}
                                       className={'note-item-title-input'}
                                       value={editingName}
                                       onChange={(e) => setEditingName(e.target.value)}
                                       onClick={(e) => {
                                           e.preventDefault()
                                           e.stopPropagation()
                                       }}
                                       onKeyDown={async (e) => {
                                           if (e.key === 'Enter') {
                                               setIsEditName(false)
                                               await updateNote()
                                           }
                                       }}
                                       maxLength={30}
                                />
                                :
                                <div className={'note-item-title'}>{note.name}</div>
                            }
                            {render()}
                        </div>
                        <div className={"note-item-content-bottom"}>
                            <div className={'note-item-date'}>
                                {dayjs(note.updatedAt).format('YYYY.M.D')}
                            </div>
                            {subjectData?.name && <div className={'note-item-subject'}
                                                       style={{
                                                       backgroundColor: `rgba(${hexToRgb(subjectData.color)}, 0.7)`,
                                                       borderColor: subjectData.color,
                                                   }}
                            >
                                {subjectData.name}
                            </div>}
                        </div>
                    </div>
            </button>
        </>
    );
}

export default NoteItem;
{}

import React from 'react';
import './Footer.scss';
import cn from "classnames";
import {Link} from "react-router-dom";
import {footer_logo} from "../../asset/image"
import {useTranslation} from "react-i18next";

function Footer() {

    const {t} = useTranslation("translation", {keyPrefix: "Footer"})

    return (
        <footer id={"Footer"}>
            <Link to={'/'}>
                <img className={"footer-logo"} src={footer_logo} alt={'logo'}/>
            </Link>
            <div className={"footer-links"}>
                <a href={'https://notifai.notion.site/Notifai-105f6ff037e6801fb64bc684033ff411?pvs=4'}
                   className={cn("footer-link")}
                   target={"_blank"}
                   rel={"noreferrer"}
                >
                    {t('privacy')}
                </a>
                <a href={'https://notifai.notion.site/Notifai-0115b9a8bd8048adb2dd92fbff7d0436?pvs=4'}
                   className={cn("footer-link")}
                   target={"_blank"}
                   rel={"noreferrer"}
                >
                    {t('terms')}
                </a>
            </div>
            <a href={'https://slashpage.com/notifai-community'}
               className={"footer-contact"}
               target={"_blank"}
               rel={"noreferrer"}
            >
                {t('contact')}
            </a>
        </footer>
    );
}

export default Footer;

import React, {useState, useEffect} from 'react';
import Modal from "../../component-library/modal/Modal";
import './AccountDeletionWarningModal.scss';
import Button from "../../component-library/button/Button";
import {deleteUserAction} from "../../../action/appUserAction";
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { Trans } from 'react-i18next';

type AccountDeletionWarningModalProps = {
    isOpen: boolean;
    onClose: () => void;
    finalReasons: string[]; // 삭제 사유
};

function AccountDeletionWarningModal({ isOpen, finalReasons, onClose }: AccountDeletionWarningModalProps) {

    const {t} = useTranslation("translation", {keyPrefix: "AccountDeletionWarningModal"})

    const [isConfirmed, setIsConfirmed] = useState(false);
    const navigate = useNavigate();  // 리다이렉트 사용

    // 모달이 닫힐 때마다 상태 초기화
    useEffect(() => {
        if (!isOpen) {
            setIsConfirmed(false); // 체크박스 초기화
        }
    }, [isOpen]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsConfirmed(event.target.checked);
    };

    // 계정 삭제 API 호출
    const handleDeleteAccount = async () => {
        try {
            await deleteUserAction(finalReasons);  // 삭제 API 호출
            navigate('/');
        } catch (error) {
            console.error("계정 삭제 중 오류 발생:", error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} className={"account-deletion-warning-modal"}>
            <div className={"account-deletion-warning-h1"}>
                {t('note')}  {/* 유의사항 */}
            </div>
            <div className={"account-deletion-warning-body"}>
                <ol>
                <Trans i18nKey="warning" t={t}>
                    <li>계정 삭제 시 모든 계정 정보 및 서비스 이용기록은 삭제되어 <strong>복구가 불가능</strong>합니다.</li>
                    <li>계정 삭제를 요청하시면 <strong>30일의 유예기간</strong> 후 삭제 처리됩니다. 즉시 삭제를 원하시면 <a href="https://notifai.channel.io">[1:1 문의]</a>를 남겨주세요.</li>
                    <li>유예기간 중 노티파이에 로그인하시면 <strong>계정 삭제</strong>가 취소됩니다.</li>
                </Trans>
                </ol>
            </div>
            <div className={"account-deletion-warning-checkbox"}>
                <label>
                    <input type="checkbox" onChange={handleCheckboxChange} />
                    {t('check')} {/* 위 유의사항을 모두 확인하였으며, 이에 동의합니다. */}
                </label>
            </div>
            <div className={"account-deletion-warning-button-wrapper"}>
                <Button className={"cancel-button"} onClick={onClose}>
                    {t('cancel')}  {/* 취소 */}
                </Button>
                <Button
                    className={"delete-button"}
                    onClick={handleDeleteAccount} // 계정 삭제 호출
                    disabled={!isConfirmed}
                >
                    {t('delete-account')}  {/* 계정 삭제 */}
                </Button>
            </div>
        </Modal>
    );
}

export default AccountDeletionWarningModal;
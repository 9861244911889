import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './NoteRecorder.scss';
import cn from "classnames";
import {useTranslation} from "react-i18next";
import useBlockPageExit from "../../../hook/useBlockPageExit";
import recordingStateStop from "../../asset/image/recording-state-default.png"
import recordingStateEnd from "../../asset/image/recording-state-stop.png"
import recordingStopWave from "../../asset/image/recording-wave-stop.png"
import recordingGif from "../../asset/image/recording.gif"
import {
    createRecordingSessionAction,
    pollingNoteContentsAction,
    uploadAudioRealtimeAction,
    uploadWholeAudioAction
} from "../../../action/appNoteAction";
import RecordingEndModal from "../modal/RecordingEndModal";
import {useSelector} from "react-redux";
import {RecordingSession} from "../../../redux/appNote";
import {selectAllAudios} from "../../../redux/noteAudio";
import {
    recordingPausedEvent,
    recordingResumedEvent,
    recordingEndedEvent,
} from "../../../analytics/ga";
import arrowDown from "../../asset/image/arrow-down.png";
import Select from "../../component-library/input/Select";
import {selectAllLanguages} from "../../../redux/languages";
import {HiOutlineLanguage} from "react-icons/hi2";
import {secondToTimestamp} from "../../../util/timeUtils";
import useRecorderForVad from "../../../hook/useRecorderWithVad";
import AudioUploadModal from "../modal/AudioUploadModal";

type NoteRecorderProps = {
    focusedPageIndex: number
    setIsLecture: (args: any) => void
    isRecording: boolean
    setIsRecording: (args: any) => void
    isNewRecording: boolean
    setIsNewRecording: (args: any) => void
    noteSourceLanguageId: number
    setNoteSourceLanguageId: (args: any) => void
    recordingTimestamp: number
    setRecordingTimestamp: (args: any) => void
}

const DEFAULT_RECORDING_INTERVAL = 29800;
const MAX_RECORDING_TIME = 5400; // 1시간 30분

function NoteRecorder({
                          focusedPageIndex,
                          setIsLecture,
                          isRecording,
                          setIsRecording,
                          isNewRecording,
                          setIsNewRecording,
                          noteSourceLanguageId,
                          setNoteSourceLanguageId,
                          recordingTimestamp,
                          setRecordingTimestamp
                      }: NoteRecorderProps) {

    const {t} = useTranslation('translation', {keyPrefix: 'NoteRecorder'});

    const languages = useSelector(selectAllLanguages)
    const note = useSelector((state: any) => state.appNote)
    const allAudios = useSelector(selectAllAudios)

    const recordingPageIndexRef = useRef<number>(-1)
    const recordingSessionIdRef = useRef<number>()

    const [recordingSessionId, setRecordingSessionId] = useState<number>()
    const [isOpenRecordingEndModal, setIsOpenRecordingEndModal] = useState<boolean>(false)

    const [isHide, setIsHide] = useState<boolean>(false)
    const [isUploadAudioFile, setIsUploadAudioFile] = useState<boolean>(false)

    const recordingProps = useMemo(() => ({
        onInterval: async (audioFile: Blob, recordingTime: number, wholeRecordingTime: number) => {

            if (!recordingSessionIdRef.current) {
                console.log('recordingSessionId is not exist')
                return false
            }

            if (audioFile.size === 0) {
                console.log('audioFile is empty')
                return false
            }

            await uploadAudioRealtimeAction({
                audioFile,
                audioFileName: `recording-${Date.now()}.wav`,
                audioFileLength: recordingTime,
                recordingSessionId: recordingSessionIdRef.current,
                referenceFilePageIndex: recordingPageIndexRef.current,
                timestamp: wholeRecordingTime
            })

            return true
        },
        onStop: async (audioFile: Blob, recordingTime: number) => {

            if (!recordingSessionIdRef.current) {
                console.log('recordingSessionId is not exist')
                return
            }
            setIsUploadAudioFile(true)
            const result = await uploadWholeAudioAction({
                audioFile,
                // audioFileName: `recording-${Date.now()}.wav`,
                audioFileName: `recording-${Date.now()}.mp3`,
                audioFileLength: recordingTime,
                recordingSessionId: recordingSessionIdRef.current
            })

            console.log(result)

            recordingSessionIdRef.current = undefined
            setIsUploadAudioFile(false)
            // setFullAudioFile({
            //     audio: audioFile,
            //     filename: `${dayjs().format('YYYY-MM-DD_HH-mm-ss')}.webm`
            // });

            // // Convert the audio file to MP3 format
            // // const mp3File = new Blob([audioFile], { type: 'audio/mp3' });
            //
            // // Create a URL for the MP3 file
            // const mp3URL = URL.createObjectURL(audioFile);
            //
            // // Create a temporary link element
            // const tempLink = document.createElement('a');
            // tempLink.href = mp3URL;
            // tempLink.setAttribute('download', `full-record-${note.name}.mp3`);
            //
            // // Trigger a click event on the link to start the download
            // tempLink.click();
            //
            // // Clean up the URL object
            // URL.revokeObjectURL(mp3URL);
        },
        interval: DEFAULT_RECORDING_INTERVAL
    }), [])

    const recording = useRecorderForVad(recordingProps)

    useEffect(() => {
        if (recording.isRecording && recordingPageIndexRef.current !== focusedPageIndex) {
            recordingPageIndexRef.current = focusedPageIndex
        }
    }, [recording, focusedPageIndex]);

    const recordingStop = useCallback(async () => {
        await recording.stop()
        setIsRecording(false)
    }, [recording, setIsRecording])

    const onCreateRecordingSession = useCallback(async () => {
        if (isRecording) {
            return;
        }
        setIsRecording(true)

        const response = (await createRecordingSessionAction({
            noteId: note.id,
            languageId: noteSourceLanguageId
        })) as RecordingSession

        recordingSessionIdRef.current = response.id
        setRecordingSessionId(response.id)

        await recording.start()

    }, [isRecording, note.id, noteSourceLanguageId, recording, setIsRecording])

    const onHandleRecordingState = useCallback(async () => {
        if (recording.isRecording !== isRecording) {
            return
        }

        if (isRecording) {
            recordingPausedEvent();  // 녹음 일시중지 이벤트 호출
            await recordingStop()
            return
        }

        recordingResumedEvent();  // 녹음 재시작 이벤트 호출
        await onCreateRecordingSession()
    }, [isRecording, onCreateRecordingSession, recording.isRecording, recordingStop])

    const onChangeLanguage = useCallback(async (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNoteSourceLanguageId(parseInt(e.target.value))
        if (isRecording) {
            await recordingStop()
            setIsNewRecording(true)
        }
    }, [isRecording, recordingStop, setIsNewRecording, setNoteSourceLanguageId])

    useEffect(() => {
        if (isNewRecording && recording.isLoaded) {
            setIsNewRecording(false)
            onCreateRecordingSession()
        }
    }, [isNewRecording, onCreateRecordingSession, setIsNewRecording, recording.isLoaded]);

    useEffect(() => {
        let polling: any;

        if (recordingSessionId && isRecording) {
            const pollingFunction = async () => {
                if (recordingSessionId && recordingSessionIdRef.current) {
                    await pollingNoteContentsAction(recordingSessionIdRef.current)
                }
            }

            polling = setInterval(pollingFunction, 1000)

            return () => {
                setTimeout(() => {
                    clearInterval(polling)
                }, 60000)
            }
        }

        return () => {
            clearInterval(polling)
        }
    }, [recordingSessionId, isRecording]);

    useEffect(() => {
        const sumRecordingTime = Math.round(allAudios
            .filter((audio: RecordingSession) => audio.id !== recordingSessionIdRef.current)
            .reduce((sum: number, audio: RecordingSession) => sum + audio.wholeAudioLength, 0) / 1000) + (recording.recordingTime ?? 0)

        if (recordingTimestamp < sumRecordingTime) {
            setRecordingTimestamp(sumRecordingTime)
        }
    }, [allAudios, setRecordingTimestamp, recording.recordingTime, recordingSessionId, recordingTimestamp]);

    // 최대 녹음 시간 체크
    useEffect(() => {
        if (recording.recordingTime >= MAX_RECORDING_TIME) {
            recording.stop();
            alert(t("maximum-recording-time"))
        }
    }, [recording, t])

    // 녹음 중 페이지 이동 방지
    const blockPageExitProps = useMemo(() => ({
        active: recording.isRecording,
        message: t("sure-leaving-record"),
        unmount: recording.stop
    }), [recording.isRecording, recording.stop, t])
    useBlockPageExit(blockPageExitProps)

    return (
        <div className={"note-recorder-wrapper"}>
            {/*{isUploadAudioFile && <div className={"note-recorder-uploading"} />}*/}
            <AudioUploadModal isOpen={isUploadAudioFile} onClose={() => {}} />
            <RecordingEndModal isOpen={isOpenRecordingEndModal}
                               onClose={() => setIsOpenRecordingEndModal(false)}
                               endRecording={async () => {
                                   await recordingStop()
                                   setIsLecture(false)
                               }}/>
            {!isHide && <div className={"note-recorder"}>
                <button className={"note-recorder-state-button"}
                        onClick={onHandleRecordingState}
                >
                    <div className={"note-recorder-state-frame"}>
                        <img className={"note-recorder-state-icon"}
                             src={isRecording ? recordingStateStop : recordingStateEnd}
                             alt={"recording-state"}/>
                    </div>
                </button>

                <img className={cn("note-recorder-gif", {stop: !isRecording})}
                     src={isRecording ? recordingGif : recordingStopWave}
                     alt={"recording-state"}/>

                <span className={cn("note-recorder-state-text", {stop: !isRecording})}>
                    {isRecording ? t("now-recording") : t("now-stop-recording")}
                </span>

                <div className={"note-recorder-language"}>
                    <div className={"note-recorder-language-icon"}>
                        <HiOutlineLanguage/>
                    </div>
                    <Select id={"LanguageSelect"}
                            className={`note-recorder-language-select ${isRecording ? 'recording' : ''}`}  // 녹음 중일 때 'recording' 클래스 추가
                            options={languages}
                            labelKey={"name"}
                            valueKey={"id"}
                            value={noteSourceLanguageId?.toString()}
                            onChange={onChangeLanguage}/>
                </div>

                <span className={"note-recorder-time"}>
                    {secondToTimestamp(recordingTimestamp)}
                </span>
                <button className={"note-recorder-end-button"}
                        onClick={() => {
                            recordingEndedEvent();  // 녹음 종료 이벤트 호출
                            setIsOpenRecordingEndModal(true);
                        }}>
                    {t("recording-end")}
                </button>
            </div>}
            <button className={"note-recorder-drop-button"} onClick={() => setIsHide(prev => !prev)}>
                <img src={arrowDown} alt={'arrow'} className={cn("note-recorder-drop-button-icon", {hide: isHide})}/>
            </button>
        </div>
    );
}

export default NoteRecorder;

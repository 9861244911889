// src/view/component-library/button/Button.tsx

import React, { forwardRef } from 'react';
import './Button.scss';
import cn from "classnames";

export type ButtonProps = {
    className?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    onClick: any;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className = 'primary', ...props }, ref) => {
        return (
            <button
                ref={ref}
                className={cn('button', className)}
                {...props}
            />
        );
    }
);

Button.displayName = 'Button';

export default Button;
import React, {useCallback} from 'react';
import './Header.scss';
import cn from "classnames";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import Button from "../../component-library/button/Button";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Select from "../../component-library/input/Select";
import {GrLanguage} from "react-icons/gr";
import SignInModal from "../modal/SignInModal";
import {logo_svg} from '../../asset/image'
import {selectAllLanguages} from "../../../redux/languages";


function Header() {

    const {t: commonTranslation, i18n} = useTranslation('translation');

    const navigate = useNavigate()

    const appUser = useSelector((state: RootState) => state.appUser);
    const languages = useSelector(selectAllLanguages);

    const changeLanguage = useCallback(async (e: any) => {
        localStorage.setItem('language', e.target.value)
        await i18n.changeLanguage(e.target.value)
    }, [i18n])

    const [isOpenSignInModal, setIsOpenSignInModal] = React.useState(false);

    return (
        <header id={"Header"}>
            <SignInModal isOpen={isOpenSignInModal} onClose={() => setIsOpenSignInModal(false)} />
            <div className={cn("header-front")}>
                <Link to={'/'}>
                    <img className={cn("header-front-logo")} src={logo_svg} alt={'logo'}/>
                </Link>
            </div>
            <div className={cn("header-back")}>
                <div className={"language-setting"}>
                    <div className={"language-setting-icon"}>
                        <GrLanguage/>
                    </div>
                    <Select className={"language-setting-select"}
                            options={languages}
                            value={i18n.language}
                            labelKey={"name"}
                            valueKey={"code"}
                            onChange={changeLanguage}/>
                </div>

                {appUser.id ?
                    <Button className={"header-back-button"} onClick={() => navigate('/notes/list', {replace: true})}>
                        {commonTranslation("go-to-home")}
                    </Button>
                    :
                    <Button className={"header-back-button"} onClick={() => setIsOpenSignInModal(true)}>
                        {commonTranslation("login")}
                    </Button>
                }
            </div>
        </header>
    );
}

export default Header;

import {createEntityAdapter, createSlice, EntityId, EntityState, Slice} from '@reduxjs/toolkit';
import {RootState} from "./index";

export type Language = {
    id: number
    name: string
    code: string
};

type LanguagesRedux = EntityState<Language, EntityId>

const LanguageAdapter = createEntityAdapter<Language>({
    // @ts-ignore
    selectId: (language: Language) => language.id,
});

export const {
    selectAll: selectAllLanguages,
    selectIds: selectLanguagesIds,
    selectById: selectLanguagesById,
} = LanguageAdapter.getSelectors<RootState>((state) => state.languages);

const languagesSlice: Slice<LanguagesRedux> = createSlice({
    name: 'languages',
    initialState: LanguageAdapter.getInitialState({}),
    reducers: {
        upsertLanguages: (state, action) => {
            LanguageAdapter.upsertMany(state, action.payload);
        },
    },

});

export const {upsertLanguages} = languagesSlice.actions;

export default languagesSlice;

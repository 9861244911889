import {storeDispatch, storeState} from "../redux";
import {selectAllAudios, startAudio, startedAudio} from "../redux/noteAudio";


export const startNoteAudioAction = async (args: {
    targetAudioId: number,
    startTime: number
}) => {
    storeDispatch(startAudio(args))
}

export const findAndStartNoteAudioAction = async (args: {
    startTime: number
}) => {

    let remainTime = args.startTime
    const audios = selectAllAudios(storeState())

    for (let i = 0; i < audios.length; i++) {
        const audio = audios[i]
        if (remainTime < audio.wholeAudioLength / 1000) {
            storeDispatch(startAudio({
                targetAudioId: audio.id,
                startTime: remainTime
            }))
            break
        }
        remainTime -= audio.wholeAudioLength / 1000
    }
}

export const startedNoteAudioAction = async () => {
    storeDispatch(startedAudio(1))
}

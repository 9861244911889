import {Document, Packer, Paragraph, ImageRun, TextRun, PageBreak, AlignmentType} from 'docx';
import {saveAs} from 'file-saver';
import {AppNote, ScriptBlock, selectAllRecordingSessions} from "../redux/appNote";
import {storeState} from "../redux";
import _ from "lodash";
import {rangeInt} from "./ArrayUtil";

export const createAndDownloadWord = async ({
                                                pageImages,
                                                note,
                                                width,
                                                height
                                            }: {
    pageImages: ArrayBuffer[],
    note: AppNote,
    width: number,
    height: number
}): Promise<boolean> => {
    try {
        const allRecordingSessions = selectAllRecordingSessions(storeState())

        const blocks = _.sortBy(
            allRecordingSessions.reduce((result: any[], session: any) => result.concat(session.scriptBlocks), []),
            ['referenceFilePageIndex', 'recordingSessionId', 'order']
        )

        const range = note?.referenceFileTotalPages ? rangeInt(note.referenceFileTotalPages) : rangeInt(-1, 0)

        const descriptions = range.map((pageIndex: number) => blocks
            .filter((block: any) => block.referenceFilePageIndex === pageIndex)
            .reduce((result: string, scriptBlock: ScriptBlock) => {
                const script = scriptBlock.text
                    .split("{:")
                    .reduce((acc, cur) => {
                        if (cur.includes(":}")) {
                            return acc + cur.split(":} ")[1]
                        }
                        return acc
                    }, "")
                if (!result) {
                    return script
                }
                return result + "\n\n " + script
            }, "")
        )

        // Word 문서 생성
        const height_ratio = 600 / width;
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: pageImages.flatMap((imageBuffer, i) => [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new ImageRun({
                                    data: imageBuffer,
                                    transformation: {
                                        width: 600,
                                        height: height * height_ratio,
                                    },
                                }),
                            ],
                        }),
                        new Paragraph({text: ''}),
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: descriptions[i],
                                    size: 24,
                                }),
                            ],
                        }),
                        new Paragraph({
                            children: [new PageBreak()], // 페이지를 넘기기 위해 PageBreak 추가
                        }),
                    ]),
                },
            ],
        });

        const blob: Blob = await Packer.toBlob(doc);
        // const note = await getNoteAction(note?.id);
        saveAs(blob, `${note?.name}.docx`);
        console.log(`워드 파일 생성 완료 ${note?.name}.docx`);
    } catch (error: unknown) {
        if (error instanceof Error) {
            throw new Error(`워드 파일 생성 중 오류가 발생했습니다: ${error.message}`);
        } else {
            throw new Error('워드 파일 생성 중 알 수 없는 오류가 발생했습니다.');
        }
        return false;
    }
    return true;
}
;

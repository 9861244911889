import React from 'react';
import './SectionFourth.scss';
import {useTranslation} from "react-i18next";
import {arrow_right} from "../../asset/image";
import Button from "../../component-library/button/Button";
import SignInModal from "../modal/SignInModal";
import { ScrollAnimationContainer } from "../ScrollAnimationContainer";

function SectionFourth() {

    const {t} = useTranslation("translation", {keyPrefix: "SectionFourth"})

    const [isOpenSignInModal, setIsOpenSignInModal] = React.useState(false);

    return (
        <ScrollAnimationContainer>
            <div className="section-fourth">
                <SignInModal isOpen={isOpenSignInModal} onClose={() => setIsOpenSignInModal(false)}/>
                <h2 className={"section-fourth-title"}>{t('title')}</h2>
                <Button className={"section-fourth-button"}
                        onClick={() => {setIsOpenSignInModal(true)}}
                    >
                        {t('start-notifai')}
                        <img className={"section-fourth-button-icon"} src={arrow_right} alt={"go"}/>
                </Button>
            </div>
        </ScrollAnimationContainer>
    );
}

export default SectionFourth;
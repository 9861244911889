import {BrowserRouter} from "react-router-dom";
import RootRouter from "./router/RootRouter";
import store from './redux';
import {Provider} from 'react-redux';
import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import {GoogleOAuthProvider} from "@react-oauth/google";

const GA_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID as string;
const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string;

function App() {

    useEffect(() => {
        ReactGA.initialize(GA_MEASUREMENT_ID);
    }, []);

    return (
        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
            <Provider store={store}>
                <BrowserRouter>
                    <RootRouter/>
                </BrowserRouter>
            </Provider>
        </GoogleOAuthProvider>
    );
}

export default App;

import {API} from "../class";
import {NotifaiServer} from "../index";
import joi from "joi";
import {PollingScriptBlockFromDB, ScriptBlockFromDB} from "./type";

export const createScriptBlockAPI = new API<
    {
        body: {
            recording_session_id: number
            reference_file_page_index: number
            text: string
            order: number
            audio_id: number
        }
    },
    ScriptBlockFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/script-blocks/",
    method: "post",
    requestValidator: joi.object({
        body: joi.object({
            recording_session_id: joi.number().required(),
            reference_file_page_index: joi.number().required(),
            text: joi.string().required(),
            order: joi.number().required(),
            audio_id: joi.number().required(),
        }),
    }),
});

export const pollScriptBlockAPI = new API<
    {
        body: {
            recording_session_id: number
            last_response_time: string
        }
    },
    {
        scriptBlocks: PollingScriptBlockFromDB[]
        serverCurrentTime: string
    }
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/script-blocks/timestamp",
    method: "post",
    requestValidator: joi.object({
        body: joi.object({
            recording_session_id: joi.number().required(),
            last_response_time: joi.string().required(),
        }),
    }),
});

export const updateScriptBlockAPI = new API<
    {
        params: {
            scriptBlockId: number;
        },
        body: {
            recording_session_id: number
            reference_file_page_index: number
            text: string
            order: number
        }
    },
    ScriptBlockFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/script-blocks/:scriptBlockId",
    method: "put",
    requestValidator: joi.object({
        params: joi.object({
            scriptBlockId: joi.number().required(),
        }),
        body: joi.object({
            recording_session_id: joi.number().required(),
            reference_file_page_index: joi.number().required(),
            text: joi.string().allow(""),
            order: joi.number().required(),
        }),
    }),
});

export const deleteScriptBlockAPI = new API<
    {
        params: {
            scriptBlockId: number;
        },
    },
    any
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/script-blocks/:scriptBlockId/",
    method: "delete",
    requestValidator: joi.object({
        params: joi.object({
            scriptBlockId: joi.number().required(),
        })
    }),
});

export const translateScriptBlockAPI = new API<
    {
        body: {
            script_block_ids: number[]
            dest_language_id: number
        },
    },
    any
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/script-blocks/translation/",
    method: "post",
    requestValidator: joi.object({
        body: joi.object({
            script_block_ids: joi.array().items(joi.number()).required(),
            dest_language_id: joi.number().required(),
        }),
    }),
});

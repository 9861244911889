import React, {useState, useEffect, useMemo} from 'react';
import Modal from "../../component-library/modal/Modal";
import AccountDeletionWarningModal from './AccountDeletionWarningModal';
import './AccountDeletionModal.scss';
import Button from "../../component-library/button/Button";
import {useTranslation} from "react-i18next"; // 리다이렉트를 위한 useHistory

type AccountDeletionModalProps = {
    isOpen: boolean;
    onClose: () => void;
}

function AccountDeletionModal({ isOpen, onClose }: AccountDeletionModalProps) {

    const {t} = useTranslation('translation', {keyPrefix: "AccountDeletionModal"});

    const [selectedReasons, setSelectedReasons] = React.useState<string[]>([]);
    const [otherReason, setOtherReason] = useState<string>(''); // 기타 이유
    const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [finalReasons, setFinalReasons] = useState<string[]>([]);

    const reasonList = useMemo(() => [
        "reason-row-accuracy",
        "reason-too-slow",
        "reason-no-function",
        "reason-many-error",
        "reason-no-needs",
        "reason-etc"
    ], []);

    // 체크박스 상태 업데이트 로직
    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedReasons(prev =>
            event.target.checked
                ? [...prev, value]
                : prev.filter(reason => reason !== value)
        );
    };

    // "reason-etc"가 선택된 경우에는 이유가 2자 이상 적혔는지 확인하고, 그 외에는 선택된 사유가 있는지만 확인
    useEffect(() => {
        if (selectedReasons.includes("reason-etc")) {
            setIsDeleteEnabled(otherReason.length >= 2);
        } else {
            setIsDeleteEnabled(selectedReasons.length > 0);
        }
    }, [selectedReasons, otherReason]);

    // 모달이 닫힐 때마다 상태를 초기화
    useEffect(() => {
      if (!isOpen) {
          setSelectedReasons([]);  // 모달이 닫힐 때 선택된 체크박스 초기화
          setOtherReason('');  // 기타 이유 초기화
      }
    }, [isOpen]);

    // '계속' 버튼 클릭시 현재 모달 닫고, 계정 삭제 워닝 모달 열기
    const handleContinue = () => {
        const reasons = selectedReasons.includes("reason-etc")
            ? [...selectedReasons.filter(reason => reason !== "reason-etc"), `기타: ${otherReason}`]  // 문자열 템플릿 수정
            : selectedReasons;
        setFinalReasons(reasons);  // finalReasons 설정
        onClose();  // 현재 모달 닫기
        setIsWarningModalOpen(true);  // 경고 모달 열기
    };



    return (
        <>
        <Modal isOpen={isOpen} onClose={onClose} className={"account-deletion-modal"}>
            <div className={"account-deletion-modal-h1"}>
                {t('account-deletion')}
            </div>
            <div className={"account-deletion-modal-h2"}>
                {t('account-deletion-description')}
            </div>
            <form className={"account-deletion-modal-form"}>
                {reasonList.map((reason) => (
                    <label key={reason}>
                        <input
                            type="checkbox"
                            value={reason}
                            onChange={handleReasonChange}
                        />
                        {t(reason)}
                    </label>
                ))}
                {selectedReasons.includes("reason-etc") && (
                    <textarea
                        className="other-reason-textarea textarea-visible"
                        value={otherReason}
                        onChange={(e) => setOtherReason(e.target.value)}
                        placeholder={t('reason-placeholder')}
                        maxLength={200}
                    />
                )}
            </form>
            <div className={"account-deletion-modal-button-wrapper"}>
                <Button className={"account-deletion-modal-button-wrapper-cancel-button"} onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button
                    className={"account-deletion-modal-button-wrapper-delete-button"}
                    disabled={!isDeleteEnabled}
                    onClick={handleContinue} // 계속 버튼 클릭시 워닝 모달 열기
                >
                    {t('delete')}
                </Button>
            </div>
        </Modal>

        <AccountDeletionWarningModal
            isOpen={isWarningModalOpen} // 두 번째 모달 열기
            onClose={() => setIsWarningModalOpen(false)}
            finalReasons={finalReasons} // 삭제 사유 전달
        />
    </>
    );
}

export default AccountDeletionModal;

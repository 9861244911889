import React, {Suspense, useCallback, useEffect, useRef} from 'react';
import EditorJS from "@editorjs/editorjs";
import List from '@editorjs/list';
import Header from '@editorjs/header';
import Marker from '@editorjs/marker';
import InlineCode from '@editorjs/inline-code';
import Underline from '@editorjs/underline';
import './BlockEditor.scss'
import cn from "classnames";
import {useDebouncedCallback} from "use-debounce";

const DEFAULT_EDITOR_CONFIG = {
    inlineToolbar: ['bold', 'italic', 'underline', 'marker', 'inlineCode'],
    tools: {
        header: {
            class: Header,
            toolbox: {
                title: 'Header',
            },
            config: {
                levels: [1, 2, 3],
                defaultLevel: 1,
            }
        },
        list: List,
        marker: Marker,
        inlineCode: InlineCode,
        underline: Underline,
    }
}

function BlockEditor({ id, toolbar = true, onSave, editorConfig }) {

    const editorRef = useRef()

    const [blocks, setBlocks] = React.useState({
        time: new Date(0).getTime(),
        blocks: []
    });

    const onChange = useDebouncedCallback(async (api, event) => {
        if (editorRef.current) {
            const savedData = await editorRef.current?.save();
            setBlocks(savedData);
        }
    }, 1000)

    const initEditorJs = useCallback(() => {
        if (!editorRef.current && editorConfig.data.blocks?.length > 0) {
            editorRef.current = new EditorJS({
                ...DEFAULT_EDITOR_CONFIG,
                ...editorConfig,
                onChange,
                holder: id,
            });
        }
    }, [editorConfig, id, onChange])

    useEffect(() => {
        const div = document.getElementById(id)
        if (div) {
            initEditorJs()
        }
    }, [id, initEditorJs]);

    return (
        <div id={id}
             className={cn('block-editor', {'disabled-toolbar': !toolbar})}
             onLoad={() => {
             }}
        />
    );
}

export default BlockEditor;

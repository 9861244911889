import React from 'react';
import Button, {ButtonProps} from "../../component-library/button/Button";
import cn from "classnames";
import './GrayButton.scss';

function GrayButton(props: ButtonProps) {
    return (
        <Button {...props} className={cn("gray-button", props?.className)} />
    );
}

export default GrayButton;

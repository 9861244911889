import {createEntityAdapter, createSlice, EntityId, EntityState, Slice} from '@reduxjs/toolkit';
import {FolderFromDB, SubjectFromDB} from "../server/appNotes/type";
import {RootState} from "./index";

export type Folder = FolderFromDB & {type: 'folder', children: (Folder | Subject)[]};
export type Subject = SubjectFromDB & {type: 'subject'};

type FolderAndSubjectRedux = {
    folders: EntityState<Folder, EntityId>,
    subjects: EntityState<Subject, EntityId>,
}

const FolderAdapter = createEntityAdapter<Folder>({
    // @ts-ignore
    selectId: (folder:Folder) => folder.id,
});

export const {
    selectAll: selectAllFolders,
    selectIds: selectFoldersIds,
    selectById: selectFoldersById,
} = FolderAdapter.getSelectors<RootState>((state) => state.folderAndSubject.folders);


const SubjectAdapter = createEntityAdapter<Subject>({
    // @ts-ignore
    selectId: (subject: Subject) => subject.id,
});

export const {
    selectAll: selectAllSubjects,
    selectIds: selectSubjectsIds,
    selectById: selectSubjectsById,
} = SubjectAdapter.getSelectors<RootState>((state) => state.folderAndSubject.subjects);



const FolderAndSubjectInitialState: FolderAndSubjectRedux = {
    folders: FolderAdapter.getInitialState({}),
    subjects: SubjectAdapter.getInitialState({}),
}

const folderAndSubjectSlice: Slice<FolderAndSubjectRedux> = createSlice({
    name: 'folderAndSubject',
    initialState: FolderAndSubjectInitialState,
    reducers: {
        upsertFolders: (state, action) => {
            FolderAdapter.upsertMany(state.folders, action.payload.map((folder: FolderFromDB) => ({...folder, type: 'folder', children: []})));
        },
        upsertSubjects: (state, action) => {
            SubjectAdapter.upsertMany(state.subjects, action.payload.map((subject: SubjectFromDB) => ({...subject, type: 'subject'})));
        },
        clearFolders: (state) => {
            FolderAdapter.removeAll(state.folders);
        },
        clearSubjects: (state) => {
            SubjectAdapter.removeAll(state.subjects);
        },
        upsertFolder: (state, action) => {
            FolderAdapter.upsertOne(state.folders, {type: 'folder', ...action.payload});
        },
        upsertSubject: (state, action) => {
            SubjectAdapter.upsertOne(state.subjects, {type: 'subject', ...action.payload});
        },
        removeFolder: (state, action) => {
            FolderAdapter.removeOne(state.folders, action.payload);
        },
        removeSubject: (state, action) => {
            SubjectAdapter.removeOne(state.subjects, action.payload);
        },
    },

});

export const {upsertFolders, upsertSubjects, upsertFolder, upsertSubject, clearFolders, clearSubjects, removeFolder, removeSubject} = folderAndSubjectSlice.actions;

export const selectSubject = (state: RootState, subjectId: number) => selectSubjectsById(state, subjectId);
const selectSubjectsByFolderId = (state: RootState, folderId: number) => selectAllSubjects(state).filter((subject: Subject) => subject.folderId === folderId);
export const selectFolder = (state: RootState, folderId: number): Folder => {
    const result: Folder = selectFoldersById(state, folderId) ?? {id: 0, name: '', createdAt: '', updatedAt: '', type: 'folder', children: []};
    return {
        ...result,
        children: [
            ...selectAllFolders(state).filter((folder: Folder) => folder.parentFolderId === folderId)
                .map((folder: Folder) => {
                    return selectFolder(state, folder.id);
                }),
            ...selectSubjectsByFolderId(state, folderId),
        ]
    }
}

export default folderAndSubjectSlice;

import React, {useCallback, useEffect} from 'react';
import {useDrag} from "react-dnd";
import './DraggableParagraph.scss'
import {AiTwotoneEdit} from "react-icons/ai";
import TextareaAutosize from "react-textarea-autosize";
import cn from "classnames";
import {IoMdCheckmark} from "react-icons/io";
import {updateScriptBlockAction} from "../../../action/appNoteScriptBlockAction";
import {ScriptBlock, selectAppNoteTranslation} from "../../../redux/appNote";
import {startNoteAudioAction} from "../../../action/noteAudioAction";
import {
    scriptPageRematchEvent,
    textUpdatedEvent,
    scriptClickReplayEvent,
} from "../../../analytics/ga";
import ConfirmModal from "../modal/ConfirmModal";
import {secondToTimestamp} from "../../../util/timeUtils";
import {useSelector} from "react-redux";
import Skeleton from "react-loading-skeleton";

type DraggableParagraphProps = {
    block: ScriptBlock & { prevTime: number };
    setIsKeyboardBlock: (isKeyboardBlock: boolean) => void
    setFocusedPageIndex: (args: any) => void
    isRecording: boolean
    isLast: boolean
}

function DraggableParagraph({block, setIsKeyboardBlock, setFocusedPageIndex, isRecording, isLast}: DraggableParagraphProps) {

    const [{opacity}, drag] = useDrag(
        () => ({
            type: "scriptBlock",
            item: {block},
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult(); // 드롭 결과 가져오기
                if (monitor.didDrop() && dropResult) {
                    scriptPageRematchEvent(); // 페이지 재매칭 이벤트 호출
                }
            },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        }),
        [block],
    );

    const noteTranslation = useSelector(selectAppNoteTranslation)

    const textareaRef = React.useRef<HTMLTextAreaElement>(null)

    const [isHover, setIsHover] = React.useState(false)
    const [isEditing, setIsEditing] = React.useState(false)
    const [editingText, setEditingText] = React.useState('')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isPressShift, setIsPressShift] = React.useState(false)
    const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false)

    const parsingText = useCallback((): { timestamp: string, text: string }[] => {

        const splitText = block.text?.split('|||')
        return splitText.reduce((acc: any[], cur: string, i: number) => {
            return acc.concat(cur.replaceAll('\n', '').split('{:')
                .map((line: string, index: number) => {
                    const [timestamp, text] = line.split(':} ')
                    if (i > 0) {
                        return {timestamp, text, processing: true}
                    }
                    return {timestamp, text}
                })
                .slice(1) ?? [])
        }, [])
        // let result = splitText[0].replaceAll('\n', '').split('{:')
        //     .map((line: string, index: number) => {
        //         const [timestamp, text] = line.split(':} ')
        //         return {timestamp, text}
        //     })
        //     .slice(1) ?? []
        //
        // if (block.text?.split('|||').length > 1) {
        //     return block.text?.replaceAll('\n', '').split('{:')
        //         .map((line: string, index: number) => {
        //             const [timestamp, text] = line.split(':} ')
        //             return {timestamp, text}
        //         })
        //         .slice(1) ?? []
        // }

        // return result

    }, [block.text])

    useEffect(() => {
        setEditingText(parsingText()?.reduce((acc, cur) => acc + cur.text, ''))
    }, [parsingText]);

    // const divideBlock = useCallback(async (text: string) => {
    //     await divideScriptBlockAction({
    //         ...block,
    //         text,
    //     })
    // }, [block])

    const onEndEditing = useCallback(async () => {

        if (editingText === '') {
            setIsConfirmModalOpen(true)
            return
        }

        let copyEditingText = editingText

        const newTexts: any = {}

        let parsedText: any[] = parsingText()

        parsedText = parsedText.map((line: any, index: number) => {
            const temp = copyEditingText.split(line.text)
            if (temp.length === 1) {
                if (index === parsedText.length - 1) {
                    return {
                        ...line,
                        text: copyEditingText,
                        isFix: true
                    }
                }
                copyEditingText = temp[0]
                return {
                    ...line,
                    text: "",
                    isFix: true
                }
            }
            if (index !== parsedText.length - 1 && temp[0]) {
                newTexts[line.timestamp] = temp[0]
            }
            if (index === parsedText.length - 1) {
                return {
                    ...line,
                    text: copyEditingText,
                }
            }
            copyEditingText = temp.slice(1).join(line.text)
            return line
        })

        Object.keys(newTexts).forEach((timestamp) => {
            if (timestamp === 'last') {
                parsedText[parsedText.length - 1].text = parsedText[parsedText.length - 1].text + newTexts[timestamp]
                return
            }

            let targetIndex = parsedText.findIndex((line) => line.timestamp === timestamp)

            if (targetIndex === 0) {
                parsedText[targetIndex].text = newTexts[timestamp] + parsedText[targetIndex].text
                return
            }

            for (let i = targetIndex; i >= 0; i--) {
                if (!parsedText[i - 1]?.isFix) {
                    break
                }
                targetIndex = i
            }

            parsedText[targetIndex - 1].text = newTexts[timestamp] + parsedText[targetIndex - 1].text
        })


        const textToUpdate = parsedText.reduce((acc, cur) => acc + (cur.text ? `{:${cur.timestamp}:} ${cur.text}` : ''), '')

        setIsEditing(false)

        if (textToUpdate === block.text) {
            return
        }

        await updateScriptBlockAction({
            ...block,
            text: textToUpdate,
        })
        textUpdatedEvent(); // 텍스트 수정 트래킹 이벤트 호출
    }, [block, editingText, parsingText])

    const onFillEmptyText = useCallback(async () => {
        await updateScriptBlockAction({
            ...block,
            text: '',
        })
    }, [block])

    const onChange = useCallback(async (e: any) => {
        // if (isPressShift && e.target.value.includes('\n')){
        //     setEditingText(e.target.value)
        //     return
        // }
        if (e.target.value.includes('\n')) {
            setEditingText(e.target.value.replaceAll('\n', ''))
            onEndEditing()
            return
        }
        setEditingText(e.target.value)
    }, [onEndEditing])

    const onKeyDown = useCallback((e: any) => {
        if (e.shiftKey) {
            setIsPressShift(true)
        }
    }, [])

    const onKeyUp = useCallback((e: any) => {
        if (!e.shiftKey) {
            setIsPressShift(false)
        }
    }, [])

    useEffect(() => {
        setIsKeyboardBlock(isEditing)
    }, [isEditing, setIsKeyboardBlock]);

    return (
        <div>
            <div {...(!isEditing && {ref: drag})} key={'block_' + block.id.toString()} className={cn("paragraph-block")}
                 style={{opacity}}
                 onMouseEnter={() => setIsHover(true)}
                 onMouseLeave={() => setIsHover(false)}
            >
                <ConfirmModal title={"스크립트 삭제"}
                              subtitle={"정말로 삭제하시겠습니까?\n삭제한 스크립트는 복구할 수 없습니다."}
                              isOpen={isConfirmModalOpen}
                              onClose={() => {
                                  setEditingText(parsingText()?.reduce((acc, cur) => acc + cur.text, ''))
                                  setIsConfirmModalOpen(false)
                              }}
                              onConfirm={onFillEmptyText}/>
                <div className={'paragraph-block-timestamp'}>
                    {secondToTimestamp(Math.round(block.prevTime / 1000))}
                </div>
                {isEditing ?
                    <TextareaAutosize ref={textareaRef}
                                      className={cn('paragraph-block-textarea', {edit: isEditing, hover: isHover})}
                                      value={(isEditing ? editingText : block.text)}
                                      onChange={onChange}
                                      onKeyDown={onKeyDown}
                                      onKeyUp={onKeyUp}
                                      onBlur={() => {
                                          setTimeout(() => {
                                              setIsEditing(false)
                                          }, 100)
                                      }}
                    />
                    :
                    <div className={cn('paragraph-block-textarea', {edit: isEditing, hover: isHover})}>
                        {parsingText()?.map((line: any, index: number) => (
                            <span className={cn("paragraph-block-words", {processing: line.processing})}
                                  key={index}
                                  onClick={async () => {
                                      await startNoteAudioAction({
                                          targetAudioId: block.recordingSessionId,
                                          startTime: parseFloat(line.timestamp)
                                      });
                                      setFocusedPageIndex(block.referenceFilePageIndex)
                                      scriptClickReplayEvent();  // 스크립트 클릭해서 오디오 재생 트래킹
                                  }}
                            >
                                {`${line.text} `}
                            </span>
                        ))}
                        {isLast && isRecording && block?.state === 'processing' && <span className={"paragraph-block-skeleton"}>
                            {`~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~`}
                        </span>}
                        {noteTranslation && block?.state !== 'processing' && <p className={cn("paragraph-block-translation")}>
                            {block.translation ? block.translation : <Skeleton count={2}/>}
                        </p>}
                    </div>
                }

                {(isHover || isEditing) &&
                    <button className={cn('paragraph-block-edit-button', {edit: isEditing})}
                            onClick={async () => {
                                if (isEditing) {
                                    await onEndEditing()
                                    return
                                }
                                setIsEditing(true)
                                setTimeout(() => {
                                    textareaRef.current?.focus()
                                }, 10)
                            }}>
                    {isEditing ? <IoMdCheckmark/> : <AiTwotoneEdit/>}
                    </button>}

            </div>
        </div>
    );
}

export default DraggableParagraph;

// src/view/component/nav/RootDroppable.tsx

import React, {useCallback, useState, useEffect, useRef} from 'react';
import {useDrop} from "react-dnd";
import {
    createNewFolderAction, createNewSubjectAction,
    updateFolderAction,
    updateSubjectAction
} from "../../../action/appNoteAction";
import './RootDroppable.scss'
import {Folder, Subject} from "../../../redux/folderAndSubject";
import {useSelector, useDispatch} from "react-redux";
import {RootState} from "../../../redux";
import {FaPlus} from "react-icons/fa6";
import {useTranslation} from "react-i18next";
import useClickOutside from "../../../hook/useClickOutside";
import {setTutorialStep, skipAllTutorials} from "../../../redux/tutorialSlice"; // 튜토리얼 액션 추가
import TutorialOverlay from "../../component/tutorial/TutorialOverlay"; // 튜토리얼 컴포넌트 추가
import {
    folderCreatedEvent,
    subjectCreatedEvent,
} from "../../../analytics/ga";

type RootDroppableProps = {
    setIsOpenSignInModal: (value: React.SetStateAction<boolean>) => void
}

function RootDroppable({setIsOpenSignInModal}: RootDroppableProps) {

    const { t } = useTranslation('translation', { keyPrefix: "RootDroppable" });
    const appUser = useSelector((state: RootState) => state.appUser);

    // 새로운 과목을 만드는 함수
    const createNewSubject = useCallback(async () => {
        if (!Boolean(appUser.id)) {
            setIsOpenSignInModal(true)
            return
        }
        await createNewSubjectAction({name: "새로운 과목", folderId: appUser.rootFolderId});  // 자동으로 과목을 생성
    }, [appUser.id, appUser.rootFolderId, setIsOpenSignInModal]);

    const dispatch = useDispatch();
    const { currentStep, subjectAdd } = useSelector((state: RootState) => state.tutorial);
    const newSubjectButtonRef = useRef<HTMLButtonElement>(null);

    // 튜토리얼 완료 핸들러
    const handleSubjectAddCompleteTutorial = useCallback(() => {
        dispatch(setTutorialStep('subjectAdd'));
      }, [dispatch]);

    // 모든 튜토리얼 건너뛰기 처리 함수
    const handleSkipTutorial = useCallback(() => {
        dispatch(skipAllTutorials());
    }, [dispatch]);

    const onDropItem = useCallback(async (dropItem: { subject?: Subject, folder?: Folder }) => {
        if (dropItem?.subject) {
            await updateSubjectAction({
                subjectId: dropItem.subject.id,
                name: dropItem.subject.name,
                folderId: appUser.rootFolderId as number
            })
        }
        if (dropItem?.folder) {
            await updateFolderAction({
                folderId: dropItem.folder.id,
                name: dropItem.folder.name,
                parentFolderId: appUser.rootFolderId as number
            })
        }
    }, [appUser.rootFolderId])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ["subject", "folder"],
        drop: onDropItem,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    const [isNewFolderOrSubject, setIsNewFolderOrSubject] = useState<boolean>(false)

    const onClickNewFolder = useCallback(async () => {
        if (!Boolean(appUser.id)) {
            setIsOpenSignInModal(true)
            return
        }
        await createNewFolderAction({name: t('new-folder'), parentFolderId: appUser.rootFolderId})
        setIsNewFolderOrSubject(false)
        folderCreatedEvent(); // new folder 추가 이벤트
    }, [appUser.id, appUser.rootFolderId, setIsOpenSignInModal, t])

    const onClickNewSubject = useCallback(async () => {
        if (!Boolean(appUser.id)) {
            setIsOpenSignInModal(true)
            return
        }
        await createNewSubjectAction({name: t('new-subject'), folderId: appUser.rootFolderId})
        setIsNewFolderOrSubject(false)
        subjectCreatedEvent(); // new subject 추가 이벤트
    }, [appUser.id, appUser.rootFolderId, setIsOpenSignInModal, t])

    const newModalRef = useClickOutside({
        handler: () => {
            setTimeout(() => {
                setIsNewFolderOrSubject(false)
            }, 200)
        }
    })

    return (
        <div ref={drop}>
            <div className={"nav-list-header"}>
                <span className={"nav-list-header-title"}>{t('subject')}</span>
                <button 
                    className={"nav-list-header-new-button"}
                    onClick={() => {
                        onClickNewSubject()
                    }}
                    ref={newSubjectButtonRef} // 새로운 과목 버튼에 ref 연결
                >
                    <FaPlus />
                </button>

                {/* subjectAdd 튜토리얼 표시 */}
                {currentStep === 1 && !subjectAdd && (
                    <TutorialOverlay
                        content={t('subject-add-tutorial')} 
                        onComplete={handleSubjectAddCompleteTutorial}
                        onSkip={handleSkipTutorial}
                        targetRef={newSubjectButtonRef}
                    />
                )}

                {isNewFolderOrSubject && <div className={"nav-list-header-new"} ref={newModalRef}>
                    <button className={"nav-list-header-new-folder"}
                            onClick={onClickNewFolder}>{t('add-folder')}</button>
                    <div/>
                    <button className={"nav-list-header-new-subject"}
                            onClick={onClickNewSubject}>{t('add-subject')}</button>
                </div>}
            </div>
        </div>
    );
}

export default RootDroppable;

import React, {useCallback, useEffect, useRef} from 'react';

export type useModalProps = {
    modalComponent: ({text, onInactive, ref}: {text?: string, onInactive: () => void, ref: any}) => React.ReactNode & any,
}

function useTooltip({modalComponent}: useModalProps) {

    const wrapperRef = useRef(null);

    const [isActive, setIsActive] = React.useState<boolean>(false);

    const onActive = useCallback(() => setIsActive(true), []);
    const onInactive = useCallback(() => setIsActive(false), []);

    useEffect(() => {
        function handleClickOutside(event: any) {
            setIsActive(false);
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return {
        onActive,
        onInactive,
        modalComponent: isActive ?
            modalComponent({onInactive, ref: wrapperRef})
            :
            null,
    };
}

export default useTooltip;

import {APP_USER_STATE, logout, setAppUser} from "../redux/appUser";
import store, {storeDispatch, storeState} from "../redux";
import {CodeResponse} from "@react-oauth/google";
import {deleteUserAPI, loginByGoogleAPI, refreshTokenAPI, updateUserAPI} from "../server/appUsers/appUser";
import {getActiveSettingsAPI, getSettingsAPI} from "../server/appUsers/settings";
import {upsertActiveSettings, upsertSettings} from "../redux/setting";
import {clearAppNote} from "../redux/appNote";
import {clearAppNotes} from "../redux/appNotes";
import {clearFolders, clearSubjects} from "../redux/folderAndSubject";
import Cookies from 'universal-cookie';
import {clearAudio} from "../redux/noteAudio";

const cookies = new Cookies();

export const allInOneAction = async () => {
    // storeDispatch(setAppUser({
    //     name: "test"
    // }))
    // window.location.href = `${process.env.REACT_APP_SERVER_DOMAIN}/oauth/google/login/`;
}

export const googleLoginAction = async (codeResponse: CodeResponse) => {
    const {response, error} = await loginByGoogleAPI.request({body: {
        code: codeResponse.code,
        scope: codeResponse.scope
    }})

    if (error) {
        return false
    }

    storeDispatch(setAppUser({
        ...response.user,
        tokens: response.jwt,
        state: APP_USER_STATE.AUTHORIZED
    }))

    cookies.set('refresh_token', response.jwt.refreshToken, {
        path: '/',
        secure: process.env.REACT_APP_ENVIRONMENT === 'production',
        sameSite: 'lax',
        maxAge: 3600 * 24 * 30,
    });

    return true
}

export const refreshTokenAction = async () => {
    const {result, error} = await refreshTokenAPI()

    if (error) {
        console.error(error)
        storeDispatch(setAppUser({
            state: APP_USER_STATE.NOT_AUTHORIZED
        }))
        return
    }

    storeDispatch(setAppUser({
        ...result,
        state: APP_USER_STATE.AUTHORIZED
    }))
}

export const appUserAuthenticateAction = () => {
    storeDispatch(setAppUser({
        state: APP_USER_STATE.AUTHENTICATED
    }))
}

export const googleLogoutAction = async () => {
    storeDispatch(logout(storeState()))
    storeDispatch(clearAppNote(storeState()))
    storeDispatch(clearAudio(storeState()))
    storeDispatch(clearAppNotes(storeState()))
    storeDispatch(clearFolders(storeState()))
    storeDispatch(clearSubjects(storeState()))

    cookies.remove('refresh_token', {
        path: '/',
        secure: process.env.REACT_APP_ENVIRONMENT === 'production',
        sameSite: 'lax',
        maxAge: 3600 * 24 * 30,
    })
}

export const updateUserAction = async (args: {
    description?: string;
    department?: string;
    serviceLanguageId?: number;
    noteSourceLanguageId?: number;
    noteDestLanguageId?: number;
}) => {
    const {response, error} = await updateUserAPI.request({
        body: {
            description: args.description ?? "",
            department: args.department ?? "",
            service_language_id: args.serviceLanguageId,
            note_source_language_id: args.noteSourceLanguageId,
            note_dest_language_id: args.noteDestLanguageId,
        }
    })

    if (error) {
        return
    }

    storeDispatch(setAppUser({
        ...response,
        state: APP_USER_STATE.AUTHORIZED
    }))
}

export const getAppUserSettings = async () => {
    const {response: settings, error: settingsError} = await getSettingsAPI.request({})
    const {response: activeSettings, error: activeSettingsError} = await getActiveSettingsAPI.request({})

    if (settingsError || activeSettingsError) {
        return
    }

    storeDispatch(upsertSettings(settings))
    storeDispatch(upsertActiveSettings(activeSettings))
}

export const deleteUserAction = async (reasons: string[]) => {
    const {response, error} = await deleteUserAPI.request({
        body: {
            reasons: reasons  // 배열 형태로 전달
        }
    });

    if (error) {
        console.error("Failed to delete user: ", error);
        return;
    }

    // API 호출 성공 시 상태 초기화
    storeDispatch(logout(storeState()));
    storeDispatch(clearAppNote(storeState()));
    storeDispatch(clearAudio(storeState()))
    storeDispatch(clearAppNotes(storeState()));
    storeDispatch(clearFolders(storeState()));
    storeDispatch(clearSubjects(storeState()));

    cookies.remove('refresh_token', {
        path: '/',
        secure: process.env.REACT_APP_ENVIRONMENT === 'production',
        sameSite: 'lax',
        maxAge: 3600 * 24 * 30,
    })
};

// src/redux/noteTutorialSlice.ts

import {createSlice, PayloadAction, Slice} from '@reduxjs/toolkit';

const tutorialOrder = ['scriptInfo', 'scriptInfoRef', 'refControl', 'memoInfo', 'noteTitle'] as const;
type TutorialStep = typeof tutorialOrder[number];

interface noteTutorialState {
    scriptInfo: boolean;
    scriptInfoRef: boolean;
    refControl: boolean;
    memoInfo: boolean;
    noteTitle: boolean;
    currentStep: number;
}

const initializeLocalStorage = (): noteTutorialState => {
  const savedState = localStorage.getItem('noteTutorial');
  if (!savedState) {
    const initialState: noteTutorialState = {
      scriptInfo: false,
      scriptInfoRef: false,
      refControl: false,
      memoInfo: false,
      noteTitle: false,
      currentStep: 0
    };
    localStorage.setItem('noteTutorial', JSON.stringify(initialState));
    return initialState;
  }
  return JSON.parse(savedState);
};

const initialState = initializeLocalStorage();

const noteTutorialSlice: Slice<noteTutorialState> = createSlice({
  name: 'noteTutorial',
  initialState,
  reducers: {
    setTutorialStep(state, action: PayloadAction<TutorialStep>) {
      const step = action.payload;
      if (!state[step]) {
        state[step] = true;
        state.currentStep += 1;
        localStorage.setItem('noteTutorial', JSON.stringify(state));
      }
    },
    skipAllTutorials(state) {
      tutorialOrder.forEach(step => {
        state[step] = true;
      });
      state.currentStep = tutorialOrder.length;
      localStorage.setItem('noteTutorial', JSON.stringify(state));
    },
  },
});

export const { setTutorialStep, skipAllTutorials } = noteTutorialSlice.actions;

export default noteTutorialSlice;

import React, {useEffect} from 'react';

function useBlockPageExit({
    active,
    message,
    unmount
}: {
    active: boolean
    message: string
    unmount?: Function
}) {

    useEffect(() => {

        const handleBeforeUnload = (ev: BeforeUnloadEvent) => {
            if (active) {
                ev.preventDefault();
                ev.returnValue = '';
            }
        }

        const preventGoBack = () => {
            if (active) {
                if (window.confirm(message)) {
                    if (unmount) {
                        unmount()
                    }
                    window.history.back()
                    return;
                }
                window.history.pushState(null, "", window.location.href);
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        // window.addEventListener("popstate", preventGoBack);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            // window.removeEventListener("popstate", preventGoBack);
        }
    }, [active, message, unmount]);

    // useEffect(() => {
    //     window.history.pushState(null, "", window.location.href);
    // }, []);

    return null;
}

export default useBlockPageExit;

import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import './SummaryView.scss'
import {useDebouncedCallback} from 'use-debounce';
import BlockEditor from "../../component-library/editor/BlockEditor";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectAppNote} from "../../../redux/appNote";
import CommonButton from "../button/CommonButton";
import GrayButton from "../button/GrayButton";
import cn from "classnames";
import {createNoteSummaryAction, getNoteSummaryAction} from "../../../action/appNoteSummaryAction";
import Skeleton from "react-loading-skeleton";
import Select from "../../component-library/input/Select";
import {selectAllLanguages} from "../../../redux/languages";
import NoteExportModal from "../modal/NoteExportModal";

// image
import memoEmoji from "../../asset/image/memo-emoji.png";
import booksEmoji from "../../asset/image/books-emoji.png";
import cardFileBoxEmoji from "../../asset/image/card-file-box-emoji.png";
import pushPinEmoji from "../../asset/image/pushpin-emoji.png";
import dropdownIcon from "../../component-library/input/select-arrow.svg";
import {toast} from "react-toastify";


const DEFAULT_SUMMARY = {
    time: 1556098174501,
    blocks: []
}

const parseSummary = (summary: any) => {
    return summary.reduce((ac: any[], summary: any) => {
        return [...ac, ...summary.text
            .split("\n")
            .map((item: string) => item.trim())
            .filter((item: string) => item)
            .reduce((acc: any[], text: string) => {

                const accentTexts = text.split("**")
                let parsedText = text

                if (accentTexts.length === 3 && !accentTexts[2]) {
                    return [...acc, {
                        type: 'header',
                        data: {
                            text: accentTexts[1],
                            level: accentTexts[0] ? 3 : 2
                        }
                    }]
                }

                if (accentTexts.length === 3 && accentTexts[2]) {
                    parsedText = `${accentTexts[0]}<strong>${accentTexts[1]}</strong>${accentTexts[2]}`
                }

                const listTexts = parsedText.split("-")

                if (listTexts.length > 1) {

                    if (acc.length > 0 && acc[acc.length - 1].type === 'list') {
                        return [...acc.slice(0, acc.length - 1), {
                            ...acc[acc.length - 1],
                            data: {
                                ...acc[acc.length - 1].data,
                                items: [...acc[acc.length - 1].data.items, listTexts[1]]
                            }
                        }]
                    }

                    return [...acc, {
                        type: 'list',
                        data: {
                            style: "unordered",
                            items: [listTexts[1]]
                        }
                    }]
                }

                return [...acc, {
                    type: 'paragraph',
                    data: {
                        text: parsedText
                    }
                }]
            }, [])
        ]
    }, [])
}

function SummaryView({summaryNote}: any) {

    const {t} = useTranslation("translation", {keyPrefix: "SummaryView"});

    const languages = useSelector(selectAllLanguages)
    const appUser = useSelector((state: any) => state.appUser)
    const note = useSelector(selectAppNote)

    const editorRef = useRef<any>()
    const [selectedTemplate, setSelectedTemplate] = React.useState<string>("DETAILED_DESCRIPTION")
    const [summaryBlocks, setSummaryBlocks] = React.useState<{
        time: number,
        blocks: any[]
    }>({
        time: new Date().getTime(),
        blocks: []
    })
    const [selectedLanguageId, setSelectedLanguageId] = React.useState(appUser.serviceLanguageId)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isOptionsModalOpen, setIsOptionsModalOpen] = React.useState<boolean>(false)

    const templateList = useMemo(() => [
        {
            title: t("perfunctory-template"), /* 요약 형식으로 선택하기 */
            type: "perfunctory",
            sublist: [
                {
                    label: t("detailed-description"), /* 꼼꼼 정리노트 */
                    value: "DETAILED_DESCRIPTION",
                    id: "DETAILED_DESCRIPTION",
                    emoji: memoEmoji,
                },
                {
                    label: t("gist-summary"), /* 콕콕 핵심만 */
                    value: "GIST_SUMMARY",
                    id: "GIST_SUMMARY",
                    emoji: pushPinEmoji,
                    disabled: true
                },

            ]
        },
        {
            title: t("situational-template"), /* 상황별 템플릿 선택하기 */
            type: "situational",
            sublist: [
                {
                    label: t("meeting"),
                    value: "MEETING",
                    id: "MEETING",
                    emoji: cardFileBoxEmoji,
                    disabled: true
                },
                {
                    label: t("reading-group"),
                    value: "READING_GROUP",
                    id: "READING_GROUP",
                    emoji: booksEmoji,
                    disabled: true
                },
            ]
        }
    ], [t])

    const onChange = useDebouncedCallback(async (api: any, event: any) => {
        if (editorRef.current) {
            const savedData = await editorRef.current.save();
            setSummaryBlocks(savedData);
        }
    }, 1000)

    const getNoteSummary = useCallback(async () => {
        const response = await getNoteSummaryAction({
            noteId: note.id
        })
        setSummaryBlocks({
            time: new Date().getTime(),
            blocks: parseSummary(response)
        })
        setIsLoading(false)
    }, [note.id])

    const createNoteSummary = useCallback(async () => {
        setIsLoading(true)
        const response = await createNoteSummaryAction({
            noteId: note.id,
            languageId: selectedLanguageId
        })
        if (!response) {
            setIsLoading(false)
            return
        }
        setSummaryBlocks({
            time: new Date().getTime(),
            blocks: parseSummary(response)
        })
        setIsLoading(false)
    }, [note.id, selectedLanguageId])

    useEffect(() => {
        setSummaryBlocks({
            time: new Date().getTime(),
            blocks: DEFAULT_SUMMARY.blocks
        })
    }, [summaryNote])

    useEffect(() => {
        getNoteSummary()
    }, [getNoteSummary]);

    return (
        <div className={'summary-view'}>
            {isOptionsModalOpen && (
                <NoteExportModal
                    isOpen={isOptionsModalOpen}
                    onClose={() => setIsOptionsModalOpen(false)}
                    summaries={summaryBlocks.blocks}
                />
            )}
            <div className={"summary-view-toolbar"}>
                <div className={"summary-view-toolbar-wrapper"}/>
                <div className={"summary-view-toolbar-wrapper"}>
                    {/*<CommonButton className={"summary-view-toolbar-button"}*/}
                    {/*              onClick={() => {}}>*/}
                    {/*    {t("share")}*/}
                    {/*</CommonButton>*/}
                    <GrayButton className={"summary-view-toolbar-button"}
                                onClick={async () => {
                                    try {

                                        const plainBlob = new Blob([summaryBlocks.blocks.reduce((result: string, summary: any) => {
                                            switch (summary.type) {
                                                case 'header':
                                                    return result + `${summary.data.text}\n\n`
                                                case 'list':
                                                    return result + `- ${summary.data.items.join('\n- ')}\n\n`
                                                case 'paragraph':
                                                    return result + `${summary.data.text}\n\n`
                                                default:
                                                    return result
                                            }
                                        }, '')], {
                                            type: "text/plain"
                                        })

                                        const htmlBlob = new Blob([`<meta charset="utf-8">${summaryBlocks.blocks.reduce((result: string, summary: any) => {
                                            switch (summary.type) {
                                                case 'header':
                                                    return result + `<h${summary.data.level}>${summary.data.text}</h${summary.data.level}><p/>`
                                                case 'list':
                                                    return result + `<ul>${summary.data.items.reduce((result: string, item: string) => result + `<li>${item}</li>`, ``)}</ul><p/>`
                                                case 'paragraph':
                                                    return result + `<p>${summary.data.text}</p><p/>`
                                                default:
                                                    return result
                                            }
                                        }, '')}`], {
                                            type: "text/html"
                                        })

                                        await navigator.clipboard.write([new ClipboardItem({
                                            'text/plain': plainBlob,
                                            'text/html': htmlBlob,
                                        })])

                                        toast.success(t('success-copy'), {
                                                autoClose: 5000,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                draggable: true,
                                                progress: undefined,
                                            }
                                        )
                                    } catch (e) {
                                        console.error(e)
                                    }
                                }}>
                        {t("copy-to-clipboard")}
                    </GrayButton>
                </div>
            </div>
            <div className={"summary-view-body"}>
                {/*<h2 className={"summary-view-title"}>{note.name}</h2>*/}
                {isLoading ?
                    <Skeleton height={100} count={5}/>
                    :
                    summaryBlocks.blocks.length > 0 ?
                        <BlockEditor id={'editorjs-summary'}
                                     onSave={setSummaryBlocks}
                                     editorConfig={{
                                         data: summaryBlocks,
                                         onChange,
                                         inlineToolbar: ['convertTo', 'bold', 'italic', 'underline', 'marker', 'inlineCode'],
                                     }}/>
                        :
                        <div className={"summary-view-template"}>

                            <div className={"summary-view-template-header"}>
                                <h2 className={"summary-view-template-header-title"}>{t("summary-template-select")}</h2>
                            </div>

                            <form className={"summary-view-template-list"}>
                                {templateList.map((templateList) => {
                                    return <div key={templateList.title} className='summary-view-template-list-div'>
                                        <h3 className={"summary-view-template-list-title"}>{templateList.title}</h3>
                                        <div className={"summary-view-template-sublist"}>
                                            {templateList.sublist.map((template) => {
                                                return <label className={cn("summary-view-template-label", {
                                                    // @ts-ignore
                                                    active: selectedTemplate === template.value,
                                                    disabled: template.disabled
                                                })}
                                                              key={template.id}
                                                              htmlFor={template.id}>
                                                    <input className={"summary-view-template-radio"}
                                                           id={template.id}
                                                           type={'radio'}
                                                           name={'template'}
                                                           value={template.value}
                                                           onChange={(e) => {
                                                               setSelectedTemplate(template.value)
                                                           }}
                                                           disabled={template.disabled}
                                                    />
                                                    <div className={"summary-view-template-content"}>
                                                        <img src={template.emoji} alt={`${template.label}-emoji`}
                                                             className={"summary-view-template-image"}/>
                                                        <span>{template.label}</span>
                                                    </div>
                                                    {/* {template.label} */}
                                                </label>
                                            })}
                                        </div>
                                    </div>
                                })}
                            </form>

                            <label className={"summary-view-template-language"}>
                                <div className={"summary-view-template-language-label"}>{t("select-language")}</div>
                                <Select className={"summary-view-template-language-select"}
                                        options={languages}
                                        labelKey={"name"}
                                        valueKey={"id"}
                                        value={selectedLanguageId?.toString()}
                                        onChange={(e) => {
                                            setSelectedLanguageId(parseInt(e.target.value))
                                        }}/>
                                <img className={"summary-view-template-language-dropdown-icon"} src={dropdownIcon}
                                     alt={"select summary language"}/>
                            </label>

                            <CommonButton
                                className={"summary-view-template-start-summary"}
                                onClick={createNoteSummary}
                                disabled={!selectedTemplate || isLoading} // 템플릿이 선택되지 않으면 버튼 비활성화
                            >
                                {t("start-summary")}
                            </CommonButton>
                        </div>
                }
            </div>
        </div>
    );
}

export default SummaryView;

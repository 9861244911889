type ConditionalComponentProps = {
    active: boolean,
    children: any
}

function IsActive({active, children}: ConditionalComponentProps) {
    if (active) {
        return children
    }
    return null
}

export default IsActive;

import React from 'react';
import {FiSearch} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import './SearchBar.scss';

type SearchBarProps = {
    keyword?: string;
    setKeyword: (keyword: string) => void;
}

function SearchBar({keyword, setKeyword}: SearchBarProps) {

    const {t} = useTranslation('translation', {keyPrefix: 'SearchBar'});

    const [inputContent, setInputContent] = React.useState<string>('');

    React.useEffect(() => {
        setInputContent(keyword || '');
    }, [keyword])

    return (
        <label className={"search-bar"} htmlFor={"search"}>
            <FiSearch/>
            <input className={"search-bar-input"}
                   id={'search'}
                   type="text"
                   value={inputContent}
                   onChange={(e) => setInputContent(e.target.value)}
                   onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setKeyword(inputContent)
                          }
                   }}
                   placeholder={t('search-placeholder')}/>
        </label>
    );
}

export default SearchBar;

import React, { useEffect, forwardRef, useRef, useState } from 'react';

type InputFitProps = {
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    className?: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    maxLength?: number;
}

const InputFit = forwardRef<HTMLInputElement, InputFitProps>((props, ref) => {
    const measureRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        setWidth(measureRef.current?.offsetWidth || 0)
    }, [props.value]);

    return (
        <>
            <div ref={measureRef} className={props.className} style={{
                position: 'absolute',
                visibility: 'hidden',
                zIndex: -1,
            }}>{props.value}</div>
            <input 
                {...props} 
                ref={ref}
                style={{width: width + 8, cursor: 'text'}}
            />
        </>
    );
});

export default InputFit;

import {createEntityAdapter, createSlice, EntityId, EntityState, Slice} from '@reduxjs/toolkit';
import {RootState} from "./index";
import {AudioFromDB} from "../server/appNotes/type";
import {RecordingSession} from "./appNote";


export type NoteAudio = {
    targetAudioId: number
    startTime: number
    audioStart: boolean,
    audioList: EntityState<RecordingSession, EntityId>
};

// export type Audio = AudioFromDB

type NoteAudioRedux = NoteAudio

const AudioAdapter = createEntityAdapter<RecordingSession>({
    // @ts-ignore
    selectId: (session: RecordingSession) => session.id,
    sortComparer: (a, b) => a.id - b.id
});

export const {
    selectAll: selectAllAudios,
    selectIds: selectAudiosIds,
    selectById: selectAudioById,
} = AudioAdapter.getSelectors<RootState>((state) => state.noteAudio.audioList);


const INITIAL_NOTE_AUDIO_STATE: NoteAudioRedux = {
    targetAudioId: 0,
    startTime: 0,
    audioStart: false,
    audioList: AudioAdapter.getInitialState({}),
}

const noteAudioSlice: Slice<NoteAudioRedux> = createSlice({
    name: 'noteAudio',
    initialState: INITIAL_NOTE_AUDIO_STATE,
    reducers: {
        startAudio: (state, action) => {
            state.targetAudioId = action.payload.targetAudioId
            state.startTime = action.payload.startTime
            state.audioStart = true
        },
        startedAudio: (state, action) => {
            state.audioStart = false
        },
        upsertAudios: (state, action) => {
            AudioAdapter.upsertMany(state.audioList, action.payload)
        },
        clearAudio: (state) => {
            return INITIAL_NOTE_AUDIO_STATE
        }
    },
});

export const {
    startAudio, startedAudio, upsertAudios, clearAudio
} = noteAudioSlice.actions;

export default noteAudioSlice;

import ReactGA from "react-ga4";

export const GaEvent = (props: {
    category: string;
    action: string;
    label?: string;
    value?: number;
    nonInteraction?: boolean;
    transport?: "beacon" | "xhr" | "image";
                        }) => {
    if (process.env.NODE_ENV !== 'development') {
        return
    }
    ReactGA.event(props);
}

//// 회원가입(로그인)시 버튼 클릭 이벤트 ////

// 1. 새로운 노트 버튼 클릭(로그인 안된 상태)
export const newNoteButtonToLoginClickedEvent = () => {
    GaEvent({
        category: 'button_click',
        action: 'new_note_button_to_login_clicked',
    })
}

// 2. 로그인 버튼 클릭
export const loginButtonClickedEvent = () => {
    GaEvent({
        category: 'button_click',
        action: 'login_button_clicked',
    })
}

// 3. 노티파이 가이드 노트 클릭
// export const notifaiGuideNoteClickedEvent = () => {
//     GaEvent({
//         category: 'button_click',
//         action: 'notifai_guide_note_clicked',
//     })
// }


// 노티파이 시작 모달에서 '구글로 로그인' 클릭 이벤트
export const googleLoginButtonClickedEvent = () => {
    GaEvent({
        category: 'button_click',
        action: 'google_login_button_clicked',
    })
}

// 구글 로그인 성공 이벤트
export const googleLoginSuccessEvent = () => {
    GaEvent({
        category: 'login',
        action: 'google_login_success',
    });
};


// '새로운 노트' 버튼 클릭 이벤트 (로그인 된 상태)
export const newNoteButtonClickedEvent = () => {
    GaEvent({
        category: 'button_click',
        action: 'new_note_button_clicked',
    })
}


//// 폴더(디바이더) 관련 ////

// 1. 새로운 폴더 생성 이벤트
export const folderCreatedEvent = () => {
    GaEvent({
        category: 'folder',
        action: 'created_folder_button_clicked',
    });
};

// 2. 폴더 이름 변경 이벤트
export const folderRenamedEvent = (oldName: string, newName: string) => {
    GaEvent({
        category: 'folder',
        action: 'renamed',
        label: `From: ${oldName}, To: ${newName}`,
    });
};

// 3-1. 폴더 삭제 버튼 클릭
export const folderDeleteButtonClickedEvent = (folderName: string) => {
    GaEvent({
        category: 'button_click',
        action: 'folder_delete_button_clicked',
        label: `Folder Name: ${folderName}`,
    });
}

// 3-2. 폴더 삭제 이벤트
export const folderDeletedEvent = (folderName: string) => {
    GaEvent({
        category: 'folder',
        action: 'deleted',
        label: `Folder Name: ${folderName}`,
    });
};


//// 주제(과목, subject) 관련 ////

// 1. 새로운 주제 생성 이벤트
export const subjectCreatedEvent = () => {
    GaEvent({
        category: 'subject',
        action: 'created_subject_button_clicked',
    });
};

// 2. 주제 이름 변경 이벤트
export const subjectRenamedEvent = (oldName: string, newName: string) => {
    GaEvent({
        category: 'subject',
        action: 'renamed',
        label: `From: ${oldName}, To: ${newName}`,
    });
};

// 3-1. 주제 삭제 버튼 클릭
export const subjectDeleteButtonClickedEvent = (subjectName: string) => {
    GaEvent({
        category: 'button_click',
        action: 'subject_delete_button_clicked',
        label: `Subject Name: ${subjectName}`,
    });
}

// 3-2. 주제 삭제 이벤트
export const subjectDeletedEvent = (subjectName: string) => {
    GaEvent({
        category: 'subject',
        action: 'deleted',
        label: `Subject Name: ${subjectName}`,
    });
};


// 드래그앤드롭으로 노트를 주제(subject)로 할당하는 이벤트
export const noteAssignedToSubjectEvent = (subjectId: number, noteId: number) => {
    GaEvent({
        category: 'drag_and_drop',
        action: 'note_assigned_to_subject',
        label: `Note: ${noteId} assigned to Subject: ${subjectId}`,
    });
};

// 강의자료 파일 선택(업로드) 이벤트
export const referenceFileUploadEvent = () => {
    GaEvent({
        category: 'file_upload',
        action: 'reference_file_selected',
    });
};

// (파일 업로드하는 화면에서) 녹음 시작 버튼 클릭 이벤트
export const recordingStartButtonClickedEvent = () => {
    GaEvent({
        category: 'button_click',
        action: 'recording_start_clicked',
        label: 'Start recording button clicked',
    });
}

// 녹음 일시중지 이벤트
export const recordingPausedEvent = () => {
    GaEvent({
        category: 'recording',
        action: 'paused',
        label: 'Recording paused',
    });
}

// 녹음 재시작 이벤트
export const recordingResumedEvent = () => {
    GaEvent({
        category: 'recording',
        action: 'resumed',
        label: 'Recording resumed',
    });
}

// 녹음 종료 이벤트 // 모달창이 뜬 뒤, 녹음을 정말 종료한 경우가 아니라, '녹음 종료' 버튼을 누른 경우
export const recordingEndedEvent = () => {
    GaEvent({
        category: 'recording',
        action: 'ended',
        label: 'Recording ended',
    });
}


// '녹음 이어하기' 버튼 클릭 이벤트
export const continueRecordingEvent = () => {
    GaEvent({
        category: 'Audio',
        action: 'Continue_Recording_Click',
        label: 'User clicked continue recording',
    });
};

// 전체화면 버튼 클릭 이벤트
export const fullScreenButtonClickedEvent = () => {
    GaEvent({
        category: 'Reference',
        action: 'FullScreen_Button_Clicked',
        label: 'User clicked the full screen button',
    });
};

// 스크롤로 페이지 넘김 이벤트
export const pageScrolledEvent = (currentPage: number) => {
    GaEvent({
        category: 'Reference',
        action: 'Page_Scrolled',
        label: `Scrolled to page ${currentPage}`,
    });
};

// 키보드로 페이지 넘김 이벤트
export const pageKeyPressedEvent = (currentPage: number) => {
    GaEvent({
        category: 'Reference',
        action: 'Page_Key_Pressed',
        label: `Navigated to page ${currentPage} using keyboard`,
    });
};


// 스크립트 재매칭 이벤트
export const scriptPageRematchEvent = () => {
    GaEvent({
        category: 'Script',
        action: 'script_page_rematched',
        label: 'Script rematched with a page',
    });
};


// 스크립트 텍스트 수정 이벤트 트래킹 함수
export const textUpdatedEvent = () => {
    GaEvent({
        category: 'Script',
        action: 'text_updated',
        label: 'User updated the script text',
    });
};

// 텍스트 메모 생성 이벤트 트래킹 함수
export const textNoteCreatedEvent = (pageIndex: number) => {
    GaEvent({
        category: 'Note',
        action: 'text_note_created',
        label: `Text note created on page ${pageIndex}`,
    });
};

// 노트 내보내기 클릭 이벤트 트래킹 함수
export const noteExportClickedEvent = (exportType: 'markdown' | 'word' | 'pdf') => {
    GaEvent({
        category: 'Note',
        action: 'export_note',
        label: `Exported note as ${exportType}`,
    });
};


//// 다시듣기 ////
// 타임라인 클릭으로 다시듣기 시작
export const timelineReplayEvent = () => {
    GaEvent({
        category: 'Audio',
        action: 'replay_script_click',
        label: 'Replay started by script click',
    });
};

// // 재생 버튼 클릭 이벤트 트래킹 함수
// export const playButtonReplayEvent = () => {
//     GaEvent({
//         category: 'Audio',
//         action: 'replay_play_button',
//         label: 'Replay started by play button',
//     });
// };

// 스페이스바로 다시듣기 시작
export const spacebarReplayEvent = () => {
    GaEvent({
        category: 'Audio',
        action: 'replay_spacebar',
        label: 'Replay started by spacebar',
    });
};

//3. '[', ']' 로 오디오 재생위치 변경
export const arrowKeyMoveEvent = (direction: 'left' | 'right') => {
    GaEvent({
        category: 'Audio',
        action: 'audio_position_changed',
        label: `Audio position moved ${direction}`,
    });
};

// 스크립트 클릭으로 오디오 재생
export const scriptClickReplayEvent = () => {
    GaEvent({
        category: 'Audio',
        action: 'replay_script_click',
        label: 'Replay started by script click',
    });
};



export const rangeInt = (num1: number, num2?: number, num3?: number): number[] => {
    let start: number
    let end: number
    let diff: number

    if (num2 === undefined && num3 === undefined) {
        start = 0
        end = num1
        diff = 1
    } else if (num3 === undefined) {
        start = num1
        end = num2 as number
        diff = 1
    } else {
        start = num1
        end = num2 as number
        diff = num3
    }

    const arr: number[] = []

    for (let i = start; i < end; i += diff) {
        arr.push(i)
    }

    return arr
}

import React, {useCallback, useEffect, useState} from 'react';
import {
    createNoteMemoAction,
    deleteNoteMemoAction,
    getNoteMemosAction,
    updateNoteMemoAction
} from "../../../action/appNoteAction";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import useInit from "../../../hook/useInit";
import TextareaAutosize from "react-textarea-autosize";
import './NoteMemoView.scss';
import {secondToTimestamp} from "../../../util/timeUtils";
import {useTranslation} from "react-i18next";
import {findAndStartNoteAudioAction} from "../../../action/noteAudioAction";

type NoteMemoViewProps = {
    isRecording: boolean
    setIsKeyboardBlock: (value: React.SetStateAction<boolean>) => void
    recordingTimestamp: number
}

function NoteMemoView({isRecording, setIsKeyboardBlock, recordingTimestamp}: NoteMemoViewProps) {

    const {t} = useTranslation("translation", {keyPrefix: "NoteMemoView"})

    const note = useSelector((state: RootState) => state.appNote)

    const [memosOrigin, setMemosOrigin] = useState<any[]>([]);
    const [memos, setMemos] = useState<any[]>([]);
    const [inputText, setInputText] = useState<string>("");

    const getNoteMemo = useCallback(async () => {
        const result = await getNoteMemosAction({noteId: note.id})
        setMemosOrigin(result)
    }, [note.id])

    useInit(getNoteMemo)

    useEffect(() => {
        setMemos(memosOrigin)
    }, [memosOrigin]);

    const onFocus = useCallback(() => {
        setIsKeyboardBlock(true)
    }, [setIsKeyboardBlock])

    const onCreateMemo = useCallback(async () => {

        if (inputText === "") {
            return
        }

        const result = await createNoteMemoAction({
            noteId: note.id,
            text: inputText,
            timestamp: (isRecording && recordingTimestamp > 0) ? recordingTimestamp : undefined,
        })

        if (!result) {
            return
        }

        setMemosOrigin(prev => [...prev, result])
        setInputText("")

    },[inputText, isRecording, note.id, recordingTimestamp])

    const onChangeMemo = useCallback(async (e: any) => {
        const noteMemoId = parseInt(e.target.id)

        setMemos(prev => prev.map(memo => memo.id === noteMemoId ? ({
            ...memo,
            text: e.target.value
        }) : memo))
    }, [])

    const onKeyDown = useCallback(async (e: any) => {
        if (e.nativeEvent.isComposing) {
            return;
        }

        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault()
            e.currentTarget.blur()
        }
    }, [])

    const onUpdateMemo = useCallback(async (e: any) => {

        setIsKeyboardBlock(false)

        const noteMemoId = parseInt(e.target.id)

        if (memosOrigin.find(memo => memo.id === noteMemoId).text === e.target.value) {
            return
        }

        if (e.target.value === "") {
            const result = await deleteNoteMemoAction({
                noteMemoId,
            })

            if (!result) {
                return
            }

            setMemosOrigin(prev => prev.filter(memo => memo.id !== noteMemoId))
            return
        }

        const result = await updateNoteMemoAction({
            noteMemoId,
            text: e.target.value,
            timestamp: undefined,
        })

        if (!result) {
            return
        }

        setMemosOrigin(prev => prev.map(memo => memo.id === noteMemoId ? result : memo))
    }, [memosOrigin, setIsKeyboardBlock])

    return (
        <div className={"note-memo-view"}>

            <div className={"note-memo-view-body"}>
                <h4 className="note-memo-view-title">{t('memo')}</h4> {/* 메모 */}
                <div className="note-memo-view-divider"></div>
                {memos.map((memo, index) => <div key={index} className={"note-memo-view-item"}>
                    {memo.timestamp && <button className={"note-memo-view-timestamp"}
                                               onClick={async () => {
                                                   if (!isRecording){
                                                       await findAndStartNoteAudioAction({startTime: memo.timestamp})
                                                   }
                                               }}
                    >
                        {secondToTimestamp(memo.timestamp)}
                    </button>}
                    <TextareaAutosize id={`${memo.id}`}
                                      className={"note-memo-view-input"}
                                      value={memo.text}
                                      onChange={onChangeMemo}
                                      onKeyDown={onKeyDown}
                                      onFocus={onFocus}
                                      onBlur={onUpdateMemo}
                    />
                </div>)}

                <label className={"note-memo-view-new"} htmlFor={"newInput"}>
                    {isRecording && <div className={"note-memo-view-timestamp"}>
                        {secondToTimestamp(recordingTimestamp)}
                    </div>}
                    <TextareaAutosize id={"newInput"}
                                      className={"note-memo-view-input"}
                                      value={inputText}
                                      onChange={(e: any) => {
                                          setInputText(e.target.value)
                                      }}
                                      onKeyDown={async (e: any) => {
                                          if (e.key === "Enter" && !e.shiftKey) {
                                              if (e.nativeEvent.isComposing) {
                                                  return;
                                              }
                                              e.preventDefault()
                                              await onCreateMemo()
                                          }
                                      }}
                                      onFocus={onFocus}
                                      onBlur={onCreateMemo}
                                      placeholder={t('placeholder')}
                    />
                </label>

            </div>


        </div>
    );
}

export default NoteMemoView;

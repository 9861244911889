import React, {useCallback, useEffect, useState} from 'react';
import {Outlet} from "react-router-dom";
import './LeftNavLayout.scss';
import LeftNav from "../nav/LeftNav";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {Mobile, useResponsive} from "../../style/Responsive";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectAppUser, selectAppUserState} from "../../../redux/appUser";
import SettingModal from "../setting/SettingModal";

function LeftNavLayout() {

    const {t: mobileTranslation} = useTranslation('translation', {keyPrefix: "Mobile"});

    const appUser = useSelector(selectAppUser)

    const [isOpen, setIsOpen] = useState<boolean>(true)

    const {isMobile, isMobileDevice} = useResponsive()

    const [isOpenSettingModal, setIsOpenSettingModal] = useState<boolean>(false)
    const onClickSetting = useCallback(() => {
        setIsOpenSettingModal(true)
    }, [])

    useEffect(() => {
        if (!appUser.id) {
            setIsOpenSettingModal(false)
        }
    }, [appUser])

    useEffect(() => {
        if (!isMobile) {
            setIsOpen(true)
        }
    }, [isMobile]);

    return (
        <DndProvider backend={HTML5Backend}>
            <SettingModal isOpen={isOpenSettingModal} onClose={() => setIsOpenSettingModal(false)}/>
            <div className={"left-nav-layout-container"}>
                <LeftNav isOpen={isOpen} setIsOpen={setIsOpen} />
                <Outlet context={{isOpen}}/>
                <Mobile>
                    <button className={"account"} onClick={onClickSetting}>
                        <img className={"account-img"} src={appUser.profileImage} alt={'user-profile'}/>
                    </button>
                </Mobile>
            </div>
        </DndProvider>
    );
}

export default LeftNavLayout;

import React, {useCallback} from 'react';
import './InputFile.scss';
import { FileUploader } from 'react-drag-drop-files';
import {AiOutlineClose} from "react-icons/ai";
import {FiUpload} from "react-icons/fi";
import cn from "classnames";


type InputFileProps = {
    className?: string;
    value?: File;
    onChange: (e: any) => void;
    id: string;
    options?: any[];
    validation?: (value: string) => boolean;
    fileProps: {
        types: string[];
        maxSize: number;
        minSize: number;
        setPreview: (preview: any) => void;
    };
    children?: React.ReactNode;
};

const InputFile = (props: InputFileProps) => {
    const {
        id,
        value,
        onChange,
        fileProps,
        className
    } = props || {};

    const handleChange = useCallback((files: any) => {
        if (!files) return;
        if (!fileProps) return;

        const file = files;
        const reader = new FileReader();

        switch (file.type) {
            case 'text/html':
                reader.onload = e => {
                    const contents = e?.target?.result;
                    fileProps.setPreview(contents);
                };
                reader.readAsText(file);
                break;
            default:
                reader.readAsDataURL(file);
        }
        reader.onloadend = () => {
            if (file.size > 2 * 1024 * 1024 * 1024) {
                alert('2GB OVER');
                return;
            } else {
                onChange({
                    target: {
                        id,
                        value: file,
                    },
                });
                fileProps.setPreview(reader.result);
            }
        };
    }, [fileProps, id, props]);

    const clearFile = useCallback((e: any) => {
        onChange({
            target: {
                id,
                value: null,
            },
        });
    }, [id, onChange]);

    return (
        <FileUploader
            handleChange={handleChange}
            disabled={Boolean(value)}
            name={id}
            types={fileProps.types}
            maxSize={1024}
            minSize={0}>
            {props?.children ? props.children : <div className={cn("file-uploader", className)}>
                {
                    value ?
                        <div className="file-uploader-preview">
                            {value?.name}
                            <button onClick={clearFile}>
                                <AiOutlineClose/>
                            </button>
                        </div>
                        :
                        <div className="file-uploader-content">
                            <FiUpload/>
                            파일을 드래그하여 업로드하세요
                        </div>

                }
            </div>}
        </FileUploader>
    );
};

export default InputFile;

import {saveAs} from 'file-saver';
import {uploadImagesToS3Action} from '../action/appNoteAction';
import {AppNote, ScriptBlock, selectAllRecordingSessions} from "../redux/appNote";
import {storeState} from "../redux";
import _ from "lodash";
import {rangeInt} from "./ArrayUtil";

export const createAndDownloadMarkdown = async ({
                                                    pageImages,
                                                    note,
                                                }: {
    pageImages: ArrayBuffer[],
    note: AppNote,
}): Promise<boolean> => {
    try {

        const allRecordingSessions = selectAllRecordingSessions(storeState())

        const blocks = _.sortBy(
            allRecordingSessions.reduce((result: any[], session: any) => result.concat(session.scriptBlocks), []),
            ['referenceFilePageIndex', 'recordingSessionId', 'order']
        )

        const range = note?.referenceFileTotalPages ? rangeInt(note.referenceFileTotalPages) : rangeInt(-1, 0)

        const descriptions = range.map((pageIndex: number) => blocks
            .filter((block: any) => block.referenceFilePageIndex === pageIndex)
            .reduce((result: string, scriptBlock: ScriptBlock) => {
                const script = scriptBlock.text
                    .split("{:")
                    .reduce((acc, cur) => {
                        if (cur.includes(":}")) {
                            return acc + cur.split(":} ")[1]
                        }
                        return acc
                    }, "")
                if (!result) {
                    return script
                }
                return result + "\n\n " + script
            }, "")
        )

        // 페이지 이미지를 S3에 업로드하고 URL 가져오기
        const imageFiles = pageImages.map((imageBuffer, index) => {
            return new File([imageBuffer], `page_${index + 1}.png`, {type: 'image/png'});
        });
        const result = await uploadImagesToS3Action({
            noteId: note.id,
            images: imageFiles
        });

        if (!result || !result.imageS3Urls) {
            console.error(`이미지 업로드에 실패했습니다. 이미지 URL을 가져올 수 없습니다.`);
            return false;
        }
        const {imageS3Urls} = result;

        const markdownContent = descriptions.map((description: string, i: number) => {
            const imageMarkdown = `![Page ${i + 1} Image](${imageS3Urls[i]})`;
            return `${imageMarkdown}\n\n${description}\n\n---\n`;
        }).join('\n');

        // Blob 생성 및 다운로드
        const blob = new Blob([markdownContent], {type: 'text/markdown;charset=utf-8'});
        const fileName = `${note?.name ?? 'untitled'}.md`;
        saveAs(blob, fileName);

        // 클립보드에 복사
        await navigator.clipboard.writeText(markdownContent);

        return true;
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error(`마크다운 파일 생성 중 오류가 발생했습니다: ${error.message}`);
        } else {
            console.error('마크다운 파일 생성 중 알 수 없는 오류가 발생했습니다.');
        }
        return false;
    }
};

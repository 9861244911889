import {NotifaiServer} from "..";
import {API} from "../class";
import joi from "joi";
import {SubjectFromDB} from "./type";
import dayjs from "dayjs";
import {camelCase} from "../parser";

export const createSubjectAPI = new API<
    {
        body: {
            name: string,
            folder_id?: number
        }
    },
    SubjectFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/subjects/",
    method: "post",
    requestValidator: joi.any(),
    responseParser: (response: any) => {
        const subject = camelCase(response)

        return {
            ...subject,
            color: subject?.color?.color ?? "#000000",
        }
    }
});

export const getSubjectsAPI = new API<
    {},
    SubjectFromDB[]
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/subjects/",
    method: "get",
    requestValidator: joi.any(),
    responseParser: (response: any) => {
        return camelCase(response).map((subject: any) => {
            return {
                ...subject,
                color: subject?.color?.color ?? "#000000",
            }
        })
    }
});

export const updateSubjectAPI = new API<
    {
        params: {
            subjectId: number;
        },
        body: {
            name: string;
            folder_id?: number;
        };
    },
    SubjectFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/subjects/:subjectId/",
    method: "put",
    requestValidator: joi.object({
        params: joi.object({
            subjectId: joi.number().required(),
        }),
        body: joi.object({
            name: joi.string().required(),
            folder_id: joi.number(),
        }),
    }),
    responseParser: (response: any) => {
        const subject = camelCase(response)

        return {
            ...subject,
            color: subject?.color?.color ?? "#000000",
        }
    }
});

export const deleteSubjectAPI = new API<
    {
        params: {
            subjectId: number;
        },
    },
    boolean
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/subjects/:subjectId/",
    method: "delete",
    requestValidator: joi.object({
        params: joi.object({
            subjectId: joi.number().required(),
        }),
    }),
});


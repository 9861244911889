import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './NoteSummary.scss';
import {useSelector} from "react-redux";
import {ScriptBlock, selectAllRecordingSessions, selectAppNote} from "../../../redux/appNote";
import {Document, Page} from "react-pdf";
import {HTML5Backend} from "react-dnd-html5-backend";
import {animated} from "@react-spring/web";
import cn from "classnames";
import {DndProvider} from "react-dnd";
import {useDrag} from "@use-gesture/react";
import _ from "lodash";
import {rangeInt} from "../../../util/ArrayUtil";
import SummaryView from "../../component/note/SummaryView";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate} from "react-router-dom";
import {Responsive} from "../../style/Responsive";

const MIN_VIEW_WIDTH = 150;

const initialLeftRatio = 0.5

function NoteSummary() {

    const navigate = useNavigate()

    const note = useSelector(selectAppNote)

    const [fixedLeftWidth, setFixedLeftWidth] = useState(
        Math.round(((window.innerWidth - 58) * initialLeftRatio))
    );
    const [leftWidth, setLeftWidth] = useState(
        Math.round(((window.innerWidth - 58) * initialLeftRatio))
    );

    const widthBind = useDrag(({active, movement}) => {

        const calculatedWidth = (left: number) => {
            return Math.max(Math.min(Math.round(left + movement[0]), window.innerWidth - MIN_VIEW_WIDTH), MIN_VIEW_WIDTH)
        }

        setLeftWidth(calculatedWidth(fixedLeftWidth))
        if (!active) {
            setFixedLeftWidth(prevState => calculatedWidth(prevState))
        }
    })

    const onClickBack = useCallback(() => {
        navigate(-1)
    }, [navigate])

    return (
        <div id={"NoteSummary"} className={"note-summary"}>
            <button className={"note-summary-back-button"}
                    onClick={onClickBack}>
                <BiArrowBack/>
            </button>
            <DndProvider backend={HTML5Backend}>
                <Responsive isDevice={false}
                            desktop={
                                <div className={"note-summary-layout"}
                                     style={{gridTemplateColumns: `${leftWidth}px 9px 1fr`}}
                                     onClick={(e) => {
                                         e.stopPropagation()
                                     }}>
                                    <OriginWrapper referenceFile={note?.referenceFileS3Path}
                                                   width={fixedLeftWidth}
                                                   itemComponent={({block, index}: { block: string, index: number }) =>
                                                       <div className={"note-summary-script-block"}
                                                            key={`block___${index}`}>
                                                           {block}
                                                       </div>}
                                    />
                                    <animated.div
                                        className={cn("note-summary-layout-vertical-border")}
                                        {...widthBind()}>
                                        <div className={"note-summary-layout-vertical-border-line"}/>
                                    </animated.div>
                                    <SummaryView/>
                                </div>
                            }
                            mobile={<SummaryView/>}
                />
            </DndProvider>
        </div>
    );
}

function OriginWrapper({referenceFile, width, itemComponent}: {
    referenceFile: any,
    width: number,
    itemComponent: any
}) {

    const recordingSessionDataList = useSelector(selectAllRecordingSessions)

    const [pdfInfo, setPdfInfo] = React.useState<{
        numPages: number
    }>({
        numPages: 0,
    });
    const [scriptData, setScriptData] = React.useState<any[]>([])

    const noteScriptBlocks = useMemo(() => {
        if (!recordingSessionDataList) {
            return []
        }

        const blocks = _.sortBy(
            recordingSessionDataList.reduce((result: any[], session: any) => result.concat(session.scriptBlocks), []),
            ['referenceFilePageIndex', 'recordingSessionId', 'order']
        )

        const range = pdfInfo.numPages ? rangeInt(pdfInfo.numPages) : rangeInt(-1, 0)

        return range.map((pageIndex: number) => ({
            pageIndex,
            scriptBlocks: [
                ...blocks.filter((block: any) => block.referenceFilePageIndex === pageIndex)
            ]
        }))

    }, [recordingSessionDataList, pdfInfo.numPages])

    useEffect(() => {
        if (noteScriptBlocks.length > 0) {
            setScriptData(noteScriptBlocks)
        }
    }, [noteScriptBlocks]);

    const onFileLoad = useCallback((pdf: any) => {
        setPdfInfo(pdf._pdfInfo)
    }, [])

    return (
        <div className={"note-summary-origin"}>
            {
                referenceFile ?
                    <Document file={referenceFile} onLoadSuccess={onFileLoad} loading={<></>}>
                        {pdfInfo && rangeInt(pdfInfo.numPages).map((index) => (<div key={`page___${index}`}>
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width - 40} />
                            {itemComponent({
                                block: scriptData.find(({pageIndex}: {pageIndex: number}) => pageIndex === index)?.scriptBlocks
                                    ?.reduce((acc: string, cur: {text: string}) => {
                                        return acc + '\n' + cur.text.split("{:")
                                            .reduce((acc: string, cur: string) => {
                                                if (cur.includes(":}")) {
                                                    return acc + cur.split(":} ")[1]
                                                }
                                                return acc
                                            }, '')
                                    }, '')

                            })}
                            {index !== pdfInfo.numPages - 1 && <div className={"note-summary-page-separator"}/>}
                        </div>))}
                    </Document>
                    :
                    itemComponent({
                        block: scriptData?.reduce((acc: string, cur: {scriptBlocks: ScriptBlock[]}) => {
                            return acc + cur?.scriptBlocks
                                ?.reduce((acc: string, cur: {text: string}) => {
                                    return acc + '\n' + cur.text.split("{:")
                                        .reduce((acc: string, cur: string) => {
                                            if (cur.includes(":}")) {
                                                return acc + cur.split(":} ")[1]
                                            }
                                            return acc
                                        }, '')
                                }, '')
                        }, '')
                    })

            }
        </div>
    );
}

export default NoteSummary;

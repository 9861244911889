import React, {useCallback, useEffect, useRef} from 'react';

type useAudioProps = {
    url?: string,
    onEnd?: () => void,
    onPlayError?: (e: any) => void
}

function useAudio({url, onEnd = () => {}, onPlayError = (e) => {}}: useAudioProps) {

    const audio = useRef<HTMLAudioElement>()

    const [isPlaying, setIsPlaying] = React.useState(false)

    const play = useCallback(async (startTime?: number) => {
        try {
            if (isPlaying) {
                return
            }

            if (!audio.current){
                return
            }


            if (startTime){
                audio.current.currentTime = startTime
            }

            await audio.current?.play()
        } catch (e) {
            onPlayError(e)
            return
        }
    }, [isPlaying, onPlayError])

    useEffect(() => {
        if (url) {
            if (audio.current) {
                audio.current?.pause()
            }
            audio.current = new Audio(url)
            audio.current.onplay = () => {
                setIsPlaying(true)
            }
            audio.current.onpause = () => {
                setIsPlaying(false)
            }
            audio.current.onended = () => {
                onEnd()
            }
        }
    }, [onEnd, url]);

    useEffect(() => {
        return () => {
            audio.current?.pause()
        }
    }, []);

    return {
        audio: audio.current,
        isPlaying,
        play,
        pause: () => {
            audio.current?.pause()
        },
    }
}

export default useAudio;

import React, {useCallback} from 'react';
import './NewNote.scss';
import NoteInit from "../../component/note/NoteInit";
import NoteHeader from "../../component/note/NoteHeader";
import {useTranslation} from "react-i18next";
import {createNoteAction} from "../../../action/appNoteAction";
import {useLocation} from "react-router-dom";
import useHideChannelTalk from "../../../hook/useHideChannelTalk";

function NewNote() {

    const {t} = useTranslation("translation", {keyPrefix: "NewNote"})

    const location = useLocation();

    const [noteName, setNoteName] = React.useState<string>(t('new-note'))

    const createNewNote = useCallback(async () => {
        return await createNoteAction(noteName, location?.state?.subjectId) /* 새로운 노트 */
    }, [location?.state?.subjectId, noteName])

    useHideChannelTalk()

    return (
        <div id={"NewNote"} className={"note"}>
            <NoteHeader setIsKeyboardBlock={() => {}} isRecording={false} initialName={noteName} setName={setNoteName}/>
            <NoteInit setNoteState={() => {}} setNoteSourceLanguageId={() => {}} createNewNote={createNewNote} />
        </div>

    );
}

export default NewNote;

import {createEntityAdapter, createSlice, EntityId, EntityState, Slice} from '@reduxjs/toolkit';
import {
    ChunkFromDB,
    RecordingSessionBelongFromDB,
    RecordingSessionFromDB,
    ScriptBlockFromDB,
    TextBoxFromDB
} from "../server/appNotes/type";
import {RootState} from "./index";
import {Folder} from "./folderAndSubject";

export type Textbox = TextBoxFromDB
export type RecordingSession = RecordingSessionFromDB & RecordingSessionBelongFromDB
export type ScriptBlock = ScriptBlockFromDB & {chunks?: ChunkFromDB[]}

export type AppNote = {
    id: number
    name: string
    subjectId: number
    referenceFileS3Path: string
    thumbnailS3Path: string
    referenceFileTotalPages: number
    textBoxes: EntityState<Folder, EntityId>
    recordingSessionDataList: EntityState<RecordingSession, EntityId>
    previewText?: string
    createdAt: string
    updatedAt: string
    translation: boolean
    lastUpdated?: string
};

type AppNoteRedux = AppNote & any

const TextboxAdapter = createEntityAdapter<Textbox>({
    // @ts-ignore
    selectId: (textbox: Textbox) => textbox.id,
});

export const {
    selectAll: selectAllTextboxes,
    selectIds: selectTextboxesIds,
    selectById: selectTextboxesById,
} = TextboxAdapter.getSelectors<RootState>((state) => state.appNote.textBoxes);

const RecordingSessionAdapter = createEntityAdapter<RecordingSession>({
    // @ts-ignore
    selectId: (recordingSession: RecordingSession) => recordingSession.id,
});

export const {
    selectAll: selectAllRecordingSessions,
    selectIds: selectRecordingSessionsIds,
    selectById: selectRecordingSessionsById,
} = RecordingSessionAdapter.getSelectors<RootState>((state) => state.appNote.recordingSessionDataList);

const INITIAL_APP_NOTE_STATE: AppNoteRedux = {
    id: 0,
    name: '',
    subjectId: 0,
    referenceFileS3Path: '',
    thumbnailS3Path: '',
    referenceFileTotalPages: 0,
    textBoxes: TextboxAdapter.getInitialState({}),
    recordingSessionDataList: RecordingSessionAdapter.getInitialState({}),
    createdAt: '',
    updatedAt: '',
    translation: false,
    lastUpdated: undefined
}

const appNoteSlice: Slice<AppNoteRedux> = createSlice({
    name: 'appNote',
    initialState: INITIAL_APP_NOTE_STATE,
    reducers: {
        setAppNote: (state, action) => {
            Object.keys(action.payload).forEach((key) => {
                if (key === 'textBoxes' || key === 'recordingSessionDataList') {
                    // TextboxAdapter.setAll(state.textBoxes, action.payload[key])
                    return
                }
                state[key] = action.payload[key]
            })
        },
        upsertTextbox: (state, action) => {
            TextboxAdapter.upsertOne(state.textBoxes, action.payload);
        },
        upsertTextboxes: (state, action) => {
            TextboxAdapter.upsertMany(state.textBoxes, action.payload);
        },
        removeTextbox: (state, action) => {
            TextboxAdapter.removeOne(state.textBoxes, action.payload);
        },
        upsertRecordingSession: (state, action) => {
            RecordingSessionAdapter.upsertOne(state.recordingSessionDataList, action.payload);
        },
        upsertRecordingSessions: (state, action) => {
            RecordingSessionAdapter.upsertMany(state.recordingSessionDataList, action.payload);
        },
        removeRecordingSession: (state, action) => {
            RecordingSessionAdapter.removeOne(state.recordingSessionDataList, action.payload);
        },
        clearAppNote: (state) => {
            return INITIAL_APP_NOTE_STATE
        },
        activateTranslation: (state) => {
            state.translation = true
        },
        deactivateTranslation: (state) => {
            state.translation = false
        },
        setLastUpdated: (state, action) => {
            state.lastUpdated = action.payload
        }
    },

});

export const {
    setAppNote,
    upsertTextbox,
    upsertTextboxes,
    removeTextbox,
    upsertRecordingSession,
    upsertRecordingSessions,
    removeRecordingSession,
    clearAppNote,
    activateTranslation,
    deactivateTranslation,
    setLastUpdated
} = appNoteSlice.actions;

export const selectAppNote = (state: any) => state.appNote;
export const selectAppNoteTranslation = (state: any) => state.appNote.translation;
export const selectAppNoteLastUpdated = (state: any) => state.appNote.lastUpdated;

export default appNoteSlice;

import _ from "lodash";

// @ts-ignore
export const camelCase = (obj: any) => {
    if (Array.isArray(obj)) {
        // @ts-ignore
        return obj.map((v) => camelCase(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result, key) => {
            return {
                ...result,
                [_.camelCase(key)]: camelCase(obj[key]),
            };
        }, {});
    }
    return obj;
};

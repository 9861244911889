import {createTextboxAPI, deleteTextboxAPI, updateTextboxAPI, getTextboxesByPageIndexAPI} from "../server/appNotes/textbox";
import {storeDispatch} from "../redux";
import {removeTextbox, Textbox, upsertTextbox} from "../redux/appNote";


export const createTextboxAction = async (textbox: Textbox) => {
    const {response, error} = await createTextboxAPI.request({
        body: {
            note_id: textbox.noteId,
            reference_file_index: textbox.referenceFileIndex,
            text: textbox.text,
            x_location: textbox.xLocation,
            y_location: textbox.yLocation,
            font_size: textbox.fontSize,
            font_weight: textbox.fontWeight,
            font_color: textbox.fontColor,
            text_box_width: textbox.textBoxWidth,
            text_box_height: textbox.textBoxHeight,
        }
    })

    if (error) {
        return
    }

    storeDispatch(upsertTextbox(response))
    return response
}

export const updateTextboxAction = async (textbox: Textbox) => {
    const {response, error} = await updateTextboxAPI.request({
        params: {textboxId: textbox.id},
        body: {
            note_id: textbox.noteId,
            reference_file_index: textbox.referenceFileIndex,
            text: textbox.text,
            x_location: textbox.xLocation,
            y_location: textbox.yLocation,
            font_size: textbox.fontSize,
            font_weight: textbox.fontWeight,
            font_color: textbox.fontColor,
            text_box_width: textbox.textBoxWidth,
            text_box_height: textbox.textBoxHeight,
        }
    })

    if (error) {
        return
    }

    storeDispatch(upsertTextbox(response))
    return response
}

export const deleteTextboxAction = async (args: {
    textboxId: number,
}) => {
    const {response, error} = await deleteTextboxAPI.request({
        params: {textboxId: args.textboxId},
    })

    if (error) {
        return
    }

    storeDispatch(removeTextbox(args.textboxId))
    return response
}

export const getTextBoxesByPageIndexAction = async (noteId: number, pageIndex: number) => {
    const {response, error} = await getTextboxesByPageIndexAPI.request({
        params: {noteId},
        query: {reference_file_index: pageIndex}
    })

    if (error) {
        return []
    }

    return response
}
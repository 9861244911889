import React from "react";
import { Container } from "./styled";
import { useScrollAnimation } from "../../hook/useScrollAnimation";
import cn from "classnames";


type PropsType = {
  className?: string;
  children: React.ReactNode;
};

export const ScrollAnimationContainer = ({ className, children }: PropsType) => {
  const { ref, isInViewport } = useScrollAnimation();
  return (
    <Container ref={ref} className={cn(className, isInViewport ? "frame-in" : "")}>
      {children}
    </Container>
  );
};

import store, {storeDispatch, storeState} from "../redux";
import {
    Folder, removeFolder, removeSubject,
    selectFolder, selectSubjectsById,
    Subject,
    upsertFolder,
    upsertFolders,
    upsertSubject,
    upsertSubjects
} from "../redux/folderAndSubject";
import {createFolderAPI, deleteFolderAPI, getFoldersAPI, updateFolderAPI} from "../server/appNotes/folder";
import {createSubjectAPI, deleteSubjectAPI, getSubjectsAPI, updateSubjectAPI} from "../server/appNotes/subject";
import {
    createNoteAPI,
    createRecordingSession,
    deleteNoteAPI,
    deleteReferenceFileAPI,
    getNoteAPI,
    getNoteContentsAPI,
    selectNoteAPI,
    updateNoteAPI,
    uploadAudioAPI,
    uploadReferenceFileAPI,
    checkNotionPermissionAPI,
    getNotionAccessTokenAPI,
    exportNoteToNotionAPI,
    getScriptsByPageIndexAPI,
    uploadImagesToS3API,
    getNoteMemoAPI,
    createNoteMemoAPI,
    updateNoteMemoAPI,
    deleteNoteMemoAPI,
    uploadWholeAudioAPI,
    uploadAudioRealtimeAPI
} from "../server/appNotes/note";
import {clearAppNotes, removeAppNote, upsertAppNotes} from "../redux/appNotes";
import {
    clearAppNote, RecordingSession, ScriptBlock,
    selectAllRecordingSessions,
    selectAppNote, selectAppNoteLastUpdated, selectRecordingSessionsById,
    setAppNote, setLastUpdated, upsertRecordingSession,
    upsertRecordingSessions,
    upsertTextboxes
} from "../redux/appNote";
import {
    AudioFromDB, ChunkFromDB,
    NoteFromDB,
    NoteMemoFromDB, PollingScriptBlockFromDB,
    RecordingSessionBelongFromDB,
    ScriptBlockFromDB
} from "../server/appNotes/type";
import {clearAudio, upsertAudios} from "../redux/noteAudio";
import { detectVoice } from "../util/detectVoiceUtils";
import {pollScriptBlockAPI} from "../server/appNotes/scriptBlock";
import _ from "lodash";
import dayjs from "dayjs";

export const initFolderAndSubject = async () => {
    const {response: folders, error: folderError} = await getFoldersAPI.request({})
    const {response: subjects, error: subjectsError} = await getSubjectsAPI.request({})
    if (folderError || subjectsError) {
        return false
    }
    storeDispatch(upsertFolders(folders))
    storeDispatch(upsertSubjects(subjects))
    return true;
}

export const createNewFolderAction = async ({name, parentFolderId}: {name: string, parentFolderId?: number}) => {
    const {response, error} = await createFolderAPI.request({body: {name, parent_folder_id: parentFolderId}})
    if (error) {
        return
    }
    storeDispatch(upsertFolder(response))
}

export const createNewSubjectAction = async ({name, folderId}: {name: string, folderId?: number}) => {
    const {response, error} = await createSubjectAPI.request({body: {name, folder_id: folderId}})
    if (error) {
        return
    }
    storeDispatch(upsertSubject({...response, isNew: true}))
}

const isChildFolder = (folder: Folder, targetFolderId: number): boolean => {
    if (folder.id === targetFolderId) {
        return true
    }
    return folder.children
        .filter((item: Folder | Subject) => item.type === 'folder')
        .some((item: Folder | Subject) => isChildFolder(item as Folder, targetFolderId))
}

export const updateFolderAction = async ({folderId, name, parentFolderId}: {folderId: number, name: string, parentFolderId: number}) => {
    const thisFolder = selectFolder(store.getState() ,folderId)
    if (isChildFolder(thisFolder, parentFolderId)) {
        return
    }
    const {response, error} = await updateFolderAPI.request({params: {folderId}, body: {name, parent_folder_id: parentFolderId}})
    if (error) {
        return
    }
    storeDispatch(upsertFolder(response))
}

export const updateSubjectAction = async ({subjectId, name, folderId}: {subjectId: number, name: string, folderId?: number}) => {
    const {response, error} = await updateSubjectAPI.request({params: {subjectId}, body: {name, folder_id: folderId}})
    if (error) {
        return
    }
    storeDispatch(upsertSubject(response))
}

export const refreshNewSubjectAction = async ({subjectId}: {subjectId: number}) => {
    const subject = selectSubjectsById(storeState(), subjectId)
    storeDispatch(upsertSubject({...subject, isNew: false}))
}

export const deleteFolderAction = async ({folderId}: {folderId: number}) => {
    const {error} = await deleteFolderAPI.request({params: {folderId}})
    if (error) {
        return
    }
    storeDispatch(removeFolder(folderId))
}

export const deleteSubjectAction = async ({subjectId}: {subjectId: number}) => {
    const {error} = await deleteSubjectAPI.request({params: {subjectId}})
    if (error) {
        return
    }
    storeDispatch(removeSubject(subjectId))
}

export const createNoteAction = async (name: string, subject_id?: number): Promise<NoteFromDB | any> => {
    const {response, error} = await createNoteAPI.request({
        body: {name, subject_id}
    })
    if (error) {
        return {}
    }
    storeDispatch(clearAppNote(storeState()))
    storeDispatch(clearAudio(storeState()))
    storeDispatch(setAppNote(response))
    return response as NoteFromDB
}

export const selectOneNoteAction = async (noteId: number) => {
    const {response: note, error: noteError} = await getNoteAPI.request({params: {noteId}})
    const {response: noteContents, error: noteContentsError} = await getNoteContentsAPI.request({params: {noteId}})

    if (noteError || noteContentsError) {
        return
    }
    if (!note || !noteContents) {
        return
    }

    const noteDetail = {
        ...note,
        ...noteContents,
        recordingSessionDataList: noteContents.recordingSessionDataList.map((recordingSession: RecordingSessionBelongFromDB) => {
            return {
                ...recordingSession.recordingSession,
                scriptBlocks: recordingSession.scriptBlocks.filter((scriptBlock: ScriptBlock) => scriptBlock.recordingSessionId === recordingSession.recordingSession.id),
                audios: recordingSession.audios.filter((audio: AudioFromDB) => audio.recordingSessionId === recordingSession.recordingSession.id)
            }
        })
    }
    storeDispatch(clearAppNote(storeState()))
    storeDispatch(clearAudio(storeState()))
    storeDispatch(setAppNote(noteDetail))
    storeDispatch(upsertTextboxes(noteDetail.textBoxes))
    storeDispatch(upsertRecordingSessions(noteDetail.recordingSessionDataList))
    storeDispatch(upsertAudios(noteDetail.recordingSessionDataList))
    return noteDetail
}

export const clearNoteAction = () => {
    storeDispatch(clearAppNote(storeState()))
    storeDispatch(clearAudio(storeState()))
}

export const selectNotesAction = async (args: {
    subjectId?: number,
    keyword?: string,
    offset?: number,
    limit?: number
}): Promise<{
    notes: NoteFromDB[]
    numPages: number
}> => {
    if (args.offset === 1) {
        storeDispatch(clearAppNotes(storeState()))
    }
    const {response, error} = await selectNoteAPI.request({query: {
        subject_id: args.subjectId,
        keyword: args.keyword,
        offset: args.offset,
        limit: args.limit
    }})
    if (error || !response) {
        return {
            notes: [],
            numPages: 0
        }
    }
    storeDispatch(upsertAppNotes(response.notes))
    return response
}

export const updateNoteAction = async (args: {
    noteId: number,
    name?: string,
    subjectId?: number,
    previewText?: string,
}) => {
    const {response, error} = await updateNoteAPI.request({
        params: {noteId: args.noteId},
        body: {
            name: args.name,
            subject_id: args.subjectId,
            preview_text: args.previewText,
        }
    })
    if (error) {
        return
    }
    storeDispatch(setAppNote(response))
    storeDispatch(upsertAppNotes([response]))
}

export const uploadReferenceFileAction = async (args: {
    noteId: number,
    referenceFile: File,
    referenceFileTotalPages: number,
    thumbnailImage: File,
}) => {

    const formData = new FormData();
    formData.append('reference_file', args.referenceFile);
    formData.append('thumbnail_image', args.thumbnailImage);
    formData.append('payload', JSON.stringify({
        reference_file_total_pages: args.referenceFileTotalPages,
    }))

    const {response, error} = await uploadReferenceFileAPI.request({
        params: {noteId: args.noteId},
        body: formData
    })
    if (error) {
        return
    }
    storeDispatch(setAppNote(response))
}

export const deleteReferenceFileAction = async (args: {
    noteId: number,
}) => {

    const {response, error} = await deleteReferenceFileAPI.request({
        params: {noteId: args.noteId}
    })
    if (error) {
        return
    }
    storeDispatch(setAppNote(response))
}

export const createRecordingSessionAction = async ({noteId, languageId}: {
    noteId: number,
    languageId: number
}) => {
    const {response, error} = await createRecordingSession.request({
        body: {
            note_id: noteId,
            language_id: languageId
        }
    })

    if (error || !response) {
        return
    }

    storeDispatch(upsertRecordingSession({
        recordingSessionId: response.id,
        ...response,
        scriptBlocks: [],
        audios: [],
    }))
    return response
}

export const uploadAudioAction = async (args: {
    audioFile: File | Blob,
    audioFileName: string,
    audioFileLength: number,
    recordingSessionId: number,
    referenceFilePageIndex: number,
}) => {
    const voiceDetected = await detectVoice(args.audioFile)

    const formData = new FormData();

    formData.append('audio_file', args.audioFile, args.audioFileName);
    formData.append('payload', JSON.stringify({
        audio_file_name: args.audioFileName,
        audio_file_length: args.audioFileLength,
        recording_session_id: args.recordingSessionId,
        reference_file_page_index: args.referenceFilePageIndex,
        voice_detected: voiceDetected
    }))

    const {response, error} = await uploadAudioAPI.request({
        body: formData
    })
    if (error) {
        return
    }

    storeDispatch(upsertAudios([response]))

    return response
}

export const uploadAudioRealtimeAction = async (args: {
    audioFile: File | Blob,
    audioFileName: string,
    audioFileLength: number,
    recordingSessionId: number,
    referenceFilePageIndex: number,
    timestamp: number,
}) => {
    const formData = new FormData();

    formData.append('audio_file', args.audioFile, args.audioFileName);
    formData.append('payload', JSON.stringify({
        audio_file_name: args.audioFileName,
        recording_session_id: args.recordingSessionId,
        audio_file_length: args.audioFileLength,
        reference_file_page_index: args.referenceFilePageIndex,
        timestamp: args.timestamp,
    }))

    const {response, error} = await uploadAudioRealtimeAPI.request({
        body: formData
    })
    if (error) {
        return
    }

    // storeDispatch(upsertAudios([response]))

    return response
}

export const uploadWholeAudioAction = async (args: {
    audioFile: File | Blob,
    audioFileName: string,
    audioFileLength: number,
    recordingSessionId: number,
}) => {

    const formData = new FormData();

    formData.append('audio_file', args.audioFile, args.audioFileName);
    formData.append('payload', JSON.stringify({
        audio_file_name: args.audioFileName,
        audio_file_length: args.audioFileLength,
    }))

    const {response, error} = await uploadWholeAudioAPI.request({
        params: {
            session_id: args.recordingSessionId,
        },
        body: formData
    })

    if (error) {
        return
    }

    storeDispatch(upsertAudios([response]))

    return response
}

export const pollingNoteContentsAction = async (recordingSessionId: number) => {
    const lastUpdated = selectAppNoteLastUpdated(storeState())
    const {response, error} = await pollScriptBlockAPI.request({
        body: {
            recording_session_id: recordingSessionId,
            // last_response_time: lastUpdated ?? dayjs().add(-1, 'day').toISOString()
            last_response_time: dayjs().add(-1, 'day').toISOString()
        }
    })

    if (error || !response) {
        return []
    }

    if (response.scriptBlocks.length === 0) {
        return []
    }

    let newRecordingSession = {...selectRecordingSessionsById(storeState(), recordingSessionId)}

    response.scriptBlocks.forEach((scriptBlock: PollingScriptBlockFromDB) => {
        let targetScriptBlock = newRecordingSession.scriptBlocks.find((block: ScriptBlock) => block.id === scriptBlock.id)

        if (!targetScriptBlock) {

            const processingText = scriptBlock.chunks.filter((chunk: ChunkFromDB) => chunk.state === 'processing').map((chunk: ChunkFromDB) => chunk.text).join(' ')

            newRecordingSession = {
                ...newRecordingSession,
                scriptBlocks: [
                    ...newRecordingSession.scriptBlocks,
                    {
                        ...scriptBlock,
                        chunks: scriptBlock.chunks,
                        text: scriptBlock.chunks.filter((chunk: ChunkFromDB) => chunk.state === 'completed').map((chunk: ChunkFromDB) => chunk.text).join(' ') + processingText ? `|||${processingText}` : '',
                    }
                ]
            }

            return
        }

        if (!targetScriptBlock.chunks) {
            return
        }

        let newChunks = [...targetScriptBlock.chunks]
        const oldChunkIds = newChunks.map((chunk: ChunkFromDB) => chunk.id)



        // let [completedText, processingText] = [...targetScriptBlock.text?.split('|||')]

        scriptBlock.chunks.forEach((chunk: ChunkFromDB) => {
            if (oldChunkIds.includes(chunk.id)) {
                newChunks = newChunks.map((oldChunk: ChunkFromDB) => {
                    if (oldChunk.id === chunk.id) {
                        return chunk
                    }
                    return oldChunk
                })
            } else {
                newChunks.push(chunk)
            }
        })

        const completedChunks = newChunks.filter((chunk: ChunkFromDB) => chunk.state === 'completed')
        const processingChunks = newChunks.filter((chunk: ChunkFromDB) => chunk.state === 'processing')

        const completedText = completedChunks.map((chunk: ChunkFromDB) => chunk.text).join(' ')
        const processingText = processingChunks.map((chunk: ChunkFromDB) => chunk.text).join(' ')

        targetScriptBlock = {
            ...targetScriptBlock,
            text: `${completedText}|||${processingText}`,
            chunks: newChunks,
            state: scriptBlock.state
        }

        newRecordingSession.scriptBlocks = newRecordingSession.scriptBlocks.map((block: ScriptBlock) => {
            if (!targetScriptBlock) {
                return block
            }
            if (block.id === targetScriptBlock.id) {
                return targetScriptBlock
            }
            return block
        })
    })


    storeDispatch(upsertRecordingSession(newRecordingSession))
    storeDispatch(setLastUpdated(response.serverCurrentTime))
}

export const deleteNoteAction = async (args: {
    noteId: number,
}) => {

    const {error} = await deleteNoteAPI.request({
        params: {noteId: args.noteId}
    })
    if (error) {
        return
    }
    storeDispatch(removeAppNote(args.noteId))
    return true
}


export const checkNotionPermissionAction = async (notionPageUrl: string): Promise<boolean> => {
    const { response, error } = await checkNotionPermissionAPI.request({
        body: {
            notion_page_url: notionPageUrl,
        },
    });

    if (error || response === undefined) {
        console.error("Failed to check Notion permission:", error);
        return false;
    }
    return response; // 서버에서 True 또는 False 반환
};


export const getNotionAccessTokenAction = async (code: string) => {
  const { response, error } = await getNotionAccessTokenAPI.request({
    body: {
      code,
    },
  });

  if (error || !response) {
    console.error('노션 액세스 토큰 획득 실패:', error);
    return false;
  }

  return true;
};

export const exportNoteToNotionAction = async (noteId: number, notionPageUrl: string): Promise<boolean> => {
    const { error } = await exportNoteToNotionAPI.request({
      params: {
        noteId: noteId,
      },
      body: {
          notion_page_url: notionPageUrl,
      }
    });

    if (error) {
      console.error('노트를 노션으로 내보내는 데 실패했습니다:', error);
      return false;
    }

    return true;
  };

  export const getScriptsByPageIndexAction = async (noteId: number, pageIndex: number) => {
    console.log(`Requesting URL: /app-notes/real-time-notes/script-blocks/${noteId}?reference_file_index=${pageIndex}`);
    const { response, error } = await getScriptsByPageIndexAPI.request({
        params: {
            noteId,
        },
        query: {
            reference_file_index: pageIndex,
        },
    });

    if (error) {
        console.error('Failed to get script blocks:', error);
        return;
    }

    return response;
};

export const getNoteAction = async (noteId: number) => {
    const {response, error} = await getNoteAPI.request({params: {noteId}})
    if (error) {
        return
    }
    return response
}



export const uploadImagesToS3Action = async (args: {
    noteId: number,
    images: File[],

}) => {

    const formData = new FormData();
    args.images.forEach((image) => {
        formData.append('images', image);  // 같은 'images' 키로 여러 파일을 추가
      });
    const {response, error} = await uploadImagesToS3API.request({
        params: {noteId: args.noteId},
        body: formData
    })
    if (error) {
        return
    }
    return response
}

export const getNoteMemosAction = async (args: {noteId: number}): Promise<NoteMemoFromDB[]> => {
    const {response, error} = await getNoteMemoAPI.request({
        params: {noteMemoId: args.noteId}
    })

    if (error || !response) {
        return []
    }

    return response
}

export const createNoteMemoAction = async (args: {
    noteId: number,
    text: string,
    timestamp?: number
}): Promise<NoteMemoFromDB | undefined> => {
    const {response, error} = await createNoteMemoAPI.request({
        body: {
            note_id: args.noteId,
            text: args.text,
            timestamp: args.timestamp
        }
    })

    if (error || !response) {
        return
    }

    return response
}

export const updateNoteMemoAction = async (args: {
    noteMemoId: number,
    text: string,
    timestamp?: number
}): Promise<NoteMemoFromDB | undefined> => {
    const {response, error} = await updateNoteMemoAPI.request({
        params: {
            noteMemoId: args.noteMemoId
        },
        body: {
            text: args.text,
            timestamp: args.timestamp
        }
    })

    if (error || !response) {
        return
    }

    return response
}

export const deleteNoteMemoAction = async (args: {
    noteMemoId: number,
}): Promise<NoteMemoFromDB | undefined> => {
    const {response, error} = await deleteNoteMemoAPI.request({
        params: {
            noteMemoId: args.noteMemoId
        }
    })

    if (error || !response) {
        return
    }

    return response
}

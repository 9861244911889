import React from 'react';
import Button, {ButtonProps} from "../../component-library/button/Button";
import cn from "classnames";
import './CommonButton.scss';

function CommonButton(props: ButtonProps) {
    return (
        <Button {...props} className={cn("common-button", props?.className)} />
    );
}

export default CommonButton;

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './ScriptView.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {
    activateTranslation,
    deactivateTranslation,
    selectAllRecordingSessions,
    selectAppNoteTranslation,
    RecordingSession,
    ScriptBlock
} from "../../../redux/appNote";
import {rangeInt} from "../../../util/ArrayUtil";
import DroppablePageScript from "./DroppablePageScript";
import {useDebouncedCallback} from "use-debounce";
import {TbFocus2} from "react-icons/tb";
import {useTranslation} from "react-i18next";
import Toggle from "react-toggle";
import {GrLanguage} from "react-icons/gr";
import Select from "../../component-library/input/Select";
import useTooltip from "../../../hook/useTooltip";
import {Language, selectAllLanguages} from "../../../redux/languages";
import {RootState} from "../../../redux";
import {translateScriptBlockAction} from "../../../action/appNoteScriptBlockAction";
import {Responsive} from "../../style/Responsive";

type ScriptViewProps = {
    isRecording: boolean
    focusedPageIndex: number
    setFocusedPageIndex: (args: any) => void
    setIsKeyboardBlock: (isKeyboardBlock: boolean) => void
    scriptWidth: number
}

function ScriptView({
                        isRecording,
                        focusedPageIndex,
                        setFocusedPageIndex,
                        setIsKeyboardBlock,
                        scriptWidth
                    }: ScriptViewProps) {

    const {t} = useTranslation("translation", {keyPrefix: "ScriptView"})

    const dispatch = useDispatch();

    const scrollViewRef = React.useRef<HTMLDivElement>(null)
    const lastFocusedPageIndex = React.useRef<number>(0)

    const noteTranslation = useSelector(selectAppNoteTranslation)
    const languages = useSelector(selectAllLanguages)
    const appUser = useSelector((state: RootState) => state.appUser)
    const recordingSessionDataList = useSelector(selectAllRecordingSessions)

    const [scriptData, setScriptData] = React.useState<any[]>([])
    const [targetLanguage, setTargetLanguage] = useState<Language | undefined>(appUser.serviceLanguage);
    const [isResetLanguage, setIsResetLanguage] = useState<boolean>(false);
    const [pageHeightList, setPageHeightList] = React.useState<{
        pageIndex: number,
        height: number,
    }[]>([])

    const focusedScrollTop = React.useRef<number>(0)
    const [isFocused, setIsFocused] = React.useState<boolean>(false)

    const note = useSelector((state: any) => state.appNote)
    const noteRecordingSessionDataList = useSelector(selectAllRecordingSessions)

    const referenceFileTotalPages = note.referenceFileTotalPages;

    const pageNum = useMemo(() => {
        if (!note.referenceFileTotalPages) {
            return [-1, 0]
        }
        return [note.referenceFileTotalPages]
    }, [note.referenceFileTotalPages])

    const noteScriptBlocks = useMemo(() => {
        if (!noteRecordingSessionDataList) {
            return []
        }

        let audios: (RecordingSession & {prevTime?: number})[] = _.sortBy(noteRecordingSessionDataList, 'id')

        const updatedAudios = [];
        for (let index = 0; index < audios.length; index++) {
            const audio = audios[index];
            const prevTime: number = index === 0 ? 0 : (updatedAudios[index - 1].prevTime + updatedAudios[index - 1].wholeAudioLength);
            updatedAudios.push({
                ...audio,
                prevTime: prevTime
            });
        }
        audios = updatedAudios;

        const blocks = _.sortBy(
            noteRecordingSessionDataList.reduce((result: any[], session: any) => {
                return result.concat(session.scriptBlocks.map((block: ScriptBlock) => {
                    let prevTime = (audios.find((audio: RecordingSession) => audio.id === block.recordingSessionId)?.prevTime ?? 0)
                    if (block.text) {
                        try {
                            const startTimestamp = parseInt(block.text.split('{:')[1]?.split(':}')[0]) * 1000
                            prevTime = prevTime + startTimestamp
                        } catch (e) {

                        }
                    }
                    return {...block, prevTime}
                }))
            }, []),
            ['referenceFilePageIndex', 'id', 'order']
        )

        // @ts-ignore
        return rangeInt(...pageNum).map((pageIndex: number) => ({
            pageIndex,
            scriptBlocks: [
                ...blocks.filter((block: any) => block.referenceFilePageIndex === pageIndex)
            ]
        }))

    }, [noteRecordingSessionDataList, pageNum])

    const onScroll = useDebouncedCallback((e: any) => {
        if (Math.abs(focusedScrollTop.current - e.target?.scrollTop) < 10) {
            setIsFocused(true)
            return
        }
        setIsFocused(false)
    }, 100)

    const onScrollToFocusedPage = useCallback(() => {
        scrollViewRef.current?.scrollTo({
            top: focusedScrollTop.current,
            behavior: 'smooth'
        })
    }, [])

    const onChangeTranslation = useCallback(() => {
        if (noteTranslation) {
            // @ts-ignore
            dispatch(deactivateTranslation())
        } else {
            // @ts-ignore
            dispatch(activateTranslation())
        }
    }, [dispatch, noteTranslation])

    // '번역언어 선택' 툴팁
    const translationLanguageTooltip = useTooltip({
        modalComponent: ({ref}) => (
            <div ref={ref} className="note-header-translation-language-tooltip">
                {t('translation-language-tooltip')} {/* 번역할 언어를 선택해주세요. */}
            </div>
        ),
    });

    const changeLanguage = useCallback(async (e: any) => {
        setTargetLanguage(languages.find(language => language.id === parseInt(e.target.value)))
        setIsResetLanguage(true)
    }, [languages])

    useEffect(() => {
        if (noteScriptBlocks.length > 0) {
            setScriptData(noteScriptBlocks)
        }
    }, [noteScriptBlocks]);

    useEffect(() => {
        if (!scrollViewRef.current) {
            return
        }

        if (lastFocusedPageIndex.current === focusedPageIndex) {
            focusedScrollTop.current = pageHeightList.filter((page) => page.pageIndex <= focusedPageIndex)
                .reduce((acc, cur) => {
                    if (cur.height) {
                        return acc + cur.height + 10
                    }
                    return acc
                }, 0) - scrollViewRef.current?.offsetHeight + 40
        } else {
            focusedScrollTop.current = pageHeightList.filter((page) => page.pageIndex < focusedPageIndex)
                .reduce((acc, cur) => {
                    if (cur.height) {
                        return acc + cur.height + 10
                    }
                    return acc
                }, 0)
            lastFocusedPageIndex.current = focusedPageIndex
        }
        setTimeout(() => {
            onScrollToFocusedPage()
        }, 10)
    }, [focusedPageIndex, pageHeightList, onScrollToFocusedPage]);

    useEffect(() => {
        setPageHeightList(scriptData.map((page) => {
            const pageIndex = page.pageIndex
            const element = document.getElementById(`block-${pageIndex}`)
            if (!element) {
                return {
                    pageIndex,
                    height: 0
                }
            }
            return {
                pageIndex,
                height: element.offsetHeight
            }
        }))
    }, [scriptData, scriptWidth, noteTranslation])

    useEffect(() => {
        if (noteTranslation) {
            const notTranslatedBlockIds: number[] = recordingSessionDataList.reduce((acc: number[], recordingSession: RecordingSession) => {
                if (recordingSession.scriptBlocks) {
                    return [
                        ...acc,
                        ...recordingSession.scriptBlocks
                            .filter((scriptBlock: ScriptBlock) => (!scriptBlock.translation || isResetLanguage) && scriptBlock.state !== 'processing')
                            .map((scriptBlock: ScriptBlock) => scriptBlock.id)
                    ]
                }
                return acc
            }, [])
            translateScriptBlockAction({scriptBlockIds: notTranslatedBlockIds, languageId: targetLanguage?.id})
            setIsResetLanguage(false)
        }
    }, [noteTranslation, recordingSessionDataList, targetLanguage?.id, isResetLanguage])

    return (
        <div ref={scrollViewRef} className={'script-view'} onScroll={onScroll}>
            <div className="script-view-header">
                <h5>{t('script')}</h5> {/* 녹음 스크립트 */}
                <label className={"note-header-translation"} onClick={(e) => e.preventDefault()}>
                    <span className={"note-header-translation-label"}>
                        {t('translation')}
                    </span>
                    <Toggle
                        checked={noteTranslation}
                        icons={false}
                        onChange={onChangeTranslation}/>
                    <div
                        className={`note-header-translation-language ${noteTranslation ? 'disabled' : ''}`} // 번역 토글 켜졌을 때 disabled 클래스 추가
                        data-translation-active={noteTranslation}
                        onMouseEnter={() => {
                            if (!noteTranslation) { // 번역 토글이 꺼져 있을 때만 툴팁이 뜨도록 조건 추가
                                translationLanguageTooltip.onActive();
                            }
                        }}
                        onMouseLeave={() => {
                            if (noteTranslation) {
                                translationLanguageTooltip.onInactive();
                            }
                        }}
                    >
                        {translationLanguageTooltip.modalComponent}
                        <div className={"note-header-translation-language-icon"}>
                            <GrLanguage/>
                        </div>
                        <Select className={"note-header-translation-language-select"}
                                options={languages}
                                value={targetLanguage?.id?.toString() ?? ''}
                                disabled={noteTranslation}
                                labelKey={"name"}
                                valueKey={"id"}
                                onChange={changeLanguage}/>
                    </div>
                </label>
            </div>

            <div className="script-view-body">
                {scriptData.every(({scriptBlocks}) => scriptBlocks.length === 0) && !isRecording && (
                    <div className="script-view-empty-message">
                        <h3>{t('script')}</h3> {/* 녹음 스크립트 */}
                        <p>{t('script-info')}</p> {/* 30초 이상 녹음을 하면 스크립트가 생성됩니다. */}
                    </div>
                )}

                {scriptData.map(({pageIndex, scriptBlocks}: any) => {

                    const isEmptyTexts = scriptBlocks.reduce((acc: any, cur: any) => {
                        return acc && cur.text === ""
                    }, true)
                    const isEmptyPage = scriptBlocks.length === 0
                    const isEmpty = isEmptyTexts || isEmptyPage

                    if (isEmpty && !isRecording) {
                        return null
                    }

                    if (isEmpty && isRecording && focusedPageIndex !== pageIndex) {
                        return null
                    }

                    return <DroppablePageScript pageIndex={pageIndex}
                                                focusedPageIndex={focusedPageIndex}
                                                setFocusedPageIndex={setFocusedPageIndex}
                                                setIsKeyboardBlock={setIsKeyboardBlock}
                                                scriptBlocks={scriptBlocks}
                                                isRecording={isRecording}
                                                referenceFileTotalPages={referenceFileTotalPages}
                                                key={`block-${pageIndex}`}/>
                })}
            </div>
            <Responsive mobile={null}
                        desktop={<>
                            {!isFocused && isRecording &&
                                <button className={"script-view-focus-button"}
                                        style={{
                                            bottom: isRecording ? 20 : 80
                                        }}
                                        onClick={onScrollToFocusedPage}>
                                    <TbFocus2 size={24}/>
                                </button>}
                        </>}
            />

        </div>
    );
}

export default ScriptView;

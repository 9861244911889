import {useCallback, useEffect, useRef, useState} from 'react';
import {IMediaRecorder, MediaRecorder, register} from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
// @ts-ignore
import MicRecorder from 'mic-recorder-to-mp3';
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";

type UseAudioProps = {
    onInterval: (audioFile: Blob, recordingTime: number, wholeRecordingTime: number) => void;
    onStop: (audioFile: Blob, recordingTime: number) => void;
    interval: number;
}

try {
    connect().then(res => register(res))
} catch (e) {
    console.error(e);
}

function useRecorderForVad({onInterval = () => {}, onStop = () => {}, interval = 30000}: UseAudioProps) {

    const {t} = useTranslation('translation', {keyPrefix: "useRecorderForVad"});

    const lastIntervalMediaRecorderRef = useRef<IMediaRecorder>();
    // const fullMediaRecorderRef = useRef<IMediaRecorder>();
    const fullMediaRecorderRef = useRef<MicRecorder>();
    const timeIntervalRef = useRef<any>(null);
    const startTimeRef = useRef<dayjs.Dayjs>(dayjs());
    const lastStartTimeRef = useRef<dayjs.Dayjs>(dayjs());
    const vadRef = useRef<any>();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recordingTime, setRecordingTime] = useState<number>(0);

    const handleDataAvailable = useCallback((event: any) => {
        if (event.data.size > 0) {
            const stopTime = dayjs();
            console.log(
                "TOTAL RECORDING TIME ::",
                stopTime.diff(startTimeRef.current, 'milliseconds').toString(), "ms",
                "\nBLOCK RECORDING TIME ::",
                stopTime.diff(lastStartTimeRef.current, 'milliseconds').toString(), "ms"
            )
            onInterval(event.data, stopTime.diff(lastStartTimeRef.current, 'milliseconds'), stopTime.diff(startTimeRef.current, 'milliseconds'));
        }
    }, [onInterval]);

    const handleDataAvailableOnStop = useCallback((event: any) => {
        if (event.data.size > 0) {
            onStop(event.data, dayjs().diff(startTimeRef.current, 'milliseconds'));
        }
    }, [onStop]);

    const stopAndStartRecord = useCallback(async () => {
        try {
            if (vadRef.current?.stream === undefined) {
                return;
            }

            if (lastIntervalMediaRecorderRef.current === undefined) {
                return;
            }

            lastIntervalMediaRecorderRef.current.stop();
            setTimeout(() => {
                lastStartTimeRef.current = dayjs(dayjs().add(-1, 'second'));
            }, 1000)

            const mediaRecorder = new MediaRecorder(vadRef.current.stream, { mimeType: 'audio/wav' });
            lastIntervalMediaRecorderRef.current = mediaRecorder;
            mediaRecorder.ondataavailable = handleDataAvailable;
            mediaRecorder.start();
        } catch (e) {

        }
    }, [handleDataAvailable])

    const start = useCallback(async () => {
        try {
            if (isRecording) {
                return;
            }

            if (vadRef.current?.stream === undefined) {
                return;
            }

            if (fullMediaRecorderRef.current) {
                fullMediaRecorderRef.current?.stop();
            }

            fullMediaRecorderRef.current = new MicRecorder({ bitRate: 128 });
            await fullMediaRecorderRef.current.start();
            // fullMediaRecorderRef.current = new MediaRecorder(vadRef.current.stream, { mimeType: 'audio/wav' });
            // fullMediaRecorderRef.current.ondataavailable = handleDataAvailableOnStop;
            // fullMediaRecorderRef.current.start();

            lastIntervalMediaRecorderRef.current = new MediaRecorder(vadRef.current.stream, { mimeType: 'audio/wav' });
            lastIntervalMediaRecorderRef.current.ondataavailable = handleDataAvailable;
            lastIntervalMediaRecorderRef.current.start();

            console.log("RECORDING START ::", dayjs().format("HH:mm:ss.SSS"))
            startTimeRef.current = dayjs();
            lastStartTimeRef.current = dayjs();
            setIsRecording(true);
        } catch (e) {
            console.error(e)
            alert(t('mic-alert')) //"마이크 권한이 차단되어있습니다.\n브라우저 설정에서 마이크 권한을 허용해주세요."
        }
    }, [handleDataAvailable, handleDataAvailableOnStop, isRecording, t]);

    const stop = useCallback(async () => {

        if (!isRecording) {
            return;
        }

        console.log("RECORDING STOP ::", dayjs().format("HH:mm:ss.SSS"))
        lastIntervalMediaRecorderRef.current?.stop();
        // fullMediaRecorderRef.current?.stop();

        const [bufferFull, blobFull] = await fullMediaRecorderRef.current?.stop().getMp3()
        const fileFull = new File(bufferFull, `full-record-${Date.now()}.mp3`, {
            type: blobFull.type,
            lastModified: Date.now()
        });
        onStop(fileFull, dayjs().diff(startTimeRef.current, 'milliseconds'))

        // console.log(vadRef.current)
        await vadRef.current?.pause();
        // await vadRef.current?.stream?.getTracks().forEach((track: any) => track.stop());
        setIsRecording(false);
    }, [isRecording, onStop]);

    useEffect(() => {
        if (isRecording) {
            timeIntervalRef.current = setInterval(() => {
                setRecordingTime(dayjs().diff(startTimeRef.current, 'seconds'));
            }, 1000);
        } else {
            clearInterval(timeIntervalRef.current);
            timeIntervalRef.current = null;
            setRecordingTime(0);
        }
    }, [interval, isRecording]);



    useEffect(() => {
        if (window?.vad?.MicVAD) {
            window.vad.MicVAD.new({
                positiveSpeechThreshold: 0.8,
                negativeSpeechThreshold: 0.8,
                frameSamples: 1024,
                preSpeechPadFrames: 5,
                minSpeechFrames: 10,
                startOnLoad: true,
                onSpeechStart: () => {},
                onVADMisfire: () => {},
                onSpeechEnd: async (audio: any) => {
                    await stopAndStartRecord();
                },
            }).then((vad: any) => {
                vadRef.current?.pause();
                vadRef.current?.stream?.getTracks().forEach((track: any) => track.stop());

                vadRef.current = vad;
                vad.start()

                setIsLoaded(true);
            })
        }

        return () => {
            vadRef.current?.pause();
            vadRef.current?.stream?.getTracks().forEach((track: any) => track.stop());
        }
    }, [stopAndStartRecord])

    return {
        isLoaded,
        isRecording,
        recordingTime,
        start,
        stop
    };
}

export default useRecorderForVad;

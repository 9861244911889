import React from 'react'
import './SectionSecond.scss'
import {section2_1, section2_2} from "../../asset/image";
import {useTranslation} from "react-i18next";
import { ScrollAnimationContainer } from '../ScrollAnimationContainer';


function SectionSecond() {

    const {t} = useTranslation("translation", {keyPrefix: "SectionSecond"})

    return (
        <div id={"SectionSecond"}>
            <ScrollAnimationContainer>
                <div className={"section-second-content-1"}>
                    <img className={"section-second-img"} src={section2_1} alt={'section2_1'}/>
                    <div className={"section-second-texts"}>
                        <h3 className={"section-second-title"}>
                            {t('title1')}
                        </h3>
                        <p className={"section-second-subtitle"}>
                            {t('subtitle1')}
                        </p>
                    </div>
                </div>
            </ScrollAnimationContainer>
            <ScrollAnimationContainer>
                <div className={"section-second-content-2"}>
                    <img className={"section-second-img"} src={section2_2} alt={'section2_2'}/>
                    <div className={"section-second-texts"}>
                        <h3 className={"section-second-title"}>
                            {t('title2')}
                        </h3>
                        <p className={"section-second-subtitle"}>
                            {t('subtitle2')}
                        </p>
                    </div>
                </div>
            </ScrollAnimationContainer>
        </div>
    );
}

export default SectionSecond;

import React from 'react'
import './SectionThird.scss'
import {useTranslation} from "react-i18next";
import {section3_1, section3_2, section3_3, section3_4, section3_5, section3_6} from "../../asset/image";
import {ScrollAnimationContainer} from "../ScrollAnimationContainer";

function SectionThird() {

    const {t} = useTranslation("translation", {keyPrefix: "SectionThird"})

    return (
        <div id={"SectionThird"}>
            <ScrollAnimationContainer className={"section-third-content"}>
                <div className={"section-third-header"}>
                    <h3 className={"section-third-title"}>{t('title1')}</h3>
                    <p className={"section-third-subtitle"}>{t('subtitle1')}</p>
                </div>
                <div className={"section-third-img-wrapper"}>
                    <img className={"section-third-img1"} src={section3_1} alt={'section3_1'}/>
                </div>
            </ScrollAnimationContainer>
            <ScrollAnimationContainer className={"section-third-content"}>
                <div className={"section-third-header"}>
                    <h3 className={"section-third-title"}>{t('title2')}</h3>
                    <p className={"section-third-subtitle"}>{t('subtitle2')}</p>
                </div>
                <div className={"section-third-img-wrapper"}>
                    <img className={"section-third-img2"} src={section3_2} alt={'section3_1'}/>
                </div>
            </ScrollAnimationContainer>
            <ScrollAnimationContainer className={"section-third-content"}>
                <div className={"section-third-header"}>
                    <h3 className={"section-third-title"}>{t('title3')}</h3>
                    <p className={"section-third-subtitle"}>{t('subtitle3')}</p>
                </div>
                <div className={"section-third-img-wrapper"}>
                    <img className={"section-third-img3"} src={section3_3} alt={'section3_1'}/>
                </div>
            </ScrollAnimationContainer>
            <ScrollAnimationContainer className={"section-third-content"}>
                <div className={"section-third-header"}>
                    <h3 className={"section-third-title"}>{t('title4')}</h3>
                    <p className={"section-third-subtitle"}>{t('subtitle4')}</p>
                </div>
                <div className={"section-third-img-wrapper"}>
                    <img className={"section-third-img4"} src={section3_4} alt={'section3_2'}/>
                </div>
            </ScrollAnimationContainer>
            <ScrollAnimationContainer className={"section-third-content"}>
                <div className={"section-third-header"}>
                    <h3 className={"section-third-title"}>{t('title5')}</h3>
                    <p className={"section-third-subtitle"}>{t('subtitle5')}</p>
                </div>
                <div className={"section-third-img-wrapper"}>
                    <img className={"section-third-img5"} src={section3_5} alt={'section3_3'}/>
                </div>
            </ScrollAnimationContainer>
            <ScrollAnimationContainer className={"section-third-content"}>
                <div className={"section-third-header"}>
                    <h3 className={"section-third-title"}>{t('title6')}</h3>
                    <p className={"section-third-subtitle"}>{t('subtitle6')}</p>
                </div>
                <div className={"section-third-img-wrapper"}>
                    <img className={"section-third-img6"} src={section3_6} alt={'section3_4'}/>
                </div>
            </ScrollAnimationContainer>

        </div>
    );
}

export default SectionThird;

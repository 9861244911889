import React, {useCallback, useEffect, useMemo} from 'react';
import {Outlet, useParams} from "react-router-dom";
import useInit from "../../../hook/useInit";
import {clearNoteAction, selectOneNoteAction} from "../../../action/appNoteAction";

function NoteLayout() {

    // const location = useLocation()
    const params = useParams();

    const noteId = useMemo(() => parseInt(params?.noteId as string), [params]);
    // const note = useSelector(selectAppNote);
    // const recordingSessionDataList = useSelector(selectAllRecordingSessions)

    // const [noteState, setNoteState] = React.useState<TYPE_NOTE_STATE>(location?.state?.noteState ?? NOTE_STATE.DEFAULT)
    //
    // useEffect(() => {
    //     if (noteState === NOTE_STATE.DEFAULT && note.id && recordingSessionDataList?.length === 0) {
    //         setNoteState(NOTE_STATE.NEW)
    //         return
    //     }
    //     if (noteState === NOTE_STATE.DEFAULT && note.id && recordingSessionDataList?.length !== 0) {
    //         setNoteState(NOTE_STATE.EDIT)
    //         return
    //     }
    // }, [note.id, noteState, recordingSessionDataList])

    useInit(useCallback(async () => {
        if (noteId) {
            await selectOneNoteAction(noteId)
        }
    }, [noteId]))

    // useEffect(() => {
    //     // if (!note.previewText) {
    //     const blocks = recordingSessionDataList.reduce((blocks, session) => blocks.concat(session.scriptBlocks), [])
    //     if (blocks.length > 0) {
    //         const previewText = blocks.map((block: ScriptBlock) => block.text.split("{:")
    //             .reduce((acc, cur) => {
    //                 if (cur.includes(":}")) {
    //                     return acc + cur.split(":}")[1]
    //                 }
    //                 return acc
    //             }, "")
    //         ).join("")
    //
    //         if (previewText && previewText.slice(0, 200) !== note.previewText) {
    //             updateNoteAction({noteId: note.id, previewText: previewText.slice(0, 200)})
    //         }
    //
    //     }
    //     // }
    // }, [note.id, note.previewText, recordingSessionDataList]);

    useEffect(() => {
        return () => {
            clearNoteAction()
        }
    }, []);

    return (
        <Outlet />
    );
}

export default NoteLayout;

import {useTranslation} from "react-i18next";
import {useResponsive} from "../../style/Responsive";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import useTooltip from "../../../hook/useTooltip";
import ReferenceInfoTooltips from "./ReferenceInfoTooltips";
import React from "react";
import infoCircle from "../../asset/image/info-circle.png";
import cn from "classnames";
import {Document, Page} from "react-pdf";
import {formatFileSize} from "../../../util/fileUtils";
import {AiOutlineClose} from "react-icons/ai";
import InputFile from "../../component-library/input/InputFile";
import {referenceFileUploadEvent} from "../../../analytics/ga";
import uploadVector from "../../asset/image/vector-upload.svg";

type NoteInitResponsiveLayoutType = {
    referenceFile: any
    referenceFileContent: any
    setReferenceFileContent: (content: any) => void
    onLoadReferenceFile: (e: any) => void
    fileSize: number
    pageNums: number
    deleteReferenceFile: (e: any) => void
    children: React.ReactNode
}

const MAX_FILE_SIZE = 50 * 1000 * 1000

export const NoteInitResponsiveLayout = ({
                                     referenceFile,
                                     referenceFileContent,
                                     setReferenceFileContent,
                                     onLoadReferenceFile,
                                     fileSize,
                                     pageNums,
                                     deleteReferenceFile,
                                     children
                                 }: NoteInitResponsiveLayoutType) => {

    const {t} = useTranslation("translation", {keyPrefix: "NoteInit"})

    const {isMobile} = useResponsive()

    const note = useSelector((state: RootState) => state.appNote)

    const {
        onActive: onActiveTooltip,
        onInactive: onInactiveTooltip,
        modalComponent: ReferenceInfoTooltipsComponent
    } = useTooltip({modalComponent: ReferenceInfoTooltips})

    // 항상 툴팁이 보이게 설정
    React.useEffect(() => {
        onActiveTooltip(); // 툴팁을 활성화 상태로 설정
    }, [onActiveTooltip]);

    if (isMobile) {
        return <div className={"note-new-block"}>

            <div className={"note-new-block-header"}>
                <span className={"note-new-block-header-number"}>1</span>
                <span className={"note-new-block-header-title"}>{t("recording-start")}</span>
            </div>

            {children}
        </div>
    }

    return <>
        <div className={"note-new-block"}>
            <div className={"note-new-block-header"}>
                <span className={"note-new-block-header-number"}>1</span>
                <span className={"note-new-block-header-title"}>{t("input-reference-file")}
                    <span
                        className="tooltip-wrapper"
                        onMouseEnter={onActiveTooltip}
                        onMouseLeave={onInactiveTooltip}
                    >
                                                <img
                                                    src={infoCircle}
                                                    alt="info"
                                                    className="tooltip-icon"
                                                />
                        {ReferenceInfoTooltipsComponent}
                                            </span>
                                        </span>
            </div>


            {referenceFile ?
                <div
                    className={cn("note-new-reference-file-upload", {preview: Boolean(referenceFileContent)})}>
                    <div className={"note-new-reference-file-upload-preview"}>
                        <Document file={referenceFile.file} onLoadSuccess={onLoadReferenceFile}>
                            <Page pageNumber={1} height={225}/>
                        </Document>
                    </div>
                    <div className={"note-new-reference-file-upload-info"}>
                        <div>
                            <div
                                className={"note-new-reference-file-upload-info-name"}>{referenceFile.name}</div>
                            <div
                                className={cn("note-new-reference-file-upload-info-size", {"size-over": fileSize > MAX_FILE_SIZE})}>
                                {fileSize > 0 ? formatFileSize(fileSize) : `${note.referenceFileTotalPages ?? pageNums} page`}
                            </div>
                        </div>
                        <button className={"note-new-reference-file-upload-info-delete"}
                                onClick={deleteReferenceFile}>
                            <AiOutlineClose/>
                        </button>
                    </div>
                </div>
                :
                <InputFile value={referenceFileContent}
                           onChange={event => {
                               referenceFileUploadEvent();  // 파일 선택 시 GA 이벤트 호출
                               setReferenceFileContent(event?.target?.value);
                           }}
                           id={"newReferenceFile"}
                           fileProps={{
                               types: ['PDF', 'PPT'],
                               maxSize: 1024,
                               minSize: 0,
                               setPreview: (preview: any) => {
                               }
                           }}>
                    <div className={"note-new-reference-file-upload"}>
                        <img className={"note-new-reference-file-upload-icon"}
                             src={uploadVector}
                             alt={'upload'}/>
                        <div className={"note-new-reference-file-upload-text"}>
                            {t("upload-reference-file")}
                        </div>
                        <div className={"note-new-reference-file-upload-subtext"}>
                            {t("upload-reference-file-limit")}
                        </div>
                    </div>
                </InputFile>
            }
        </div>


        <div className={"note-new-block"}>

            <div className={"note-new-block-header"}>
                <span className={"note-new-block-header-number"}>2</span>
                <span className={"note-new-block-header-title"}>{t("recording-start")}</span>
            </div>

            {children}
        </div>
    </>
}

import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import './LeftNav.scss';
import cn from "classnames";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "../../component-library/button/Button";
import logo from "../../asset/image/logo.png";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import SettingModal from "../setting/SettingModal";
import SignInModal from "../modal/SignInModal";
import {Folder, selectFolder, Subject} from "../../../redux/folderAndSubject";
import NavButton from "./NavButton";
import RootDroppable from "./RootDroppable";
import {loginButtonClickedEvent} from "../../../analytics/ga";
import {Mobile} from "../../style/Responsive";
import arrowDown from "../../asset/image/arrow-down.png";

// 튜토리얼
import { setTutorialStep, skipAllTutorials } from "../../../redux/tutorialSlice";
import TutorialOverlay from "../../component/tutorial/TutorialOverlay";

type LeftNavProps = {
    isOpen: boolean;
    setIsOpen: (value: React.SetStateAction<boolean>) => void;
}

export const DUMMY_SUBJECT = {
        id: -999,
        name: "사용 가이드",
        color: "#F7D8DE"
    }

function LeftNav({isOpen, setIsOpen}: LeftNavProps) {

    const { t: commonTranslation } = useTranslation('translation');
    const { t } = useTranslation('translation', { keyPrefix: "LeftNav" });

    // 튜토리얼
    const dispatch = useDispatch();

    // 튜토리얼 상태 가져오기
    const { currentStep, subjectDrag } = useSelector((state: RootState) => state.tutorial);
    const firstSubjectRef = useRef(null); // 첫 번째 과목 참조하는 Ref (subjectDrag 튜토리얼용)

    // subjectDrag 튜토리얼 완료 처리 함수
    const handleSubjectDragCompleteTutorial = useCallback(() => {
        dispatch(setTutorialStep('subjectDrag'));
    }, [dispatch]);

    // 모든 튜토리얼 건너뛰기 처리 함수
    const handleSkipTutorial = useCallback(() => {
        dispatch(skipAllTutorials());
    }, [dispatch]);

    const navigate = useNavigate()

    const appUser = useSelector((state: RootState) => state.appUser);
    const folder = useSelector((state: RootState) => selectFolder(state, appUser.rootFolderId as number));
    const folderData = useMemo(() => {
        if (!appUser.id) {
            return {
                id: -1,
                name: "노트북",
                children: [DUMMY_SUBJECT]
            } as Folder
        }
        return folder
    }, [appUser, folder])

    const [showFolderIds, setShowFolderIds] = useState<number[]>([])

    const [isOpenSettingModal, setIsOpenSettingModal] = useState<boolean>(false)
    const onClickSetting = useCallback(() => {
        setIsOpenSettingModal(true)
    }, [])

    const [isOpenSignInModal, setIsOpenSignInModal] = useState<boolean>(false)

    useEffect(() => {
        if (appUser.id) {
            setIsOpenSignInModal(false)
        }
        if (!appUser.id) {
            setIsOpenSettingModal(false)
        }
    }, [appUser])
    
    return <nav id={"LeftNav"}>
        {isOpen && <>
            <SettingModal isOpen={isOpenSettingModal} onClose={() => setIsOpenSettingModal(false)}/>
            <SignInModal isOpen={isOpenSignInModal} onClose={() => setIsOpenSignInModal(false)}/>
            <div className={"top-container"}>

                <Link to={'/notes/list'} className={'main-logo-container'}>
                    <img className={cn("main-logo")} src={logo} alt={'logo'}/>
                </Link>

                <div className={"top-inner-container"}>

                    <Link className={cn("all-note")} to={'/notes/list'}>
                        <div className={"all-note-front"}>
                            <span className={"all-note-icon"}>🗃</span>
                            <span className={"all-note-label"}>️{t('all-notes')}</span>
                        </div>
                    </Link>

                    <div className={"nav-folder"}>
                        <RootDroppable setIsOpenSignInModal={setIsOpenSignInModal}/>
                        <div className={"nav-folder-list"}>
                            {/* {folderData.children.map((folderItem: Folder | Subject, index: number) =>{
                                return <NavButton item={folderItem}
                                                  key={`${folderItem.id}_${index}`}
                                                  showFolderIds={showFolderIds}
                                                  setShowFolderIds={setShowFolderIds} 
                                                  {...(index == 0 && {ref: firstSubjectRef})} />
                            })} */}
                            {folderData.children.map((folderItem: Folder | Subject, index: number) => {
                                const isFirstSubject = folderData.children.findIndex(item => 
                                    item && (!('type' in item) || item.type !== 'folder')
                                ) === index;
                                
                                if (!folderItem) return null; // undefined 항목 건너뛰기

                                return (
                                    <NavButton 
                                        item={folderItem}
                                        key={`${folderItem.id}_${index}`}
                                        showFolderIds={showFolderIds}
                                        setShowFolderIds={setShowFolderIds} 
                                        {...(isFirstSubject && {ref: firstSubjectRef})}
                                    />
                                );
                                })}
                        </div>
                        {/* subjectDrag 튜토리얼 */}
                        {currentStep === 2 && !subjectDrag && (
                            <TutorialOverlay
                                content={t('subject-drag-tutorial')}
                                onComplete={handleSubjectDragCompleteTutorial}
                                onSkip={handleSkipTutorial}
                                targetRef={firstSubjectRef}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className={"bottom-container"}>
                <a className={"to-community"} href={'https://slashpage.com/notifai-community'} target={'_blank'} rel={'noreferrer'}>
                    <span className={"to-community-icon"}>💬</span>
                    <span className={"to-community-label"}>{t('to-developer')}</span>
                </a>
                {appUser.id ? (
                    <button className={"user"} onClick={onClickSetting}>
                        <img src={appUser.profileImage} alt={'profile'} className={"user-profile-image"}/>
                        <div className={"user-info"}>
                            <div className={"user-info-username"}>{`${appUser.firstName} ${appUser.lastName}`}</div>
                            <div className={"user-info-email"}>{appUser.email}</div>
                        </div>
                    </button>
                ) : (
                    <Button className={"bottom-button"} onClick={() => {
                        setIsOpenSignInModal(true);
                        loginButtonClickedEvent();  // 로그인 버튼 클릭 GA 이벤트 트래킹
                    }}>
                        {commonTranslation('login')}
                    </Button>

                )}
            </div>
        </>}
        {/*<Mobile>*/}
        {/*    <button className={"minimize-button"} onClick={() => setIsOpen(prev => !prev)}>*/}
        {/*        <img src={arrowDown} alt={'arrow'} className={cn("minimize-button-icon", {open: isOpen})}/>*/}
        {/*    </button>*/}
        {/*</Mobile>*/}
    </nav>
}

export default LeftNav;

import React, {useRef, useCallback} from 'react';
import './DroppablePageScript.scss'
import cn from "classnames";
import DraggableParagraph from "./DraggableParagraph";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {useDrop} from "react-dnd";
import {updateScriptBlockAction} from "../../../action/appNoteScriptBlockAction";
import {useTranslation} from "react-i18next";

// 튜토리얼
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import TutorialOverlay from "../../component/tutorial/TutorialOverlay";
import {setTutorialStep, skipAllTutorials} from "../../../redux/noteTutorialSlice";
import {ScriptBlock} from "../../../redux/appNote";


type DroppablePageScriptProps = {
    pageIndex: number
    focusedPageIndex: number
    setFocusedPageIndex: (args: any) => void
    setIsKeyboardBlock: (isKeyboardBlock: boolean) => void
    scriptBlocks: any[]
    isRecording: boolean
    referenceFileTotalPages: number
}

function DroppablePageScript({
                                 pageIndex,
                                 focusedPageIndex,
                                 setFocusedPageIndex,
                                 setIsKeyboardBlock,
                                 scriptBlocks,
                                 isRecording,
                                 referenceFileTotalPages
                             }: DroppablePageScriptProps) {

    const {t} = useTranslation('translation', {keyPrefix: "DroppablePageScript"});

    // 튜토리얼
    const dispatch = useDispatch();

    // 튜토리얼 상태 가져오기
    const {currentStep, scriptInfo, scriptInfoRef} = useSelector((state: RootState) => state.noteTutorial);
    const firstSciptRef = useRef<HTMLDivElement | null>(null);

    // scriptInfo 튜토리얼 완료 처리 함수
    const handleScriptInfoCompleteTutorial = useCallback(() => {
        dispatch(setTutorialStep('scriptInfo'));
    }, [dispatch]);

    // scriptInfoRef 튜토리얼 완료 처리 함수
    const handleScriptInfoRefCompleteTutorial = useCallback(() => {
        dispatch(setTutorialStep('scriptInfoRef'));
    }, [dispatch]);

    // 모든 튜토리얼 건너뛰기 처리 함수
    const handleSkipTutorial = useCallback(() => {
        // @ts-ignore
        dispatch(skipAllTutorials());
    }, [dispatch]);

    const [{isOver, item}, drop] = useDrop({
        accept: ["scriptBlock"],
        drop: async (dropItem: { block: any }) => {
            if (dropItem.block.referenceFilePageIndex === pageIndex) {
                return
            }
            await updateScriptBlockAction({
                ...dropItem.block,
                referenceFilePageIndex: pageIndex
            })
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            item: monitor.getItem(),
        }),
    })

    return (<div id={`block-${pageIndex}`}
                 key={`block-${pageIndex}`}
                 ref={(node) => {
                     drop(node); // drop ref 설정
                     firstSciptRef.current = node; // firstScipttRef 설정
                 }}
                 data-textid={"pageScript"}
                 className={cn("page-script-page-block", {
                     focused: focusedPageIndex === pageIndex,
                     'is-over': isOver && item?.block.referenceFilePageIndex !== pageIndex
                 })}>

        {/* scriptInfo 튜토리얼 - 녹음 중일 때만 표시, referenceFile 없이 녹음 시작했을 때 */}
        {/* {isRecording && !scriptInfo && !referenceFileTotalPages && (
                <TutorialOverlay
                    content={t('script-info-tutorial')}
                    onComplete={handleScriptInfoCompleteTutorial}
                    onSkip={handleSkipTutorial}
                    targetRef={firstSciptRef}
                />
            )} */}

        {/* scriptInfoRef 튜토리얼 - 녹음 중일 때만 표시, referenceFile 있을 때 */}
        {isRecording && !scriptInfoRef && referenceFileTotalPages && (
            <TutorialOverlay
                content={t('script-info-ref-tutorial')}
                onComplete={handleScriptInfoRefCompleteTutorial}
                onSkip={handleSkipTutorial}
                targetRef={firstSciptRef}
            />
        )}

        {scriptBlocks.length > 0 && (
            <button className={"page-script-page-index"} onClick={() => setFocusedPageIndex(pageIndex)}>
                {pageIndex + 1}
            </button>
        )}
        {scriptBlocks.map((block: any, index) => {
            // if (block.text === '') {
            //     return null
            // }
            return <DraggableParagraph block={block} key={`paragraph-${block.id}`}
                                       isRecording={isRecording}
                                       isLast={index === scriptBlocks.length - 1}
                                       setFocusedPageIndex={setFocusedPageIndex}
                                       setIsKeyboardBlock={setIsKeyboardBlock}/>
        })}
        {focusedPageIndex === pageIndex &&
            isRecording &&
            scriptBlocks.reduce((result: boolean, block: ScriptBlock) => result && block.state !== 'processing', true) &&
            <div className={'paragraph-block-textarea'}>
                <Skeleton count={3}/>
            </div>}
    </div>);
}

export default DroppablePageScript;

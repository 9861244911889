import React, {useCallback, useEffect, useRef, forwardRef} from 'react';
import cn from "classnames";
import {Link, useLocation} from "react-router-dom";
import {SubjectFromDB} from "../../../server/appNotes/type";
import {useDrag, useDrop} from "react-dnd";
import {
    deleteSubjectAction,
    refreshNewSubjectAction,
    updateNoteAction,
    updateSubjectAction
} from "../../../action/appNoteAction";
import './SubjectNavButton.scss'
import {IoCloseCircle} from "react-icons/io5";
import useClickOutside from "../../../hook/useClickOutside";
import {toast} from "react-toastify";
import ItemMenu from "../menu/ItemMenu";
import ConfirmModal from "../modal/ConfirmModal";
import {
    noteAssignedToSubjectEvent,
    subjectRenamedEvent,
    subjectDeleteButtonClickedEvent,
    subjectDeletedEvent,
} from "../../../analytics/ga";
import {DUMMY_SUBJECT} from "./LeftNav";
import {useTranslation} from "react-i18next";

type SubjectNavButtonProps = {
    item: SubjectFromDB & { isNew?: boolean }
}

const SubjectNavButton = forwardRef<HTMLDivElement, SubjectNavButtonProps>(({ item }, ref) => {

    const innerRef = useRef<HTMLDivElement | null>(null);

    // 내부 ref와 forwarded ref를 함께 처리
    React.useImperativeHandle(ref, () => innerRef.current as HTMLDivElement);

    const {t} = useTranslation('translation', {keyPrefix: "SubjectNavButton"});

    const location = useLocation();

    const [{isOver, canDrop}, drop] = useDrop({
        accept: ["note"],
        drop: async (dropItem: { note: any }) => {
            await updateNoteAction({noteId: dropItem.note.id, subjectId: item.id});
            noteAssignedToSubjectEvent(dropItem.note.id, item.id);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const [{opacity}, drag] = useDrag(
        () => ({
            type: "subject",
            item: {subject: item},
            collect: (monitor: any) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        }),
        [item],
    )

    const inputRef = React.useRef<HTMLInputElement>(null)

    const [isHover, setIsHover] = React.useState<boolean>(false)
    const [isEdit, setIsEdit] = React.useState<boolean>(false)
    const [editingName, setEditingName] = React.useState<string>(item.name)

    const onEditName = useCallback(async (e: any) => {
        e?.stopPropagation()
        e?.preventDefault()

        if (editingName === "" || editingName === "\n") {
            setEditingName(item.name)
            setIsEdit(false)
            toast.warning(t('empty-subject-name'));
            return
        }

        if (editingName.length > 20) {
            setEditingName(item.name)
            setIsEdit(false)
            toast.warning(t('subject-name-length'));
            return
        }

        if (editingName !== item.name) {
            subjectRenamedEvent(item.name, editingName);
            await updateSubjectAction({subjectId: item.id, name: editingName, folderId: item.folderId})
        }

        setIsEdit(false)
    }, [editingName, item.id, item.name, item.folderId])

    const boundRef = useClickOutside({
        handler: () => {
            if (isEdit) {
                onEditName(null)
            }
        }
    })

    const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState<boolean>(false)

    const {
        isActiveMenu,
        render
    } = ItemMenu({
        active: isHover && !isEdit,
        onEditName: e => {
            setIsEdit(true)
            setTimeout(() => {
                inputRef.current?.select()
            }, 10)
        },
        onDelete: async () => {
            subjectDeleteButtonClickedEvent(item.name); // subject 삭제 버튼 클릭 이벤트
            setIsConfirmModalOpen(true)
        }
    })

    const onDeleteSubject = async () => {
        await deleteSubjectAction({subjectId: item.id})
        subjectDeletedEvent(item.name); // subject 삭제 이벤트
        setIsConfirmModalOpen(false)
    }

    useEffect(() => {
        if (item?.isNew) {
            setIsEdit(true)
            setTimeout(() => {
                inputRef.current?.select()
            }, 10)
            refreshNewSubjectAction({subjectId: item.id})
        }
    }, [item.id, item?.isNew]);

    if (item.id === DUMMY_SUBJECT.id) {
        return (
            <div className={cn("subject-nav-button", {
                hover: location.pathname.includes(`/guide`)
            })}>
                <div className={"subject-nav-button-dot"} style={{backgroundColor: item.color}}/>
                <span className={"subject-nav-button-label"}>{item.name}</span>
            </div>
        )
    }

    return (
        // <div ref={drop} style={{opacity, borderRadius: 10}}>
        <div ref={(node) => {drop(drag(node)); innerRef.current = node;}} style={{opacity, borderRadius: 10}}>
            <ConfirmModal title={t('delete-subject')}
                          subtitle={t('delete-subject-confirm')}
                          isOpen={isConfirmModalOpen}
                          onClose={() => setIsConfirmModalOpen(false)}
                          onConfirm={onDeleteSubject}/>
            <div ref={boundRef}>
                <Link key={item.id}
                      ref={drag}
                      data-testid={"subject"}
                      to={`/notes/list/subjects/${item.id}`}
                      onMouseEnter={() => setIsHover(true)}
                      onMouseLeave={() => {
                          if (!isActiveMenu) {
                              setIsHover(false)
                          }
                      }}
                      className={cn("subject-nav-button", {
                          hover: location.pathname.includes(`/folder/${item.id}`) || (isOver && canDrop),
                          edit: isEdit
                      })}>
                    <div className={"subject-nav-button-dot"} style={{backgroundColor: item.color}}/>

                    {isEdit ?
                        <input className={"subject-nav-button-input"}
                               ref={inputRef}
                               value={editingName}
                               onChange={e => setEditingName(e.target.value)}
                               onKeyDown={e => {
                                   if (e.key === 'Escape') {
                                       setEditingName(item.name)
                                       setIsEdit(false)
                                   }
                                   if (e.key === 'Enter') {
                                       if (e.nativeEvent.isComposing) {
                                           return;
                                       }
                                       onEditName(e)
                                   }
                               }}
                               placeholder={t('placeholder-subject-name')}
                               maxLength={20}
                        />
                        :
                        <span className={"subject-nav-button-label"}>
                            {item.name}
                        </span>
                    }

                    {render()}

                    {isEdit && (
                        <button
                            className={"subject-nav-button-clear"}
                            onClick={(e) => {
                                e.preventDefault()
                                setEditingName("")
                                inputRef.current?.focus()
                            }}
                        >
                            <IoCloseCircle/>
                        </button>
                    )}

                </Link>
            </div>
        </div>
    );
});

export default SubjectNavButton;

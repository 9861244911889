import {NotifaiServer} from "..";
import {API} from "../class";
import joi from "joi";
import {FolderFromDB} from "./type";

export const createFolderAPI = new API<
    {
        body: {
            name: string,
            parent_folder_id?: number
        }
    },
    FolderFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/folders/",
    method: "post",
    requestValidator: joi.any(),
});

export const getFoldersAPI = new API<
    {},
    FolderFromDB[]
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/folders/",
    method: "get",
    requestValidator: joi.any(),
});

export const updateFolderAPI = new API<
    {
        params: {
            folderId: number;
        },
        body: {
            name: string;
            parent_folder_id?: number;
        };
    },
    FolderFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/folders/:folderId/",
    method: "put",
    requestValidator: joi.object({
        params: joi.object({
            folderId: joi.number().required(),
        }),
        body: joi.object({
            name: joi.string().required(),
            parent_folder_id: joi.number(),
        }),
    }),
});

export const deleteFolderAPI = new API<
    {
        params: {
            folderId: number;
        },
    },
    boolean
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/folders/:folderId/",
    method: "delete",
    requestValidator: joi.object({
        params: joi.object({
            folderId: joi.number().required(),
        }),
    }),
});


import {createEntityAdapter, createSlice, EntityId, EntityState, Slice} from '@reduxjs/toolkit';
import {RootState} from "./index";
import {AppNote} from "./appNote";

type AppNotesRedux = EntityState<AppNote, EntityId>

const AppNoteAdapter = createEntityAdapter<AppNote>({
    // @ts-ignore
    selectId: (appNote: AppNotes) => appNote.id,
});

export const {
    selectAll: selectAllAppNotes,
    selectIds: selectAppNotesIds,
    selectById: selectAppNotesById,
} = AppNoteAdapter.getSelectors<RootState>((state) => state.appNotes);

const appNotesSlice: Slice<AppNotesRedux> = createSlice({
    name: 'appNotes',
    initialState: AppNoteAdapter.getInitialState({}),
    reducers: {
        upsertAppNotes: (state, action) => {
            AppNoteAdapter.upsertMany(state, action.payload);
        },
        upsertAppNote: (state, action) => {
            AppNoteAdapter.upsertOne(state, action.payload);
        },
        removeAppNote: (state, action) => {
            AppNoteAdapter.removeOne(state, action.payload);
        },
        clearAppNotes: (state) => {
            AppNoteAdapter.removeAll(state);
        }
    },

});

export const {upsertAppNotes, upsertAppNote, removeAppNote, clearAppNotes} = appNotesSlice.actions;

export default appNotesSlice;

import { useMediaQuery } from 'react-responsive';
import {useMemo} from "react";

const TABLET_MIN_WIDTH = 768;
const DESKTOP_MIN_WIDTH = 1280;

export const useResponsive = () => {

    const isMobileDevice = useMemo(() => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }, [])

    const isMobile = useMediaQuery({
        query: `(max-width:${TABLET_MIN_WIDTH - 1}px)`,
    });
    const isTablet = useMediaQuery({
        query: `(min-width:${TABLET_MIN_WIDTH}px) and (max-width:${
            DESKTOP_MIN_WIDTH - 1
        }px)`,
    });
    const isDesktop = useMediaQuery({
        query: `(min-width:${DESKTOP_MIN_WIDTH}px)`,
    });

    return {
        isMobileDevice,
        isMobile,
        isTablet,
        isDesktop,
    };
};

export const Mobile = ({ isDevice = false, children }) => {
    const { isMobile, isMobileDevice } = useResponsive();

    if (!isDevice && isMobile) {
        return children;
    }

    if (isDevice && isMobileDevice && isMobile) {
        return children;
    }

    return null;
};

export const Tablet = ({ children }) => {
    const { isTablet } = useResponsive();
    if (isTablet) {
        return children;
    }
    return null;
};

export const Desktop = ({ children }) => {
    const { isDesktop } = useResponsive();
    if (isDesktop) {
        return children;
    }
    return null;
};

export const Responsive = ({ isDevice = false, mobile, desktop }) => {
    const { isMobile, isMobileDevice } = useResponsive();

    if (!isDevice && isMobile) {
        return mobile;
    }

    if (isDevice && isMobileDevice && isMobile) {
        return mobile;
    }

    return desktop;
}

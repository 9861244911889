import React, {useCallback, useEffect} from 'react';
import {useResponsive} from "../style/Responsive";
import {useTranslation} from "react-i18next";
import {AiOutlineClose} from "react-icons/ai";
import './MobileInfo.scss';

function MobileInfo() {

    const {t: mobileTranslation} = useTranslation('translation', {keyPrefix: "MobileInfo"});

    const {isMobile, isMobileDevice} = useResponsive()

    const [isShow, setIsShow] = React.useState<boolean>(false)

    const onClose = useCallback(() => {
        setIsShow(false)
        localStorage.setItem('isShowMobileInfo', 'true')
    },[])

    useEffect(() => {
        if (!localStorage.getItem('isShowMobileInfo')) {
            setIsShow(true)
        }
    }, [])

    if (!isMobile) {
        return null
    }

    if (!isShow) {
        return null
    }

    return (
        <div className={"mobile-info"}>
            {mobileTranslation('optimize-for-pc')}
            <button className={"mobile-info-close"}
                    onClick={onClose}>
                <AiOutlineClose fill={"white"}/>
            </button>
        </div>
    );
}

export default MobileInfo;

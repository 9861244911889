import { NotifaiServer } from "..";
import { API } from "../class";
import joi from "joi";
import {LanguageFromDB} from "./type";

export const getLanguagesAPI = new API<
  {},
    LanguageFromDB[]
>({
  serverInstance: NotifaiServer,
  url: "/languages/",
  method: "get",
  requestValidator: joi.any(),
});

import {
    createScriptBlockAPI,
    deleteScriptBlockAPI,
    translateScriptBlockAPI,
    updateScriptBlockAPI
} from "../server/appNotes/scriptBlock";
import {storeDispatch, storeState} from "../redux";
import {
    RecordingSession,
    ScriptBlock, selectAllRecordingSessions,
    selectRecordingSessionsById,
    upsertRecordingSession, upsertRecordingSessions,
} from "../redux/appNote";

const ORDER_INCREMENT = 0.01


export const updateScriptBlockAction = async (scriptBlock: ScriptBlock) => {
    const {response, error} = await updateScriptBlockAPI.request({
        params: {scriptBlockId: scriptBlock.id},
        body: {
            recording_session_id: scriptBlock.recordingSessionId,
            reference_file_page_index: scriptBlock.referenceFilePageIndex,
            text: scriptBlock.text,
            order: scriptBlock.order ?? 0,
        }
    })

    if (error) {
        return {}
    }

    const newRecordingSession = selectRecordingSessionsById(storeState(), scriptBlock.recordingSessionId)

    storeDispatch(upsertRecordingSession({
        ...newRecordingSession,
        scriptBlocks: [
            ...newRecordingSession.scriptBlocks.filter((block: ScriptBlock) => block.id !== scriptBlock.id),
            response
        ]
    }))

    return response
}

export const divideScriptBlockAction = async (scriptBlock: ScriptBlock) => {


    const [text1, text2] = scriptBlock.text.split('\n')

    const {response: updateResponse, error: updateError} = await updateScriptBlockAPI.request({
        params: {scriptBlockId: scriptBlock.id},
        body: {
            recording_session_id: scriptBlock.recordingSessionId,
            reference_file_page_index: scriptBlock.referenceFilePageIndex,
            text: text1,
            order: scriptBlock.order ?? 0,
        }
    })

    if (updateError) {
        return {}
    }

    const {response: createResponse, error: createError} = await createScriptBlockAPI.request({
        body: {
            recording_session_id: scriptBlock.recordingSessionId,
            reference_file_page_index: scriptBlock.referenceFilePageIndex,
            text: text2,
            order: (scriptBlock.order ?? 0) + ORDER_INCREMENT,
            audio_id: scriptBlock.audioId ?? 0
        }
    })

    if (createError) {
        return {}
    }

    const newRecordingSession = selectRecordingSessionsById(storeState(), scriptBlock.recordingSessionId)

    storeDispatch(upsertRecordingSession({
        ...newRecordingSession,
        scriptBlocks: [
            ...newRecordingSession.scriptBlocks
                .filter((block: ScriptBlock) => block.id !== scriptBlock.id),
            updateResponse,
            createResponse
        ]
    }))

    return {
        update: updateResponse,
        create: createResponse
    }
}

export const deleteScriptBlockAction = async (args: {
    scriptBlockId: number,
    recordingSessionId: number,
}) => {
    const {response, error} = await deleteScriptBlockAPI.request({
        params: {scriptBlockId: args.scriptBlockId},
    })

    if (error) {
        return {}
    }

    const newRecordingSession = selectRecordingSessionsById(storeState(),args.recordingSessionId)

    storeDispatch(upsertRecordingSession({
        ...newRecordingSession,
        scriptBlocks: newRecordingSession.scriptBlocks
            .filter((scriptBlock: ScriptBlock) => scriptBlock.id !== args.scriptBlockId)
    }))

    return response
}

export const translateScriptBlockAction = async (args: {
    scriptBlockIds: number[],
    languageId?: number,
}) => {

    if (args.scriptBlockIds.length === 0) {
        return {}
    }

    const {response, error} = await translateScriptBlockAPI.request({
        body: {
            script_block_ids: args.scriptBlockIds,
            dest_language_id: args.languageId ?? 3
        }
    })

    if (error) {
        return {}
    }

    const newRecordingSessions: RecordingSession[] = selectAllRecordingSessions(storeState()).map((recordingSession) => {
        return {
            ...recordingSession,
            scriptBlocks: recordingSession.scriptBlocks.map((scriptBlock: ScriptBlock) => {
                return response.find((translatedBlock: ScriptBlock) => translatedBlock.id === scriptBlock.id) || scriptBlock
            })
        }
    })

    storeDispatch(upsertRecordingSessions(newRecordingSessions))

    return response
}

import {storeDispatch} from "../redux";
import {upsertLanguages} from "../redux/languages";
import {getLanguagesAPI} from "../server/languages/language";


export const initLanguages = async () => {
    const {response, error} = await getLanguagesAPI.request({})

    if (error) {
        return
    }

    storeDispatch(upsertLanguages(response))
}

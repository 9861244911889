import React, {useCallback, useMemo} from 'react';
import recordingIcon from '../../asset/image/recording-icon.png';
import Select from "../../component-library/input/Select";
import {useTranslation} from "react-i18next";
import './NoteInit.scss';
import CommonButton from "../../component/button/CommonButton";
import useTooltip from "../../../hook/useTooltip";
import ReferenceInfoTooltips from "./ReferenceInfoTooltips";
import {deleteReferenceFileAction, uploadReferenceFileAction} from "../../../action/appNoteAction";
import {dataURItoBlob} from "../../../util/fileUtils";
import {NOTE_STATE, TYPE_NOTE_STATE} from "../../page/note/NoteDetail";
import {useSelector} from "react-redux";
import {recordingStartButtonClickedEvent,} from "../../../analytics/ga";
import {useResponsive} from "../../style/Responsive";
import {useNavigate} from "react-router-dom";
import {selectAllLanguages} from "../../../redux/languages";
import {RootState} from "../../../redux";
import {NoteInitResponsiveLayout} from "./ResponsiveLayout";

type NoteNewProps = {
    setNoteState: (noteState: TYPE_NOTE_STATE) => void
    setNoteSourceLanguageId: (languageId: number) => void
    createNewNote?: () => Promise<any>
}

const MAX_FILE_SIZE = 50 * 1000 * 1000

function NoteInit({setNoteState, setNoteSourceLanguageId, createNewNote}: NoteNewProps) {

    const {t} = useTranslation("translation", {keyPrefix: "NoteInit"})

    const navigate = useNavigate()

    const {isMobile} = useResponsive()

    const appUser = useSelector((state: RootState) => state.appUser)
    const languages = useSelector(selectAllLanguages)
    const note = useSelector((state: RootState) => state.appNote)

    const [selectedLanguageId, setSelectedLanguageId] = React.useState<number>(appUser.noteSourceLanguageId ?? languages[0]?.id);
    const [referenceFileContent, setReferenceFileContent] = React.useState<File>();
    const [pageNums, setPageNums] = React.useState<number>(0);
    const [fileSize, setFileSize] = React.useState<number>(0);
    const [isUploading, setIsUploading] = React.useState<boolean>(false);

    const {
        onActive: onActiveTooltip,
        onInactive: onInactiveTooltip,
        modalComponent: ReferenceInfoTooltipsComponent
    } = useTooltip({modalComponent: ReferenceInfoTooltips})

    // 항상 툴팁이 보이게 설정
    React.useEffect(() => {
        onActiveTooltip(); // 툴팁을 활성화 상태로 설정
    }, [onActiveTooltip]);

    const referenceFile = useMemo(() => {
        if (referenceFileContent) {
            return {
                file: referenceFileContent,
                name: referenceFileContent?.name
            }
        }
        if (note?.referenceFileS3Path) {
            return {
                file: note?.referenceFileS3Path,
                name: note?.referenceFileS3Path.split('/').pop()
            }
        }
        return null
    }, [referenceFileContent, note])

    const onLoadReferenceFile = useCallback(async (e: any) => {
        try {
            if (!referenceFileContent) return

            let targetNote = note

            if (!note?.id && createNewNote) {
                targetNote = (await createNewNote())
            }

            setIsUploading(true)

            const canvas = document.createElement("canvas");
            canvas.setAttribute("className", "canv");
            var page = await e.getPage(1);
            var viewport = page.getViewport({scale: 1.5});
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            var render_context = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport,
            };
            await page.render(render_context as any).promise;
            let img = canvas.toDataURL("image/png");

            const thumbnailFile = new File([dataURItoBlob(img)], `${targetNote.name}_thumbnail.png`, {type: 'image/png'})

            setPageNums(e._pdfInfo.numPages)

            setFileSize(referenceFileContent.size)

            if (referenceFileContent.size > MAX_FILE_SIZE) {
                setIsUploading(false)
                return
            }

            await uploadReferenceFileAction({
                noteId: targetNote.id,
                referenceFile: referenceFileContent,
                referenceFileTotalPages: e._pdfInfo.numPages,
                thumbnailImage: thumbnailFile,
            })

            setIsUploading(false)
        } catch (e) {
            setIsUploading(false)
        }

    }, [createNewNote, note, referenceFileContent])

    const deleteReferenceFile = useCallback(async (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        setReferenceFileContent(undefined)
        await deleteReferenceFileAction({
            noteId: note.id,
        })
        setFileSize(0)
    }, [note.id])

    const onStartRecording = useCallback(async () => {
        if (window.location.pathname === "/notes/new") {
            if (!note?.id) {
                if (!createNewNote) {
                    return
                }
                const createdNote = await createNewNote()
                navigate(`/notes/${createdNote.id}`, {
                    state: {
                        noteState: NOTE_STATE.EDIT,
                        languageId: selectedLanguageId
                    },
                    replace: true
                })
                return
            } else {
                navigate(`/notes/${note.id}`, {
                    state: {
                        noteState: NOTE_STATE.EDIT,
                        languageId: selectedLanguageId
                    },
                    replace: true
                })
            }
        }

        recordingStartButtonClickedEvent();  // '녹음 시작' 버튼 클릭 이벤트 호출
        setNoteState(NOTE_STATE.EDIT)
        setNoteSourceLanguageId(selectedLanguageId)

    }, [createNewNote, navigate, note.id, selectedLanguageId, setNoteSourceLanguageId, setNoteState])

    return (
        <div className={"note-new"}>

            <div className={"note-new-body"}>
                <NoteInitResponsiveLayout referenceFile={referenceFile}
                                  referenceFileContent={referenceFileContent}
                                  setReferenceFileContent={setReferenceFileContent}
                                  onLoadReferenceFile={onLoadReferenceFile}
                                  fileSize={fileSize}
                                  pageNums={pageNums}
                                  deleteReferenceFile={deleteReferenceFile}
                                  children={
                                      <>
                                          <label className={"note-new-audio-language"}>
                                              <div className={"note-new-audio-language-label"}>{t("audio-language")}</div>
                                              <div className={"note-new-audio-language-info"}>{t("audio-language-info")}</div>
                                              <Select className={"note-new-audio-language-select"}
                                                      options={languages}
                                                      labelKey={"name"}
                                                      valueKey={"id"}
                                                      value={selectedLanguageId?.toString()}
                                                      onChange={(e) => {
                                                          setSelectedLanguageId(parseInt(e.target.value))
                                                      }}/>
                                          </label>

                                          <div className={"note-new-audio-buttons"}>

                                              <CommonButton className={"note-new-audio-button"}
                                                            disabled={isUploading || fileSize > MAX_FILE_SIZE}
                                                            onClick={onStartRecording}>
                                                  {!isUploading &&
                                                      <img className={"note-new-audio-button-icon"} src={recordingIcon}
                                                           alt={'recording'}/>}
                                                  <span className={"note-new-audio-button-text"}>
                                                    {isUploading ? t('upload') : t("start-recording")}
                                                </span>
                                              </CommonButton>

                                          </div>
                                          {fileSize > MAX_FILE_SIZE && <div className={"note-new-warn-text"}>
                                              {t('upload-alert')}
                                          </div>}
                                      </>
                                  }
                />
            </div>

        </div>
    );

}

export default NoteInit;



import styled, { keyframes } from "styled-components";

const frameInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px); // 좀 더 큰 움직임을 줌
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  opacity: 0;  // 기본적으로 숨김 처리
  transform: translateY(50px); // 처음에 아래에 위치
  transition: opacity 0.6s ease-out, transform 0.6s ease-out; // 부드러운 애니메이션 전환

  &.frame-in {
    animation: ${frameInAnimation} 1s ease-out forwards; // 애니메이션 효과 부드럽게 적용
  }
`;

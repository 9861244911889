import React from 'react'
import './SectionMain.scss'
import {useTranslation} from "react-i18next";
import Button from "../../component-library/button/Button";
import {section1, arrow_right} from "../../asset/image";
import SignInModal from "../modal/SignInModal";
import {ScrollAnimationContainer} from "../ScrollAnimationContainer";

function SectionMain() {

    const {t} = useTranslation("translation", {keyPrefix: "SectionMain"})

    const [isOpenSignInModal, setIsOpenSignInModal] = React.useState(false);

    return (
        <ScrollAnimationContainer>
            <div id={"SectionMain"}>
                <SignInModal isOpen={isOpenSignInModal} onClose={() => setIsOpenSignInModal(false)}/>
                <div className={"section-main-info"}>
                    <h1 className={"section-main-info-title"}>{t('h1')}</h1>
                    <h2 className={"section-main-info-subtitle"}>{t('h2')}</h2>
                    <Button className={"section-main-info-button"}
                            onClick={() => {
                                setIsOpenSignInModal(true)
                            }}
                    >
                        {t('start-notifai')}
                        <img className={"section-main-info-button-icon"} src={arrow_right} alt={"go"}/>
                    </Button>
                </div>
                <div className={"section-main-img-wrapper"}>
                    <img className={"section-main-img"} src={section1} alt={'section1'}/>
                </div>
            </div>
        </ScrollAnimationContainer>
    );
}

export default SectionMain;

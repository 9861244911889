import {NotifaiServer} from "..";
import {API} from "../class";
import joi from "joi";
import {NoteSummaryFromDB} from "./type";

export const createNoteSummaryAPI = new API<
    {
        params: {
            noteId: number;
        },
        body: {
            language_id: number;
        }
    },
    NoteSummaryFromDB[]
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/notes/:noteId/summary/",
    method: "post",
    requestValidator: joi.object({
        params: joi.object({
            noteId: joi.number(),
        }),
        body: joi.object({
            language_id: joi.number(),
        })
    })
});

export const getNoteSummaryAPI = new API<
    {
        params: {
            noteId: number;
        },
    },
    NoteSummaryFromDB[]
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/notes/:noteId/summary/",
    method: "get",
    requestValidator: joi.object({
        params: joi.object({
            noteId: joi.number(),
        })
    })
});


export const updateNoteSummaryAPI = new API<
    {
        params: {
            noteId: number;
            summaryId: number;
        },
        body: {
            text: string;
            reference_file_index: number;
        }
    },
    NoteSummaryFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/notes/:noteId/summary/:summaryId/",
    method: "put",
    requestValidator: joi.object({
        params: joi.object({
            noteId: joi.number(),
            summaryId: joi.number
        }),
        body: joi.object({
            text: joi.string(),
            reference_file_index: joi.number()
        })
    })
});

export const deleteNoteSummaryAPI = new API<
    {
        params: {
            noteId: number;
            summaryId: number;
        },
    },
    any
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/notes/:noteId/summary/:summaryId/",
    method: "delete",
    requestValidator: joi.object({
        params: joi.object({
            noteId: joi.number(),
            summaryId: joi.number
        }),
    })
});

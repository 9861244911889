import React, {useCallback, useEffect} from 'react';
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import Layout from "../view/component/layout/Layout";
import NoteDetail from "../view/page/note/NoteDetail";
import NoteList from "../view/page/note/NoteList";
import LeftNavLayout from "../view/component/layout/LeftNavLayout";
import {RootState} from "../redux";
import {useSelector} from "react-redux";
import {initFolderAndSubject} from "../action/appNoteAction";
import {addAccessTokenToHeader} from "../server";
import {appUserAuthenticateAction, getAppUserSettings} from "../action/appUserAction";
import useInit from "../hook/useInit";
import NewNote from "../view/page/note/NewNote";
import Main from "../view/page/Main";
import {APP_USER_STATE, selectAppUserState} from "../redux/appUser";
import NoteSummary from "../view/page/note/NoteSummary";
import NoteLayout from "../view/component/note/NoteLayout";

function RootRouter() {

    const appUser = useSelector((state: RootState) => state.appUser);

    useInit(useCallback(async () => {
        if (appUser.id && appUser?.tokens?.accessToken) {
            addAccessTokenToHeader(appUser?.tokens?.accessToken)
            appUserAuthenticateAction();
            await initFolderAndSubject();
            await getAppUserSettings();
        }
    }, [appUser]));

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            if (window.location.origin.includes('notifai.site')) {
                window.location.href = 'https://notifai.kr';
            }
        }
    }, []);

    return (
        <Routes>
            <Route path="*" element={<Layout />}>
                <Route index element={<Routing />} />
                <Route path="landing" element={<Main />} />
                <Route path="*" element={<AuthorizedRouter />}>
                    <Route path="notes/*" >
                        <Route path="list/*" element={<LeftNavLayout />}>
                            <Route index element={<NoteList />} />
                            <Route path="subjects/:subjectId" element={<NoteList />} />
                        </Route>
                        <Route path="new" element={<NewNote />} />
                        <Route path=":noteId/*" element={<NoteLayout />}>
                            <Route index element={<NoteDetail />} />
                            <Route path="summary" element={<NoteSummary />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default RootRouter;

const AuthorizedRouter = () => {

    const navigate = useNavigate();

    const appUserState = useSelector(selectAppUserState);

    useEffect(() => {
        if (appUserState < APP_USER_STATE.AUTHORIZED) {
            navigate('/landing');
        }
    }, [appUserState, navigate]);

    return (
        <Outlet />
    )
}

const Routing = () => {
    const navigate = useNavigate();

    const appUserState = useSelector(selectAppUserState);

    useEffect(() => {
        if (appUserState < APP_USER_STATE.AUTHORIZED) {
            navigate('/landing');
        } else {
            navigate('/notes/list');
        }
    }, [appUserState, navigate]);

    return null
}

import {NoteSummaryFromDB} from "../server/appNotes/type";
import {
    createNoteSummaryAPI,
    deleteNoteSummaryAPI,
    getNoteSummaryAPI,
    updateNoteSummaryAPI
} from "../server/appNotes/summary";

export const createNoteSummaryAction = async (args: {
    noteId: number,
    languageId: number,
}): Promise<NoteSummaryFromDB[] | undefined> => {
    const {response, error} = await createNoteSummaryAPI.request({
        params: {
            noteId: args.noteId,
        },
        body: {
            language_id: args.languageId,
        }
    })

    if (error || !response) {
        return
    }

    return response
}

export const getNoteSummaryAction = async (args: {
    noteId: number,
}): Promise<NoteSummaryFromDB[]> => {
    const {response, error} = await getNoteSummaryAPI.request({
        params: {
            noteId: args.noteId,
        }
    })

    if (error || !response) {
        return []
    }

    return response
}

export const updateNoteSummaryAction = async (args: {
    noteId: number,
    summaryId: number,
    text: string,
    referenceFileIndex: number,
}): Promise<NoteSummaryFromDB | undefined> => {
    const {response, error} = await updateNoteSummaryAPI.request({
        params: {
            noteId: args.noteId,
            summaryId: args.summaryId,
        },
        body: {
            text: args.text,
            reference_file_index: args.referenceFileIndex,
        }
    })

    if (error || !response) {
        return
    }

    return response
}

export const deleteNoteSummaryAction = async (args: {
    noteId: number,
    summaryId: number,
}): Promise<any> => {
    const {response, error} = await deleteNoteSummaryAPI.request({
        params: {
            noteId: args.noteId,
            summaryId: args.summaryId,
        }
    })

    if (error || !response) {
        return
    }

    return response
}


import axios, {AxiosInstance, Method} from "axios";
import joi from "joi";
import {makeQuery} from "./makeQuery";
import {camelCase} from "./parser";

export const HTTP_METHOD = {
  POST: "post",
  PUT: "put",
  GET: "get",
  DELETE: "delete",
} as const

type TYPE_HTTP_METHOD = typeof HTTP_METHOD[keyof typeof HTTP_METHOD]

type REQUEST = {
  baseURL?: string;
  params?: {
    [key: string]: any;
  };
  query?: {
    [key: string]: any;
  };
  body?: {
    [key: string]: any;
  };
  form?: {
    [key: string]: any;
  }
  headers?: {
    [key: string]: any;
  };
};

export type TYPE_API = {
    serverInstance?: Server;
    url: string;
    method: TYPE_HTTP_METHOD;
    requestValidator?: joi.Schema;
    responseParser?: (response: any) => any;
    mocking?: (args?: any) => any;
}

export class API<
  REQUEST_TYPE extends REQUEST,
  RESPONSE_TYPE,
>  {
  serverInstance: Server | null;
  url: string;
  method: TYPE_HTTP_METHOD;
  requestValidator: joi.Schema;
  responseParser: (response: any) => RESPONSE_TYPE;
  mocking?: (args?: any) => any;

  constructor({serverInstance, url, method, responseParser = camelCase, requestValidator, mocking}: TYPE_API) {
    this.serverInstance = serverInstance || null;
    this.url = url;
    this.method = method;
    this.requestValidator = requestValidator ?? joi.any();
    this.responseParser = responseParser;
    this.mocking = mocking;
  }

  makePathWithParams(path: string, params: any) {
    if (!params) {
      return path;
    }
    return Object.keys(params).reduce((result: string, key) => {
      return result.replace(`:${key}`, params[key]);
    }, path);
  }

  isEmptyObject(obj: any) {
    if (!obj) {
      return true;
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  makeFormData(obj: any) {
    const formData = new FormData();
    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        formData.append(key, obj[key].toString())
      }
    })
    return formData
  }

  logValidationError(args: any, error: any) {
    console.group(":: API ERROR ::");
    console.log(`URL\t\t:: ${this.serverInstance?.baseURL}${this.url}`);
    console.log(`METHOD\t:: ${this.method}`);
    console.log("ARGS\t::", args);
    console.log("ERROR\t::", error);
    console.groupEnd();
  }

  // @ts-ignore
  async request(args?: REQUEST_TYPE): Promise<{
    response?: RESPONSE_TYPE,
    error?: any
  }> {
    if (!this.serverInstance) {
      throw new Error("serverInstance is not defined");
    }

    const { value: requestValue, error: requestError } =
      this.requestValidator.validate(args);

    if (requestError) {
      this.logValidationError(args, requestError);
      throw requestError;
    }

    let requestObj: any = {
      url: this.url,
      method: this.method,
    };

    if (!this.isEmptyObject(requestValue?.headers)) {
      requestObj.configs = {
        ...requestValue.headers
      };
    }

    if (!this.isEmptyObject(requestValue?.params)) {
      requestObj.url = this.makePathWithParams(this.url, requestValue.params);
    }

    if (!this.isEmptyObject(requestValue?.query)) {
      requestObj.url = requestObj.url + makeQuery(requestValue.query);
    }

    if (!this.isEmptyObject(requestValue?.body)) {
      requestObj.body = requestValue.body;
    }

    if (!this.isEmptyObject(requestValue?.form)) {
      requestObj.body = this.makeFormData(requestValue.form);
    }

    // @ts-ignore
    const {method, url, body: data, configs}: {method: Method, url: string} = requestObj

    // @ts-ignore
    let obj = [];
    switch (this.method) {
        case "post":
        case "put":
          // @ts-ignore
          obj = obj.concat([url, data]);
            break;
        case "get":
        case "delete":
          // @ts-ignore
          obj = obj.concat([url]);
            break;
      default:

    }

    if (configs) {
      obj.push(configs)
    }

    if (this.mocking) {
      return {
        response: this.mocking(args) as RESPONSE_TYPE
      };
    }

    try {
      // @ts-ignore
      const response = (await this.serverInstance.serverInstance[method](...obj)).data as RESPONSE_TYPE;

      if (!this.responseParser) {
        return response as any;
      }

      return {
        response: this.responseParser(response) as RESPONSE_TYPE
      };

    } catch (e: any) {
      if (e?.response?.status === axios.HttpStatusCode.Unauthorized) {
        const accessToken = await this.serverInstance.requestRefreshToken()
        this.serverInstance.updateRequestHeader({ Authorization: `Bearer ${accessToken}` })

        try{
          // @ts-ignore
          const response = (await this.serverInstance.serverInstance[method](...obj)).data as RESPONSE_TYPE;

          if (!this.responseParser) {
            return response as any;
          }

          return {
            response: this.responseParser(response) as RESPONSE_TYPE
          };
        } catch (e: any) {
          return {
            error: e
          }
        }
      }
      return {
        error: e
      }
    }
  }
}

export class Server {
  serverInstance: AxiosInstance;
  baseURL?: string;
  requestRefreshToken: () => Promise<string>

  constructor(args: { baseURL?: string; timeout?: number, refreshToken?: () => Promise<string> }) {
    this.serverInstance = axios.create({
      baseURL: args?.baseURL,
      timeout: args?.timeout,
    });
    this.baseURL = args?.baseURL;
    this.requestRefreshToken = args?.refreshToken || (async () => '')
  }

  updateRequestHeader(requestHeader: any) {
    this.serverInstance.defaults.headers.common = requestHeader;
  }
}

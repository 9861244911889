import {camelCase} from "../util/objectUtil";

export const dataToCamelCase = (response: any) => {
    return {
        ...response,
        data: {
            ...camelCase(response.data),
        }
    }
}

type ApiResult = {
    result: any,
    error: any,
}

export const apiHandler = async (request: Promise<any>): Promise<ApiResult> => {
    try {
        return {
            result: dataToCamelCase(await request),
            error: null,
        };
    } catch (e) {
        return {
            result: null,
            error: e,
        };
    }
}

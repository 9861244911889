import React from 'react';
import './SelectLanguage.scss';
import {useSelector} from "react-redux";
import {selectAllLanguages} from "../../../redux/languages";
import Select from "../../component-library/input/Select";
import {GrLanguage} from "react-icons/gr";
import arrowDown from "../../asset/image/arrow-down.png";

type SelectLanguageProps = {
    name: string;
    selectedLanguageId: number;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

function SelectLanguage({name, selectedLanguageId, onChange}: SelectLanguageProps) {

    const languages = useSelector(selectAllLanguages);

    return (
        <div className={'select-language'}>
            <div className={'select-language-icon'}>
                <GrLanguage size={16}/>
            </div>
            <Select className={'select-language-input'}
                    name={name}
                    options={languages}
                    value={selectedLanguageId.toString()}
                    onChange={onChange}
                    labelKey={'name'}
                    valueKey={'id'}/>
            <img className={"select-language-dropdown"} src={arrowDown} alt={'select-subject'}/>
        </div>
    );
}

export default SelectLanguage;

import React from 'react';
import cn from "classnames";
import './InputCheckbox.scss';
import {ImCheckboxChecked, ImCheckboxUnchecked} from "react-icons/im";

type InputCheckboxProps = {
    className?: string;
    value: any;
    onChange: (e: any) => void;
    id: string;
}

function InputCheckbox(props: InputCheckboxProps) {

    const {
        className,
        value,
        ...restProps
    } = props || {};

    return (
        <>
            <input
                type="checkbox"
                {...restProps}
                className={cn('checkbox', className)}
                checked={Boolean(value)}
                onChange={(e) => {
                    props.onChange({
                        target: {
                            id: props.id,
                            value: e.target.checked
                        }
                    })
                }}
            />
            {
                value ?
                    <ImCheckboxChecked className={'checked-icon'}/>
                    :
                    <ImCheckboxUnchecked className={'unchecked-icon'}/>
            }
        </>
    );
}

export default InputCheckbox;

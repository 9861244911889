import {createEntityAdapter, createSlice, EntityId, EntityState, Slice} from '@reduxjs/toolkit';
import {SettingFromDB, ActiveSettingFromDB} from "../server/appUsers/type";
import {RootState} from "./index";

export type Setting = SettingFromDB;
export type ActiveSetting = ActiveSettingFromDB;
export type SettingData = Setting & {isActive: boolean, activeSettingId?: number};

type SettingAndActiveSettingRedux = {
    settings: EntityState<Setting, EntityId>,
    activeSettings: EntityState<ActiveSetting, EntityId>,
}

const SettingAdapter = createEntityAdapter<Setting>({
    // @ts-ignore
    selectId: (setting:Setting) => setting.id,
});

export const {
    selectAll: selectAllSettings,
    selectIds: selectSettingsIds,
    selectById: selectSettingsById,
} = SettingAdapter.getSelectors<RootState>((state) => state.setting.settings);


const ActiveSettingAdapter = createEntityAdapter<ActiveSetting>({
    // @ts-ignore
    selectId: (activeSetting: ActiveSetting) => activeSetting.id,
});

export const {
    selectAll: selectAllActiveSettings,
    selectIds: selectActiveSettingsIds,
    selectById: selectActiveSettingsById,
} = ActiveSettingAdapter.getSelectors<RootState>((state) => state.setting.activeSettings);



const SettingAndActiveSettingInitialState: SettingAndActiveSettingRedux = {
    settings: SettingAdapter.getInitialState({}),
    activeSettings: ActiveSettingAdapter.getInitialState({}),
}

const settingSlice: Slice<SettingAndActiveSettingRedux> = createSlice({
    name: 'setting',
    initialState: SettingAndActiveSettingInitialState,
    reducers: {
        upsertSettings: (state, action) => {
            SettingAdapter.upsertMany(state.settings, action.payload);
        },
        upsertActiveSettings: (state, action) => {
            ActiveSettingAdapter.upsertMany(state.activeSettings, action.payload);
        },
        removeActiveSetting: (state, action) => {
            ActiveSettingAdapter.removeOne(state.activeSettings, action.payload);
        }
    },

});

export const {upsertSettings, upsertActiveSettings, removeActiveSetting} = settingSlice.actions;

export const selectSettingByType = (state: RootState, type: number): SettingData[] => {
    return selectAllSettings(state).filter(setting => setting.settingType === type)
        .map(setting => {

            const activeSetting = selectAllActiveSettings(state).find(activeSetting => activeSetting.settingId === setting.id);

            return {
                ...setting,
                activeSettingId: activeSetting?.id,
                isActive: activeSetting?.isActive ?? false,
            }
        });
};

export default settingSlice;

import React, {useState, useEffect, useCallback} from 'react';
import './NoteExportModal.scss';
import cn from 'classnames';
import Modal from "../../component-library/modal/Modal";
import Button from "../../component-library/button/Button";
import {useTranslation} from "react-i18next";
import {noteExportClickedEvent} from "../../../analytics/ga";
import {toast} from "react-toastify";
import {getNoteAction} from "../../../action/appNoteAction";
import {convertPdfToImages} from "../../../util/pdfToImagesUtil";
import {createAndDownloadWord} from "../../../util/downloadWordUtils";
import {useSelector} from "react-redux";
import {selectAppNote} from "../../../redux/appNote";
import {createAndDownloadMarkdown} from "../../../util/downloadMarkdownUtils";

type NoteExportModalProps = {
    isOpen: boolean;
    onClose: () => void;
    summaries?: any[]
}

type ExportContent = 'page' | 'text' | 'summary';
const EXPORT_CONTENTS: ExportContent[] = ['page', 'text', 'summary'];

type ExportType = 'markdown' | 'word' | 'pdf';
const EXPORT_TYPES: ExportType[] = ['word', 'markdown'];


function NoteExportModal({
    isOpen,
    onClose,
    summaries
}: NoteExportModalProps) {

    const {t} = useTranslation('translation', {keyPrefix: "NoteExportModal"});

    const note = useSelector(selectAppNote);

    const [exportContent, setExportContent] = useState<{
        page: boolean
        text: boolean
        summary: boolean
    }>({
        page: false,
        text: false,
        summary: false
    });
    const [exportType, setExportType] = useState<'markdown' | 'word' | 'pdf'>('word');
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);

    // 내용 선택 핸들러
    const onHandleExportContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setExportContent((prev) => ({
            ...prev,
            [name]: checked
        }));
    }

    // 형식 선택 핸들러
    const handleExportTypeChange = (type: 'markdown' | 'word' | 'pdf') => {
        setExportType(type);
    };

    const onConfirm = useCallback(async () => {
        noteExportClickedEvent(exportType);  // 내보내기 클릭 이벤트 트래킹
        const toastId = toast.loading(t('export-toast')); // "노트를 파일로 내보내는 중입니다... 시간이 다소 걸릴 수 있습니다."

        // const noteData = await getNoteAction(note?.id);
        const pdfUrl = note?.referenceFileS3Path;
        // let exportTypeName = '';

        if (pdfUrl) {
            const {
                pageImages,
                width,
                height
            } = await convertPdfToImages(pdfUrl, note?.id);

            if (exportType === 'markdown') {
                await createAndDownloadMarkdown({pageImages, note});
                // exportTypeName = t('markdown'); // "마크다운"
            } else if (exportType === 'word') {
                await createAndDownloadWord({pageImages, note, width, height});
                // exportTypeName = t('word'); // "워드"
            }
        }

        toast.update(toastId, {
            render: t('export-success-toast', { type: t(exportType) }), // `노트를 ${exportTypeName} 파일로 내보냈습니다.`,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: true,
        });

        if (exportType === 'markdown') {
            const toastMsg = t('markdown-copy-toast'); // "마크다운 파일이 클립보드에 복사되었습니다."
            toast.success(toastMsg, {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                }
            )
        }

        onClose();
    }, [exportType, note?.id, note?.referenceFileS3Path, onClose, t]);

    // 버튼 활성화 로직
    useEffect(() => {
     if (exportType === 'markdown' || exportType === 'word' || exportType === 'pdf') {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(false);
        }
    }, [exportType]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} className={"note-export-modal"}>
            <h3 className={"note-export-modal-title"}>{t("title")}</h3>

            {/*<p className={"note-export-modal-info"}>{t("select-export-content")}</p>*/}
            {/*<form className="note-export-modal-options">*/}
            {/*    {EXPORT_CONTENTS.filter((content) => content !== 'summary' || summaries).map((content: ExportContent) => {*/}
            {/*        return <label className={cn("note-export-modal-option", {active: exportContent[content]})} style={{opacity: content === 'summary' && !summaries ? 0.5 : 1}}>*/}
            {/*            <input*/}
            {/*                type="checkbox"*/}
            {/*                name={content}*/}
            {/*                value={content}*/}
            {/*                checked={exportContent[content]}*/}
            {/*                onChange={onHandleExportContentChange}*/}
            {/*                disabled={content === 'summary' && !summaries}*/}
            {/*            />*/}
            {/*            <span className={`icon ${content}`}>{content[0].toUpperCase()}</span>*/}
            {/*            {` ${t(content)} `}*/}
            {/*        </label>*/}
            {/*    })}*/}
            {/*</form>*/}

            <p className={"note-export-modal-info"}>{t("select-export-type")}</p>
            <form className="note-export-modal-options">
                {EXPORT_TYPES.map((type: ExportType) => {
                    return <label className={cn("note-export-modal-option", {active: exportType === type})}>
                        <input
                            type="radio"
                            value={type}
                            checked={exportType === type}
                            onChange={() => handleExportTypeChange(type)}
                        />
                        <span className={`icon ${type}`}>{type[0].toUpperCase()}</span>
                        {` ${t(type)} `}
                        <span style={{color: 'gray', fontSize: '12px', marginLeft: '3px'}}>{
                            type === 'word' ?
                                '(.docx)'
                                :
                                type === 'markdown' ?
                                    `(.md, ${t('markdown-info')})`
                                    :
                                    `(.pdf)`
                        }</span>
                    </label>
                })}
            </form>

            <div className={"note-export-modal-button-wrapper"}>
                <Button
                    onClick={onClose}
                    className={"note-export-modal-cancel-button"}>
                    {t('cancel')} {/* 취소 */}
                </Button>

                <Button
                    onClick={onConfirm}
                    className={cn("note-export-modal-confirm-button")}
                    disabled={!isButtonEnabled}>
                    {t('export')} {/* 내보내기 */}
                </Button>

            </div>
        </Modal>
    );
}

export default NoteExportModal;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './launges/en.json';
import ko from './launges/ko.json';
import ja from './launges/ja.json';
import ru from './launges/ru.json';
import zh from './launges/zh.json';
import vi from './launges/vi.json';

const resources = {
    ko,
    en,
    ja,
    ru,
    zh,
    vi
};

let locale = navigator.language || navigator.userLanguage;

const sessionLan = localStorage.getItem('language');

if (sessionLan) {
    locale = sessionLan;
} else {
    switch (locale) {
        case 'ko':
        case 'ko-KR':
            locale = 'ko';
            break;
        case 'en':
        case 'en-US':
            locale = 'en';
            break;
        case 'ja':
        case 'ja-JP':
            locale = 'ja';
            break;
        case 'ru':
        case 'ru-RU':
            locale = 'ru';
            break;
        case 'zh':
        case 'zh-CN':
        case 'zh-TW':
            locale = 'zh';
            break;
        case 'vi':
        case 'vi-VI':
            locale = 'vi';
            break;
        default:
            locale = 'en';
    }
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: locale,
    //TODO:: 콘솔 창을 위해 임시로 false로 변경
    debug: false,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});

export const text = key => i18n.t(key);

export default i18n;

import {createSlice, Slice} from '@reduxjs/toolkit';
import {Language} from "./languages";

export const APP_USER_STATE = {
    DEFAULT: -1,
    NOT_AUTHORIZED: 0,
    AUTHORIZED: 1,
    AUTHENTICATED: 2,
}

export type TYPE_APP_USER_STATE = typeof APP_USER_STATE[keyof typeof APP_USER_STATE];

type AppUserRedux = {
    id?: number,
    email?: string,
    username?: string,
    firstName?: string,
    lastName?: string,
    department?: string,
    description?: string,
    profileImage?: string,
    serviceLanguage?: Language,
    serviceLanguageId?: number,
    noteDestLanguage?: Language,
    noteDestLanguageId?: number,
    noteSourceLanguage?: Language,
    noteSourceLanguageId?: number,
    rootFolderId?: number,
    createdAt?: string,
    updatedAt?: string,
    tokens?: {
        accessToken?: string,
        refreshToken?: string,
    }
    state: TYPE_APP_USER_STATE
}



const appUserInitialState: AppUserRedux = {
    id: undefined,
    email: undefined,
    username: undefined,
    firstName: undefined,
    lastName: undefined,
    department: undefined,
    description: undefined,
    profileImage: undefined,
    serviceLanguage: undefined,
    serviceLanguageId: undefined,
    noteDestLanguage: undefined,
    noteDestLanguageId: undefined,
    noteSourceLanguage: undefined,
    noteSourceLanguageId: undefined,
    rootFolderId: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    tokens: {
        accessToken: undefined,
        refreshToken: undefined,
    },
    state: APP_USER_STATE.DEFAULT
}



const appUserSlice: Slice<AppUserRedux> = createSlice({
    name: 'appUser',
    initialState: appUserInitialState,
    reducers: {
        setAppUser(state, action) {
            Object.keys(action.payload).forEach(key => {
                // @ts-ignore
                state[key] = action.payload[key];
                if (key.includes('Language')) {
                    // @ts-ignore
                    state[key + 'Id'] = action.payload[key].id;
                }
            });
        },
        logout(state) {
            return {
                ...appUserInitialState,
                state: APP_USER_STATE.NOT_AUTHORIZED
            }
        }
    },

});

export const {setAppUser, logout} = appUserSlice.actions;

export const selectAppUser = (state: { appUser: AppUserRedux }) => state.appUser;
export const selectAppUserState = (state: { appUser: AppUserRedux }) => state.appUser.state;

export default appUserSlice;

import { Server } from "./class";
import {refreshTokenAPI} from "./appUsers/appUser";
// import {appUserReq} from "../service/appUser/appUser";

const SERVER_DOMAIN_URL = process.env.REACT_APP_SERVER_DOMAIN;

export const NotifaiServer = new Server({
  baseURL: SERVER_DOMAIN_URL,
  refreshToken: async () => {
    const {result, error} = await refreshTokenAPI();
    if (error) {throw error}
    return result.tokens.accessToken
  }
});

export const addAccessTokenToHeader = (accessToken: string) => {
  NotifaiServer.updateRequestHeader({ Authorization: `Bearer ${accessToken}` });
};



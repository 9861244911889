import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useSelector} from "react-redux";
import {selectAllTextboxes, Textbox} from "../redux/appNote";
import {useDebouncedCallback} from "use-debounce";
import {pageKeyPressedEvent, pageScrolledEvent} from "../analytics/ga";
import {createTextboxAction, deleteTextboxAction, updateTextboxAction} from "../action/appNoteTextboxAction";
import {useDrag} from "@use-gesture/react";
import {updateScriptBlockAction} from "../action/appNoteScriptBlockAction";
import {useFullScreenHandle} from "react-full-screen";
import {
    DEFAULT_TEXTBOX,
    DEFAULT_WIDTH,
    DELETE_TEXTBOX_ID,
    EDITING_TEXTBOX_ID, NEW_TEXTBOX_ID
} from "../view/component/note/ReferenceView";


type UseTextMemoProps = {
    width: number
}

function useTextMemo({width}: UseTextMemoProps) {

    const note = useSelector((state: any) => state.appNote)

    const editingTextBoxRef = useRef<any>(null);

    const appNoteTextboxes = useSelector(selectAllTextboxes)

    const [pageWidth, setPageWidth] = React.useState<number>(100);

    const [isActiveTextMemo, setIsActiveTextMemo] = React.useState<boolean>(true)
    const [editingTextBox, setEditingTextBox] = React.useState<Textbox>(DEFAULT_TEXTBOX)

    const [useColorPicker, setUseColorPicker] = React.useState<boolean>(false)

    const [lastFont, setLastFont] = React.useState<{
        fontColor: string,
        fontWeight: string,
        fontSize: number,
    }>({
        fontColor: "#FF0000",
        fontWeight: "normal",
        fontSize: 16,
    })

    const [movingVector, setMovingVector] = React.useState<{
        x: number,
        y: number
    }>({
        x: 0,
        y: 0,
    })

    const ratio = useMemo(() => DEFAULT_WIDTH / width, [width]);

    const onChangeTextboxSize = useDebouncedCallback(() => {
        if (editingTextBoxRef.current) {
            setEditingTextBox(prevState => ({
                ...prevState,
                textBoxWidth: editingTextBoxRef.current.offsetWidth * ratio,
                textBoxHeight: editingTextBoxRef.current.offsetHeight * ratio,
            }))
        }
    }, 200)



    const changeEditingTextBox = useCallback(async ({textboxId}: { textboxId: number }) => {
        if (textboxId === editingTextBox.id) {
            return
        }

        if (textboxId === DELETE_TEXTBOX_ID) {
            setEditingTextBox(DEFAULT_TEXTBOX)
            return
        }

        if (editingTextBox.id === EDITING_TEXTBOX_ID) {
            if (editingTextBox.text !== "") {
                await createTextboxAction({...editingTextBox, noteId: note.id})
            }
        } else {
            await updateTextboxAction(editingTextBox)
        }

        if (textboxId === NEW_TEXTBOX_ID || textboxId === EDITING_TEXTBOX_ID) {
            setEditingTextBox(DEFAULT_TEXTBOX)
            return
        }

        const targetTextBox = appNoteTextboxes.find(textbox => textbox.id === textboxId)
        if (targetTextBox) {
            setEditingTextBox(targetTextBox)
            return
        }
        setEditingTextBox(DEFAULT_TEXTBOX)

    }, [appNoteTextboxes, editingTextBox, note.id])

    const createTextBox = useCallback(async (e: any, index: number) => {
        await changeEditingTextBox({textboxId: NEW_TEXTBOX_ID})
        setEditingTextBox({
            ...DEFAULT_TEXTBOX,
            referenceFileIndex: index,
            xLocation: Math.round(e.nativeEvent.offsetX * ratio),
            yLocation: Math.round(e.nativeEvent.offsetY * ratio),
            textBoxWidth: Math.min((pageWidth - e.nativeEvent.offsetX) * ratio - 20, DEFAULT_TEXTBOX.textBoxWidth),
            ...lastFont
        })

        setTimeout(() => {
            editingTextBoxRef.current?.focus()
        }, 10)
    }, [changeEditingTextBox, lastFont, pageWidth, ratio])

    const dragBind = useDrag(({active, movement: [x, y]}) => {
        setMovingVector({x, y})
        if (!active) {
            setMovingVector({x: 0, y: 0})
            setEditingTextBox(prevState => ({
                ...prevState,
                xLocation: Math.round(prevState.xLocation + x * ratio),
                yLocation: Math.round(prevState.yLocation + y * ratio),
            }))
        }
    })

    const onDropScriptBlock = useCallback(async (block: any, index: number) => {
        await updateScriptBlockAction({
            ...block,
            referenceFilePageIndex: index
        })
    }, [])

    useEffect(() => {
        setPageWidth(width - 8)
    }, [width])


    return {
        ratio,
        editingTextBoxRef,
        onChangeTextboxSize,
        changeEditingTextBox,
        createTextBox,
        dragBind,
        onDropScriptBlock,
        editingTextBox,
        setEditingTextBox,
        isActiveTextMemo,
        setIsActiveTextMemo,
        movingVector,
        setLastFont,
        useColorPicker,
        setUseColorPicker
    }
}

export default useTextMemo;

import React, {useCallback, useMemo} from 'react';
import './Input.scss';
import cn from "classnames";
import Select from "./Select";
import InputFile from "./InputFile";
import InputCheckbox from "./InputCheckbox";

type InputProps = {
    className?: string;
    value: any;
    onChange: (e: any) => void;
    id: string;
    name?: string;
    label?: string;
    type?: string;
    placeholder?: string;
    options?: any[];
    validation?: (value: string) => boolean;
    fileProps?: {
        types: string[];
        maxSize: number;
        minSize: number;
        setPreview: (preview: any) => void;
    };
    readOnly?: boolean;
    disabled?: boolean;
};

function Input(props: InputProps) {

    const {
        label,
        value,
        fileProps,
        ...restProps
    } = props;

    const labelComponent = useCallback((children: any) => {
        if (!label) return children;
        return <label htmlFor={props.id} className={cn("label")}>
            <span className={cn("label-title")}>{label}</span>
            {children}
        </label>
    }, [label, props.id])

    const inputComponent = useMemo(() => {
        switch (props.type) {
            case 'select':
                return <Select
                    {...restProps}
                    value={value ?? ""}
                    options={restProps.options ?? []}
                />;
            case 'file':
                if (!fileProps) return null;
                return <InputFile {...props} fileProps={fileProps}/>;
            case 'checkbox':
                return <InputCheckbox {...props} />
            default:
                return <input
                    {...restProps}
                    className={cn('input', restProps.className)}
                    value={value ?? ""}
                />;
        }
    }, [fileProps, props, restProps, value])

    return labelComponent(inputComponent);
}

export default Input;

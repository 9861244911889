// src/redux/tutorialSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const tutorialOrder = ['noteAdd', 'subjectAdd', 'subjectDrag', 'noteDrag'] as const;
type TutorialStep = typeof tutorialOrder[number];

interface TutorialState {
  subjectAdd: boolean;
  noteAdd: boolean;
  subjectDrag: boolean;
  noteDrag: boolean;
  currentStep: number;
}

const initializeLocalStorage = (): TutorialState => {
  const savedState = localStorage.getItem('tutorial');
  if (!savedState) {
    const initialState: TutorialState = {
      subjectAdd: false,
      noteAdd: false,
      subjectDrag: false,
      noteDrag: false,
      currentStep: 0
    };
    localStorage.setItem('tutorial', JSON.stringify(initialState));
    return initialState;
  }
  return JSON.parse(savedState);
};

const initialState = initializeLocalStorage();

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setTutorialStep(state, action: PayloadAction<TutorialStep>) {
      const step = action.payload;
      if (!state[step]) {
        state[step] = true;
        state.currentStep = tutorialOrder.indexOf(step) + 1;
        localStorage.setItem('tutorial', JSON.stringify(state));
      }
    },
    skipAllTutorials(state) {
      tutorialOrder.forEach(step => {
        state[step] = true;
      });
      state.currentStep = tutorialOrder.length;
      localStorage.setItem('tutorial', JSON.stringify(state));
    },
  },
});

export const { setTutorialStep, skipAllTutorials } = tutorialSlice.actions;

export default tutorialSlice;
import React, {useCallback, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import useEditContent from "../../../hook/useEditContent";
import useTooltip from "../../../hook/useTooltip";
import './SettingAccount.scss'
import Input from "../../component-library/input/Input";
import {selectAllLanguages} from "../../../redux/languages";
import Button from "../../component-library/button/Button";
import {updateUserAction} from "../../../action/appUserAction";
import infoCircle from "../../asset/image/info-circle.png";
import InfoTooltips from "./InfoTooltips";
import AccountDeletionModal from './AccountDeletionModal';
import {toast} from "react-toastify";
import defaultProfileImage from "../../asset/image//logo(round).png";
import {useTranslation} from "react-i18next";
import SelectLanguage from "../input/SelectLanguage";

const ACCOUNT_PROPS = [
    {
        key: "username",
        label: "username",
        readOnly: true
    },
    {
        key: "email",
        label: "email",
        readOnly: true
    },
    {
        key: "description",
        label: "description"
    },
    // {
    //     key: "school",
    //     label: "학교"
    // },
    {
        key: "department",
        label: "department"
    },
    {
        key: "serviceLanguageId",
        label: "service-language",
        type: "select",
    },
    {
        key: "noteSourceLanguageId",
        label: "note-from-language",
        type: "select",
    },
    {
        key: "noteDestLanguageId",
        label: "note-to-language",
        type: "select",
    }
]

function SettingAccount() {

    const {t, i18n} = useTranslation('translation', {keyPrefix: "SettingAccount"});

    const appUser = useSelector((state: RootState) => state.appUser);
    const languages = useSelector(selectAllLanguages)

    const {content, setContent, editedContent, isEdit, setIsEdit} = useEditContent(appUser);
    const [isAccountDeletionModalOpen, setIsAccountDeletionModalOpen] = useState(false);
    const [profileImageSrc, setProfileImageSrc] = useState(appUser.profileImage || defaultProfileImage);

    const onChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        let {value, name}: {value: number | string, name: string} = e.target;
        if (e.target.name.includes("Language")) {
            value = parseInt(value);
        }
        setContent({...content, [name]: value})
    }, [content, setContent])

    const {
        onActive: onActiveTooltip,
        onInactive: onInactiveTooltip,
        modalComponent: InfoTooltipsComponent
    } = useTooltip({modalComponent: InfoTooltips})

    const onSave = useCallback(async () => {
        if (content.description && content.description.length > 100) {
            toast.warning(t('description-length'));
            return;
        }

        if (content.department && content.department.length > 20) {
            toast.warning(t('department-length'));
            return;
        }

        if (content.serviceLanguageId !== appUser.serviceLanguageId) {
            const code = languages.find(language => language.id === parseInt(content.serviceLanguageId))?.code || "en";
            localStorage.setItem("language", code);
            await i18n.changeLanguage(code)
        }

        try {
            await updateUserAction(content);  // 유저 정보 업데이트 API 호출
            toast.success(t('success-save'));  // 성공 메시지
        } catch (error) {
            toast.error(t('save-fail'));  // 오류 메시지
        }
    }, [appUser.serviceLanguageId, content, i18n, languages, t]);

    const handleImageError = useCallback(() => {
        setProfileImageSrc(defaultProfileImage);
    }, []);

    return (
        <div className={"setting-account"}>
            {t('my-account')}
            <div className="setting-account-profile">
                <img
                    className="setting-account-profile-image"
                    src={appUser.profileImage}
                    alt="profile"
                    onError={handleImageError}
                />
                <div className="setting-account-profile-group">
                    <div className="setting-account-profile-group-name">{`${appUser.firstName} ${appUser.lastName}`}</div>
                    <div className="setting-account-profile-group-email">{appUser.email}</div>
                </div>
            </div>

            <div className="section-header">{t('fill-profile')}</div>
            <div className="divider"></div>

            <div className="setting-account-prop-view">
                {ACCOUNT_PROPS.slice(2, 4).map((prop) => (
                    <label key={prop.key} className="setting-account-prop" htmlFor={`input_${prop.key}`}>
                        <span className={"setting-account-prop-label"}>{t(prop.label)}
                            {prop.key === "department" && (
                                <span
                                    className="tooltip-wrapper"
                                    onMouseEnter={onActiveTooltip}
                                    onMouseLeave={onInactiveTooltip}
                                >
                                <img
                                    src={infoCircle}
                                    alt="info"
                                    className="tooltip-icon"
                                />
                                {InfoTooltipsComponent}
                                </span>
                            )}
                        </span>

                        <Input
                            className="setting-account-prop-input"
                            id={`input_${prop.key}`}
                            name={prop.key}
                            type={prop.type}
                            value={content[prop.key] ?? ""}
                            onChange={onChange}
                            readOnly={prop.readOnly ?? false}
                            placeholder={prop.key === "description" ? t('description-placeholder') : t('department-placeholder')}
                        />
                    </label>
                ))}
            </div>


            <div className="section-header">{t('language-setting')}</div>
            <div className="divider"></div>

            <div className="setting-account-prop-view">
                {ACCOUNT_PROPS.slice(4, 6).map((prop) => (
                    <label key={prop.key} className="setting-account-prop" htmlFor={`input_${prop.key}`}>
                        <span className="setting-account-prop-label">{t(prop.label)}</span>
                        <SelectLanguage name={prop.key} onChange={onChange} selectedLanguageId={content[prop.key] as number} />
                    </label>
                ))}
            </div>
            <p className="setting-account-language-explain">* {t('language-explain')}</p>
            <div className={"setting-account-save"}>
                <Button onClick={onSave}>{t('save')}</Button>
            </div>
            <div className={"setting-account-deletion"}>
                <div className={"setting-account-deletion-label"}>
                    {t('delete-account')}
                </div>
                <span className={"setting-account-deletion-description"}>
                    {t('delete-account-confirm')}
                    <span className={"setting-account-deletion-button"}
                          onClick={() => setIsAccountDeletionModalOpen(true)}>
                        {t('delete-account')}
                    </span>
                </span>
            </div>
            {/* 계정 삭제 모달 */}
            <AccountDeletionModal
                isOpen={isAccountDeletionModalOpen}
                onClose={() => setIsAccountDeletionModalOpen(false)}
            />
        </div>
    );
}

export default SettingAccount;

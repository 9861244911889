import {NotifaiServer} from "..";
import {API} from "../class";
import joi from "joi";
import {NoteContentsFromDB, NoteFromDB, ImageS3Urls, NoteMemoFromDB, RecordingSessionFromDB} from "./type";
import {camelCase} from "../parser";
import {JsonParse} from "../../util/parseUtil";

export const createNoteAPI = new API<
    {
        body: {
            name: string,
            subject_id?: number,
        }
    },
    NoteFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/",
    method: "post",
    requestValidator: joi.object({
        body: joi.object({
            name: joi.string(),
            subject_id: joi.number().optional(),
        })
    })
});

export const selectNoteAPI = new API<
    {
        query: {
            subject_id?: number;
            keyword?: string;
            offset?: number;
            limit?: number;
        }
    },
    {
        notes: NoteFromDB[],
        numPages: number
    }
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/",
    method: "get",
    requestValidator: joi.object({
        query: joi.object({
            subject_id: joi.number(),
            keyword: joi.string(),
            offset: joi.number(),
            limit: joi.number(),
        }),
    }),
});

export const getNoteAPI = new API<
    {
        params: {
            noteId: number;
        }
    },
    NoteFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/:noteId/",
    method: "get",
    requestValidator: joi.object({
        params: joi.object({
            noteId: joi.number().required(),
        }),
    }),
});

export const getNoteContentsAPI = new API<
    {
        params: {
            noteId: number;
        }
    },
    NoteContentsFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/:noteId/contents/",
    method: "get",
    requestValidator: joi.object({
        params: joi.object({
            noteId: joi.number().required(),
        }),
    }),
    responseParser: (response) => {
        const camelResponse = camelCase(response)
        return {
            ...camelResponse,
            recordingSessionDataList: camelResponse.recordingSessionDataList.map((recordingSessionData: any) => ({
                ...recordingSessionData,
                scriptBlocks: recordingSessionData.scriptBlocks.map((scriptBlock: any) => ({
                    ...scriptBlock,
                    text: (JsonParse(scriptBlock?.text)?.data?.text) ?? scriptBlock.text,
                }))
            }))
        }
    }
});

export const getScripts = new API<
    {
        params: {
            noteId: number;
        }
    },
    NoteFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/:noteId/",
    method: "get",
    requestValidator: joi.object({
        params: joi.object({
            noteId: joi.number().required(),
        }),
    }),
});

export const createRecordingSession = new API<
    {
        body: {
            note_id: number
            language_id: number | null
        }
    },
    RecordingSessionFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/recording-sessions/",
    method: "post",
    requestValidator: joi.any(),
});


export const uploadWholeAudioAPI = new API<
    {
        params: {
            session_id: number
        },
        body: FormData,
    },
    RecordingSessionFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/recording-sessions/:session_id/upload-whole-audio",
    method: "post",
    requestValidator: joi.any(),
});

export const uploadReferenceFileAPI = new API<
    {
        params: {
            noteId: number;
        },
        body: FormData
    },
    NoteFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/:noteId/upload-reference-file/",
    method: "post",
    requestValidator: joi.any(),
});

export const deleteReferenceFileAPI = new API<
    {
        params: {
            noteId: number;
        },
    },
    NoteFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/:noteId/delete-reference-file/",
    method: "delete",
    requestValidator: joi.any(),
});

export const uploadAudioAPI = new API<
    {
        body: FormData,
    },
    NoteFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/audios/",
    method: "post",
    requestValidator: joi.any(),
});

export const uploadAudioRealtimeAPI = new API<
    {
        body: FormData,
    },
    NoteFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/audios/realtime/",
    method: "post",
    requestValidator: joi.any(),
});


export const updateNoteAPI = new API<
    {
        params: {
            noteId: number;
        },
        body?: {
            name?: string;
            subject_id?: number;
            preview_text?: string;
        }
    },
    NoteFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/:noteId/",
    method: "put",
    requestValidator: joi.object({
        params: joi.object({
            noteId: joi.number().required(),
        }),
        body: joi.object({
            name: joi.string(),
            subject_id: joi.number(),
            preview_text: joi.string(),
        }),
    }),
});

export const deleteNoteAPI = new API<
    {
        params: {
            noteId: number;
        },
    },
    boolean
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/:noteId/",
    method: "delete",
    requestValidator: joi.any(),
});


export const checkNotionPermissionAPI = new API<
    {
        body: {
            notion_page_url: string;
        };
    },
    boolean
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/check-notion-permission",
    method: "post",
    requestValidator: joi.object({
        body: joi.object({
            notion_page_url: joi.string().required(),
        }),
    }),
});


export const getNotionAccessTokenAPI = new API<
  {
    body: {
      code: string;
    };
  },
  boolean
>({
  serverInstance: NotifaiServer,
  url: '/app-notes/notes/notion-access-token',
  method: 'post',
  requestValidator: joi.object({
    body: joi.object({
      code: joi.string().required(),
    }),
  }),
});


export const exportNoteToNotionAPI = new API<
  {
    params: {
      noteId: number;
    };

    body: {
        notion_page_url: string;
    }
  },
  any
>({
  serverInstance: NotifaiServer,
  url: "/app-notes/notes/:noteId/export-to-notion/",
  method: "post",
  requestValidator: joi.object({
    params: joi.object({
      noteId: joi.number().required(),
    }),
    body: joi.object({
        notion_page_url: joi.string().required(),
    })
  }),
});


export const getScriptsByPageIndexAPI = new API<
{
    params: {
        noteId: number;
    },
    query: {
        reference_file_index: number;
    },
},
{
    scripts: string;
}
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/script-blocks/:noteId/",
    method: "get",
});

export const uploadImagesToS3API = new API<
{
    params: {
        noteId: number;
    },
    body: FormData
},
ImageS3Urls
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/notes/:noteId/upload-images/",
    method: "post",
    requestValidator: joi.any(),
});

export const createNoteMemoAPI = new API<
    {
        body: {
            note_id: number;
            text: string;
            timestamp?: number;
        }
    },
    NoteMemoFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/note-memos/",
    method: "post",
    requestValidator: joi.any(),
});

export const getNoteMemoAPI = new API<
    {
        params: {
            noteMemoId: number;
        },
    },
    NoteMemoFromDB[]
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/note-memos/:noteMemoId/",
    method: "get",
    requestValidator: joi.any(),
});

export const updateNoteMemoAPI = new API<
    {
        params: {
            noteMemoId: number;
        },
        body: {
            text: string;
            timestamp?: number;
        }
    },
    NoteMemoFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/note-memos/:noteMemoId",
    method: "put",
    requestValidator: joi.any(),
});

export const deleteNoteMemoAPI = new API<
    {
        params: {
            noteMemoId: number;
        },
    },
    any
>({
    serverInstance: NotifaiServer,
    url: "/app-notes/real-time-notes/note-memos/:noteMemoId",
    method: "delete",
    requestValidator: joi.any(),
});

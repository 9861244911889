import React from 'react';
import Modal from "../../component-library/modal/Modal";
import './AudioUploadModal.scss';
import {useTranslation} from "react-i18next";
import {RingLoader} from "react-spinners";

type AudioUploadModalProps = {
    isOpen: boolean;
    onClose: () => void;
}

function AudioUploadModal({isOpen, onClose}: AudioUploadModalProps) {

    const {t} = useTranslation('translation', {keyPrefix: "AudioUploadModal"});

    return (
        <Modal isOpen={isOpen} onClose={onClose} className={"audio-upload-modal"}>
            <RingLoader
                color={'#7A24F8'}
                loading={true}
                size={48}
                aria-label="Loading Spinner"
                data-testid="loader"/>
            <p className={"audio-upload-modal-text"}>
                {t('uploading')}
            </p>
        </Modal>
    );
}

export default AudioUploadModal;

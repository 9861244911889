// src/view/component/nav/NavButton.tsx

import React, {forwardRef} from 'react';
import {Folder, Subject} from "../../../redux/folderAndSubject";
import SubjectNavButton from "./SubjectNavButton";
import FolderNavButton from "./FolderNavButton";


type NavButtonProps = {
    item: Folder | Subject;
    showFolderIds: number[];
    setShowFolderIds: (value: React.SetStateAction<number[]>) => void;
}

const NavButton = forwardRef<HTMLDivElement, NavButtonProps>(({ item, showFolderIds, setShowFolderIds }, ref) => {
    if (item.type === 'folder') {
        // return <FolderNavButton item={item} showFolderIds={showFolderIds} setShowFolderIds={setShowFolderIds} ref={ref} />
        return null;
    } else {
        return <SubjectNavButton item={item} ref={ref} />;
    }
});

export default NavButton;
import React, {useEffect, useState} from 'react';
import './NoteDetail.scss';
import NoteInit from "../../component/note/NoteInit";
import NoteEditView from "../../component/note/NoteEditView";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {RecordingSession, ScriptBlock, selectAllRecordingSessions, selectAppNote} from "../../../redux/appNote";
import {updateNoteAction,} from "../../../action/appNoteAction";
import IsActive from "../../component-library/common/IsActive";
import {Mobile, useResponsive} from "../../style/Responsive";
import NoteHeader from "../../component/note/NoteHeader";
import {RootState} from "../../../redux";
import {useTranslation} from "react-i18next";
import useHideChannelTalk from "../../../hook/useHideChannelTalk";
import {RecordingSessionBelongFromDB} from "../../../server/appNotes/type";

export const NOTE_STATE = {
    DEFAULT: 0,
    NEW: 1,
    EDIT: 2,
    RESULT: 3
}

export type TYPE_NOTE_STATE = typeof NOTE_STATE[keyof typeof NOTE_STATE]

function NoteDetail() {

    const {t} = useTranslation("translation", {keyPrefix: "NoteDetail"})

    const {isMobile, isMobileDevice} = useResponsive()

    const location = useLocation()

    const appUser = useSelector((state: RootState) => state.appUser)
    const note = useSelector(selectAppNote);
    const recordingSessionDataList = useSelector(selectAllRecordingSessions)

    const [blocks, setBlocks] = React.useState<any[]>([])
    const [noteState, setNoteState] = React.useState<TYPE_NOTE_STATE>(location?.state?.noteState ?? NOTE_STATE.DEFAULT)
    const [noteSourceLanguageId, setNoteSourceLanguageId] = useState<number>(location?.state?.languageId ?? appUser?.noteSourceLanguageId)
    const [isRecording, setIsRecording] = useState(false);
    const [isKeyboardBlock, setIsKeyboardBlock] = useState<boolean>(false)

    useEffect(() => {
        if (noteState === NOTE_STATE.DEFAULT && note.id && recordingSessionDataList?.length === 0) {
            setNoteState(NOTE_STATE.NEW)
            return
        }
        if (noteState === NOTE_STATE.DEFAULT && note.id && recordingSessionDataList?.length !== 0) {
            setNoteState(NOTE_STATE.EDIT)
            return
        }
    }, [note.id, noteState, recordingSessionDataList])

    useEffect(() => {
        const blocks = recordingSessionDataList.reduce((blocks: ScriptBlock[], session: RecordingSessionBelongFromDB) => blocks.concat(session.scriptBlocks), [])
        if (blocks.length > 0) {
            const previewText = blocks.map((block: ScriptBlock) => block.text?.split("{:")
                .reduce((acc, cur) => {
                    if (cur.includes(":}")) {
                        return acc + cur.split(":}")[1]
                    }
                    return acc
                }, "")
            ).join("")

            if (previewText && previewText.slice(0, 200) !== note.previewText) {
                updateNoteAction({noteId: note.id, previewText: previewText.slice(0, 200)})
            }
        }
    }, [note.id, note.previewText, recordingSessionDataList]);

    useHideChannelTalk()

    return (
        <div id={"NoteDetail"} className={"note"}>
            <NoteHeader setIsKeyboardBlock={setIsKeyboardBlock} isRecording={isRecording} />
            <IsActive active={Boolean(note.id) && noteState === NOTE_STATE.NEW}>
                <NoteInit setNoteState={setNoteState} setNoteSourceLanguageId={setNoteSourceLanguageId}/>
            </IsActive>
            <IsActive active={Boolean(note.id) && noteState === NOTE_STATE.EDIT}>
                <NoteEditView
                    blocks={blocks}
                    setBlocks={setBlocks}
                    isRecording={isRecording}
                    setIsRecording={setIsRecording}
                    isKeyboardBlock={isKeyboardBlock}
                    setIsKeyboardBlock={setIsKeyboardBlock}
                    noteSourceLanguageId={noteSourceLanguageId}
                    setNoteSourceLanguageId={setNoteSourceLanguageId}

                />
            </IsActive>
        </div>

    );
}

export default NoteDetail;

import {API} from "../class";
import {AppUserFromDB} from "./type";
import {NotifaiServer} from "../index";
import joi from "joi";
import {apiHandler} from "../utils";
import axios from "axios";
import Cookies from 'universal-cookie';

const SERVER_DOMAIN_URL = process.env.REACT_APP_SERVER_DOMAIN;
const cookies = new Cookies();

export const refreshTokenAPI = async (): Promise<{
    result: any,
    error: any,
}> => {
    const refreshToken = cookies.get('refresh_token')

    if (!refreshToken) {
        return {
            result: {},
            error: 'refreshToken is not exist'
        }
    }

    const {result, error} = await apiHandler(axios.post(
        SERVER_DOMAIN_URL + '/app-users/refresh-token',
        {
            refreshToken,
        },
        {
            'withCredentials': true,
        }
    ));

    if (error) {
        return {result: null, error}
    }

    cookies.set('refresh_token', result.data.jwt.refreshToken, {
        path: '/',
        secure: process.env.REACT_APP_ENVIRONMENT === 'production',
        maxAge: 3600 * 24 * 30,
    });

    return {
        result: {
            ...result.data.user,
            tokens: result.data.jwt,
        },
        error: null
    };
}

// ===============================================================

export const loginByGoogleAPI = new API<
    {
        body: {
            code: string;
            scope: string;
        };
    },
    AppUserFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-users/google-oauth/access-token",
    method: "post",
    requestValidator: joi.object({
        body: joi.object({
            code: joi.string().required(),
            scope: joi.string().required(),
        }),
    }),
});

export const updateUserAPI = new API<
    {
        body: {
            description?: string;
            department?: string;
            service_language_id?: number;
            note_source_language_id?: number;
            note_dest_language_id?: number;
        };
    },
    AppUserFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-users/",
    method: "put",
    requestValidator: joi.object({
        body: joi.object({
            description: joi.string().allow(""),
            department: joi.string().allow(""),
            service_language_id: joi.number(),
            note_source_language_id: joi.number(),
            note_dest_language_id: joi.number(),
        }),
    }),
});

export const deleteUserAPI = new API<
    {
        body: {
            reasons: string[];
        };
    },
    any
>({
    serverInstance: NotifaiServer,
    url: "/app-users/delete-user",
    method: "post",
    requestValidator: joi.object({
        body: joi.object({
            reasons: joi.array().items(joi.string()).required(),
        }),
    }),
});

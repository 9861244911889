// src/redux/index.ts

import {Action, configureStore} from "@reduxjs/toolkit";
import appUserSlice from "./appUser";
import folderAndSubjectSlice from "./folderAndSubject";
import languagesSlice from "./languages";
import settingSlice from "./setting";
import appNotesSlice from "./appNotes";
import appNoteSlice from "./appNote";
import noteAudioSlice from "./noteAudio";
import tutorialSlice from "./tutorialSlice"; 
import noteTutorialSlice from "./noteTutorialSlice";

const store = configureStore({
    reducer: {
        appUser: appUserSlice.reducer,
        appNote: appNoteSlice.reducer,
        noteAudio: noteAudioSlice.reducer,
        appNotes: appNotesSlice.reducer,
        folderAndSubject: folderAndSubjectSlice.reducer,
        languages: languagesSlice.reducer,
        setting: settingSlice.reducer,
        tutorial: tutorialSlice.reducer,
        noteTutorial: noteTutorialSlice.reducer,
    },
});

store.subscribe(() => {
});

export const storeDispatch = (action: Action) => store.dispatch(action);
export default store;
export const storeState = () => store.getState();
export type RootState = ReturnType<typeof store.getState>;

import React, {useEffect} from 'react';
import './Main.scss';
import Header from "../component/layout/Header";
import SectionMain from "../component/section/SectionMain";
import SectionSecond from "../component/section/SectionSecond";
import SectionThird from "../component/section/SectionThird";
import SectionFourth from "../component/section/SectionFourth";
import Footer from "../component/layout/Footer";
import {RootState} from "../../redux";
import {useSelector} from "react-redux";
import {APP_USER_STATE} from "../../redux/appUser";
import {useNavigate} from "react-router-dom";

function Main() {

    const navigate = useNavigate()

    const appUser = useSelector((state: RootState) => state.appUser);

    useEffect(() => {
        if(appUser.state === APP_USER_STATE.AUTHORIZED) {
            navigate('/notes/list')
        }
    }, [appUser, navigate]);

    return (
        <>
            <Header />
            <div id={"Main"}>
                <SectionMain />
                <SectionSecond />
                <SectionThird />
                <SectionFourth />
            </div>
            <Footer />
        </>
    );
}

export default Main;

import React from 'react';
import Modal from "../../component-library/modal/Modal";
import './ConfirmModal.scss';
import Button from "../../component-library/button/Button";
import cn from "classnames";
import {useTranslation} from "react-i18next";

type SignInModalProps = {
    title: string;
    subtitle: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    confirmClassName?: string;
}

function ConfirmModal({isOpen, onClose, onConfirm, title, subtitle, confirmClassName}: SignInModalProps) {

    const {t} = useTranslation('translation', {keyPrefix: "ConfirmModal"});

    return (
        <Modal isOpen={isOpen} onClose={onClose} className={"note-export-modal"}>
            <h3 className={"confirm-modal-title"}>{title}</h3>
            <p className={"confirm-modal-subtitle"}>{subtitle}</p>

            <div className={"confirm-modal-button-wrapper"}>
                <Button onClick={onClose} className={"confirm-modal-close-button"}>{t('cancel')}</Button> {/* 취소 */}
                <Button onClick={onConfirm} className={cn("confirm-modal-confirm-button", confirmClassName)} >{t('delete')}</Button> {/* 삭제하기 */}
            </div>
        </Modal>
    );
}

export default ConfirmModal;

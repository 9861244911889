import React, {useEffect} from 'react';
import Modal from "../../component-library/modal/Modal";
import './SignInModal.scss';
import {useGoogleLogin} from "@react-oauth/google";
import {useTranslation} from "react-i18next";
import { Trans } from 'react-i18next';
import {googleLoginAction} from "../../../action/appUserAction";
import googleLoginImage from "../../asset/image/web_light_sq_SU@4x.png"
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {google_sign_in, google_logo} from "../../asset/image";
import {
    googleLoginButtonClickedEvent,
    googleLoginSuccessEvent,
} from "../../../analytics/ga";

type SignInModalProps = {
    isOpen: boolean;
    onClose: () => void;
}

function SignInModal({isOpen, onClose}: SignInModalProps) {

    const {t} = useTranslation('translation', {keyPrefix: "SignInModal"});

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const result = await googleLoginAction(tokenResponse);
            googleLoginSuccessEvent();
            if (result) {
                onClose();
            }
        },
        flow: 'auth-code',
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} className={"sign-in-modal"}>
            <h3 className={"sign-in-modal-title"}>{t('start')}</h3> {/* 노티파이 시작하기 */}
            <p className={"sign-in-modal-subtitle"}>{t('login')}</p> {/* 간편 로그인으로 3초만에 노티파이를 시작해보세요 */}

            <button
                className="sign-in-modal-button"
                onClick={() => {
                    googleLoginButtonClickedEvent();
                    login();
                }}
            >
                <div className="sign-in-modal-button-content">
                    <img className="google-logo" src={google_logo} alt="Google Logo" />
                    <span className="google-login-text">{t('google-login')}  {/* Google로 로그인 */}</span>
                </div>
            </button>

            <p className={"sign-in-modal-terms"}>
                <Trans i18nKey="terms" t={t}>
                    로그인 시 <a href="https://notifai.notion.site/Notifai-105f6ff037e6801fb64bc684033ff411?pvs=4" className="link" target={"_blank"}>개인정보보호 정책</a> 및 <a href="https://notifai.notion.site/Notifai-0115b9a8bd8048adb2dd92fbff7d0436?pvs=4" className="link" target={"_blank"}>서비스 이용약관</a>
                    에 동의하는 것으로 간주하며, 서비스 이용을 위해 이메일과 이름을 수집합니다.
                </Trans>
            </p>
        </Modal>
    );
}

export default SignInModal;

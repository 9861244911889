import React, {useCallback, forwardRef, useRef} from 'react';
import cn from "classnames";
import {Page} from "react-pdf";
import TextareaAutosize from "react-textarea-autosize";
import {animated} from "@react-spring/web";
import {RxFontBold} from "react-icons/rx";
import Select from "../../component-library/input/Select";
import {HexColorPicker} from "react-colorful";
import {MdDelete} from "react-icons/md";
import {useDrop} from "react-dnd";
import {Textbox} from "../../../redux/appNote";
import {DELETE_TEXTBOX_ID} from "./ReferenceView";
import {deleteTextboxAction} from "../../../action/appNoteTextboxAction";
import useClickOutside from "../../../hook/useClickOutside";
import {
    textNoteCreatedEvent,
} from "../../../analytics/ga";
import Skeleton from "react-loading-skeleton";

type DroppablePageViewProps = {
    index: number
    focusedPageIndex: number
    createTextBox: (e: any, index: number) => void
    textBoxes: any[]
    setEditingTextBox: (arg: any) => void
    movingVector: any
    pageWidth: number
    ratio: number
    setPageHeight: (height: number) => void
    onChangeTextboxSize: () => void
    editingTextBoxRef: any
    changeEditingTextBox: (args: { textboxId: number }) => Promise<void>
    useColorPicker: boolean
    setUseColorPicker: (useColorPicker: boolean | any) => void
    setLastFont: (arg: any) => void
    dragBind: any
    onDrop: (item: any) => void
    useBorder?: boolean
    usePageIndex?: boolean
    setIsKeyboardBlock: (args: any) => void
    isActiveTextMemo?: boolean
}

const DroppablePageView = forwardRef<HTMLDivElement, DroppablePageViewProps>(function DroppablePageView({
        index,
        focusedPageIndex,
        createTextBox,
        textBoxes,
        setEditingTextBox,
        movingVector,
        pageWidth,
        ratio,
        setPageHeight,
        onChangeTextboxSize,
        editingTextBoxRef,
        changeEditingTextBox,
        useColorPicker,
        setUseColorPicker,
        setLastFont,
        dragBind,
        onDrop,
        useBorder = true,
        usePageIndex = true,
        setIsKeyboardBlock,
        isActiveTextMemo = true
    }, ref) {

    const [{isOver}, drop] = useDrop({
        accept: ["scriptBlock"],
        drop: onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    const [isActiveHandle, setIsActiveHandle] = React.useState<boolean>(true)
    const [isAfterEscape, setIsAfterEscape] = React.useState<boolean>(false)

    const onDeleteTextBox = useCallback(async ({textboxId}: { textboxId: number }) => {
        if (textboxId !== -1) {
            await deleteTextboxAction({textboxId})
        }
        await changeEditingTextBox({textboxId: DELETE_TEXTBOX_ID})
    }, [changeEditingTextBox])

    const escapeEditing = useCallback(async () => {
        setIsActiveHandle(true)
        setUseColorPicker(false)
        await editingTextBoxRef.current?.blur()
        await changeEditingTextBox({textboxId: -2})
    }, [changeEditingTextBox, editingTextBoxRef, setUseColorPicker])

    const editRef = useClickOutside({
        handler: async () => {
            setIsAfterEscape(true)
            await escapeEditing()
        }
    })

    const innerRef = useRef<HTMLDivElement | null>(null);
    // 내부 ref와 forwarded ref를 함께 처리
    React.useImperativeHandle(ref, () => innerRef.current as HTMLDivElement);

    return (
        <div className={cn("reference-view-page-container", {
            focused: focusedPageIndex === index,
            over: isOver,
            'use-border': useBorder
        })}
             style={{
                 cursor: isActiveTextMemo ? "text" : "initial",
             }}
             key={`page_${index + 1}`}
             id={`page_${index + 1}`}

             ref={(node) => {
                drop(node);
                innerRef.current = node;
            }}

             data-textid={"page"}
             onClick={e => {
                 if(!isActiveTextMemo) {
                    return
                }
                 if(isAfterEscape) {
                     setIsAfterEscape(false)
                     return
                 }
                 createTextBox(e, index);
                 textNoteCreatedEvent(index);
             }}
        >
            {usePageIndex && <div className={"reference-view-page-container-index"}>
                {index + 1}
            </div>}
            <Page pageNumber={index + 1}
                  width={pageWidth}
                  onLoadSuccess={e => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const [_1, _2, w, h] = e._pageInfo.view
                      setPageHeight(Math.round(h * pageWidth / w))
                  }}
                  loading={<Skeleton width={pageWidth} height={pageWidth / 16 * 9}/>}
            />
            {textBoxes.map((textbox: Textbox & { isEdit?: boolean }) => {

                const isEditingTextBox = Boolean(textbox.isEdit)

                return <div id={`text-box-${textbox.id}`}
                            key={`text-box-${textbox.id}`}
                            {...(textbox.isEdit && {
                                ref: editRef,
                            })}
                            className={'text-box-container'}
                            style={{
                                top: textbox.yLocation / ratio + (isEditingTextBox ? movingVector.y : 0),
                                left: textbox.xLocation / ratio + (isEditingTextBox ? movingVector.x : 0),
                            }}
                            onClick={e => e.stopPropagation()}
                >
                    {isActiveHandle && <animated.div className={cn("text-box-handle")}

                                                     onClick={async () => {
                                                         await changeEditingTextBox({textboxId: textbox.id})
                                                     }}
                                                     onDoubleClick={async () => {
                                                         setIsActiveHandle(false)
                                                         editingTextBoxRef.current.select()
                                                     }}
                                                     style={{
                                                         width: textbox.textBoxWidth / ratio - 10,
                                                         height: textbox.textBoxHeight / ratio,
                                                         cursor: isEditingTextBox ? "all-scroll" : "initial"
                                                     }}
                                                     {...dragBind()}
                    />}
                    <TextareaAutosize
                        className={cn("text-box", {editing: isEditingTextBox})}
                        {...(textbox.isEdit && {
                            ref: editingTextBoxRef,
                        })}
                        style={{
                            width: textbox.textBoxWidth / ratio,
                            height: textbox.textBoxHeight / ratio,
                            fontSize: textbox.fontSize / ratio,
                            color: textbox.fontColor,
                            fontWeight: textbox.fontWeight,
                        }}
                        value={textbox.text}
                        onKeyDown={async (e) => {
                            if (e.key === "Escape") {
                                e.preventDefault()
                                await escapeEditing()
                                return
                            }
                            if (e.key === "Enter" && !e.shiftKey) {
                                if (e.nativeEvent.isComposing) {
                                    return;
                                }
                                e.preventDefault()
                                await escapeEditing()
                            }

                        }}
                        onChange={e => {
                            setEditingTextBox((prev: Textbox) => ({
                                ...prev,
                                text: e.target.value
                            }))
                            onChangeTextboxSize()
                        }}
                        onFocus={async () => {
                            await changeEditingTextBox({textboxId: textbox.id})
                            setIsKeyboardBlock(true)
                        }}
                        onMouseUp={onChangeTextboxSize}
                        onBlur={async () => {
                            setIsActiveHandle(true)
                            setIsKeyboardBlock(false)
                        }}
                    />
                    {isEditingTextBox && <>
                        <div className={'text-box-menu'}>
                            <Select className={"text-box-menu-font-size"}
                                    value={textbox.fontSize.toString()}
                                    onChange={(e) => {
                                        setEditingTextBox((prev: Textbox) => ({
                                            ...prev,
                                            fontSize: parseInt(e.target.value)
                                        }))
                                        setLastFont((prev: any) => ({...prev, fontSize: parseInt(e.target.value) }))
                                    }}
                                    options={[
                                        {label: "8", value: 8},
                                        {label: "12", value: 12},
                                        {label: "16", value: 16},
                                        {label: "20", value: 20},
                                        {label: "24", value: 24},
                                        {label: "32", value: 32},
                                        {label: "48", value: 48},
                                    ]}
                            />
                            <button className={"text-box-menu-color"} onClick={() => {
                                setUseColorPicker((prevState: boolean) => !prevState)
                            }}>
                                <div className={"text-box-menu-color-sample"}
                                     style={{backgroundColor: textbox.fontColor}}/>
                            </button>
                            {useColorPicker &&
                                <HexColorPicker className={"text-box-menu-color-picker"}
                                                color={textbox.fontColor}
                                                onChange={(color) => {
                                                    setEditingTextBox((prev: Textbox) => ({
                                                        ...prev,
                                                        fontColor: color
                                                    }))
                                                    setLastFont((prev: any) => ({...prev, fontColor: color }))
                                                }}
                                />}
                            <button
                                className={cn("text-box-menu-bold", {active: textbox.fontWeight === "bold"})}
                                onClick={() => {
                                    setEditingTextBox((prev: Textbox) => ({
                                        ...prev,
                                        fontWeight: prev.fontWeight === "normal" ? "bold" : "normal"
                                    }))
                                    setLastFont((prev: any) => ({...prev, fontWeight: prev.fontWeight === "normal" ? "bold" : "normal" }))
                                }}>
                                <RxFontBold/>
                            </button>
                            <button className={"text-box-menu-delete"}
                                    onClick={() => onDeleteTextBox({textboxId: textbox.id})}>
                                <MdDelete fill={"pink"}/>
                            </button>
                        </div>
                    </>}
                </div>
            })}
        </div>
    );
});

export default DroppablePageView;

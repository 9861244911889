import React from 'react';
import {TbDots} from "react-icons/tb";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import useClickOutside from "../../../hook/useClickOutside";
import './ItemMenu.scss'
import {useTranslation} from "react-i18next";

type ItemMenuProps = {
    active: boolean,
    onEditName: (e: any) => void,
    onDelete: (e: any) => void,
    style?: any
}

function ItemMenu({active, onEditName, onDelete, style}: ItemMenuProps) {

    const {t} = useTranslation('translation', {keyPrefix: "ItemMenu"});

    const [isActiveMenu, setIsActiveMenu] = React.useState<boolean>(false)

    const menuRef = useClickOutside({
        handler: () => {
            setTimeout(() => {
                setIsActiveMenu(false)
            }, 200)
        }
    })

    return {
        isActiveMenu,
        render: () => {
            if (active) {
                return <div className={"item-menu"} style={style}>
                    <button className={"item-menu-dots"} onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setIsActiveMenu(true)
                    }}>
                        <TbDots/>
                    </button>
                    {isActiveMenu && <div className={"item-menu-list"} ref={menuRef} >
                        <button className={"item-menu-button"}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    onEditName(e)
                                    setIsActiveMenu(false)
                                }}>
                            <AiOutlineEdit/>
                            <span className={"item-menu-button-label"}>{t('edit-name')}</span>
                        </button>
                        <button className={"item-menu-button"}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    onDelete(e)
                                    setIsActiveMenu(false)
                                }}
                        >
                            <AiOutlineDelete fill={'red'}/>
                            <span className={"item-menu-button-label red"}>{t('delete')}</span>
                        </button>
                    </div>}
                </div>
            }

            return null
        }
    }
}

export default ItemMenu;

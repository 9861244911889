import {storeDispatch} from "../redux";
import {activateSettingAPI, deactivateSettingAPI} from "../server/appUsers/settings";
import {removeActiveSetting, upsertActiveSettings} from "../redux/setting";


export const activateSetting = async ({settingId}: {settingId: number}) => {
    const {response, error} = await activateSettingAPI.request({body: {setting_id: settingId}})

    if (error) {
        return
    }

    storeDispatch(upsertActiveSettings([response]))
}

export const deactivateSetting = async ({activeSettingId}: {activeSettingId: number}) => {
    const {error} = await deactivateSettingAPI.request({params: {active_setting_id: activeSettingId}})

    if (error) {
        return
    }

    storeDispatch(removeActiveSetting(activeSettingId))
}

// src/view/component/tutorial/TutorialOverlay.tsx

import React, { useEffect, useRef, useState } from 'react';
import './TutorialOverlay.scss';
import {useTranslation} from "react-i18next";

import { useDispatch } from 'react-redux';

interface TutorialOverlayProps {
    content: string;
    onComplete: () => void;
    onSkip: () => void;
    targetRef: React.RefObject<HTMLElement>;
    tutorialName?: string; // 튜토리얼에 따라 위치 바꾸기 위해
}

const TutorialOverlay: React.FC<TutorialOverlayProps> = ({ content, onComplete, onSkip, targetRef, tutorialName }) => {
    
    const {t} = useTranslation("translation", {keyPrefix: "TutorialOverlay"})

    const overlayRef = useRef<HTMLDivElement>(null);
    const [, forceUpdate] = useState({});

    useEffect(() => {
        const updatePosition = () => {
            const targetElement = targetRef.current;
            const overlayElement = overlayRef.current;

            if (targetElement && overlayElement) {
                const rect = targetElement.getBoundingClientRect();
                const padding = 10; // 패딩 값 (조절 가능)

                // refControl 튜토리얼일 때는 오른쪽에 위치하도록 설정
                if (tutorialName === 'refControl') {
                  const adjustedLeft = rect.right + padding; // 오른쪽에 위치
                  overlayElement.style.setProperty('--target-top', `${rect.top - padding}px`);
                  overlayElement.style.setProperty('--content-left', `${adjustedLeft}px`);
              } else {
                  // 기본적으로는 아래쪽 위치
                  const adjustedLeft = rect.left - padding;
                  overlayElement.style.setProperty('--target-top', `${rect.top - padding}px`);
                  overlayElement.style.setProperty('--content-left', `${adjustedLeft}px`);
              }

              overlayElement.style.setProperty('--target-left', `${rect.left - padding}px`);
              overlayElement.style.setProperty('--target-width', `${rect.width + padding * 2}px`);
              overlayElement.style.setProperty('--target-height', `${rect.height + padding * 2}px`);
            }
        };

        updatePosition();

        window.addEventListener('resize', updatePosition);
        window.addEventListener('scroll', updatePosition);

        // 주기적으로 위치 업데이트(화면 크기 변경 시 함께 움직이도록) -> 성능 최적화가 필요할 수 있음
        const intervalId = setInterval(() => {
            updatePosition();
            forceUpdate({});
        }, 100);

        return () => {
            window.removeEventListener('resize', updatePosition);
            window.removeEventListener('scroll', updatePosition);
            clearInterval(intervalId);
        };
    }, [targetRef]);


    return (
        <div 
          className="tutorial-overlay" 
          ref={overlayRef}

          // 튜토리얼마다 위치를 다르게 하기 위함
          data-ref-control={tutorialName === 'refControl' ? "true" : "false"}  // refControl 튜토리얼일 때만 true
          data-note-drag={tutorialName === 'noteDrag' ? "true" : "false"}  // noteDrag 튜토리얼일 때만 true
          data-note-add={tutorialName === 'noteAdd' ? "true" : "false"}  // noteAdd 튜토리얼일 때만 true
        >
          <div className="tutorial-backdrop" />
          <div className="tutorial-focus" />
          <div className="tutorial-content">
            <p>{content.split('\\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                {i < content.split('\\n').length - 1 && <br />}
              </React.Fragment>
            ))}</p>
            <div className="button-container">
              <button onClick={onSkip} className="skip-button">{t('skip-all')}</button> {/* 모두 건너뛰기 */}
              <button onClick={onComplete} className="next-button">{t('next')}</button>   {/* 다음 */}
            </div>
          </div>
        </div>
      );
};

export default TutorialOverlay;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Button from "../../component-library/button/Button";
import back5secsIcon from "../../asset/image/backward-5-seconds.png";
import playIcon from "../../asset/image/play-icon.png";
import forward5secsIcon from "../../asset/image/forward-5-seconds.png";
import "./NoteAudioPlayer.scss";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import useAudio from "../../../hook/useAudio";
import audio_stop from "../../asset/image/audio-stop.png";
import {useDebouncedCallback} from "use-debounce";
import useTooltip from "../../../hook/useTooltip";
import {useTranslation} from "react-i18next";
import {startedNoteAudioAction} from "../../../action/noteAudioAction";
import {selectAllAudios} from "../../../redux/noteAudio";
import {
    continueRecordingEvent,
    timelineReplayEvent,
    spacebarReplayEvent,
    arrowKeyMoveEvent,
} from "../../../analytics/ga";
import {secondToTimestamp} from "../../../util/timeUtils";
import {RecordingSession} from "../../../redux/appNote";

type NoteAudioPlayerProps = {
    continueRecord: () => Promise<void>
    isKeyboardBlock: boolean
}

function NoteAudioPlayer({continueRecord, isKeyboardBlock}: NoteAudioPlayerProps) {

    const {t} = useTranslation('translation', {keyPrefix: "NoteAudioPlayer"});

    const noteAudioState = useSelector((state: RootState) => state.noteAudio)
    const audioList = useSelector(selectAllAudios)

    const [audioPlayProps, setAudioPlayProps] = useState<{
        targetAudioIndex: number,
        autoPlay: boolean,
        startTime: number,
    }>({
        targetAudioIndex: 0,
        autoPlay: false,
        startTime: 0
    })
    const [totalAudioTime, setTotalAudioTime] = useState(0)
    const [currentAudioTime, setCurrentAudioTime] = useState(0)
    const [mousePosition, setMousePosition] = useState(-1)
    const [isSmoothingTimeline, setIsSmoothingTimeline] = useState(false)

    const audioProps = useMemo(() => {
        return {
            url: audioList[audioPlayProps.targetAudioIndex]?.wholeAudioFilePath,
            onEnd: () => {
                if (audioPlayProps.targetAudioIndex < audioList.length - 1) {
                    setAudioPlayProps(prevState => ({
                        targetAudioIndex: prevState.targetAudioIndex + 1,
                        autoPlay: true,
                        startTime: 0,
                    }))
                } else if (audioPlayProps.targetAudioIndex === audioList.length - 1) {
                    setAudioPlayProps({
                        targetAudioIndex: 0,
                        autoPlay: false,
                        startTime: 0,
                    })
                }
            },
        }
    }, [audioList, audioPlayProps])

    const {audio, play, isPlaying, pause} = useAudio(audioProps)

    const calculateCurrentAudioTime = useCallback(() => {
        if (!audio || !isPlaying) {
            return
        }

        let time = 0

        for (let i = 0; i < audioPlayProps.targetAudioIndex; i++) {
            time += audioList[i].wholeAudioLength
        }

        setCurrentAudioTime(prev => {
            const newTime = time + Math.round(audio.currentTime * 1000)
            setIsSmoothingTimeline(Math.abs(newTime - prev) < 1500)
            return time + Math.round(audio.currentTime * 1000)
        })

    }, [audio, isPlaying, audioPlayProps.targetAudioIndex, audioList])


    const getMousePosition = useDebouncedCallback((width: number, mousePosition: number) => {
        setMousePosition(Math.round(mousePosition / width * 100))
    }, 10)

    const moveTimeline = useCallback((targetTime: number) => {
        let time = 0
        let index = 0

        for (let i = 0; i < audioList.length; i++) {
            if (audioList[i].wholeAudioLength + time > targetTime) {
                index = i
                break
            }
            time += audioList[i].wholeAudioLength
        }

        setAudioPlayProps({
            targetAudioIndex: index,
            autoPlay: true,
            startTime: targetTime - time
        })
    }, [audioList])

    const onClickTimeline = useCallback(async (e: any) => {
        if (!audio) {
            return
        }

        const targetTime = Math.floor(totalAudioTime * e.clientX / e.currentTarget.offsetWidth)
        moveTimeline(targetTime)
        timelineReplayEvent();  // 타임라인 클릭으로 재생하는 이벤트 트래킹

    }, [audio, moveTimeline, totalAudioTime])

    const onSpace = useCallback(() => {
        if (isPlaying) {
            setAudioPlayProps(prev => ({
                ...prev, autoPlay: false, startTime: (audio?.currentTime ?? 0) * 1000
            }))
            pause()
        } else {
            setAudioPlayProps(prev => ({
                ...prev, autoPlay: true
            }))
            play()
        }
    }, [audio, isPlaying, pause, play])

    const onPrev = useCallback(() => {
        moveTimeline(currentAudioTime - 5000)
    }, [currentAudioTime, moveTimeline])

    const onNext = useCallback(() => {
        moveTimeline(currentAudioTime + 5000)
    }, [currentAudioTime, moveTimeline])

    // 각 버튼에 툴팁 설정
    const backTooltip = useTooltip({
        modalComponent: ({ onInactive, ref }) => (
            <div ref={ref} className="tooltip">{t('backward')}    <span className="shortcut">[</span></div>  /* 5초 뒤로 */
        ),
    });

    const playTooltip = useTooltip({
        modalComponent: ({ onInactive, ref }) => (
            <div ref={ref} className="tooltip">
                {isPlaying ? (
                    <>{t('pause')} <span className="shortcut">space</span></> /* 일시정지 */
                ) : (
                    <>{t('play')} <span className="shortcut">space</span></> /* 재생 */
                )}
            </div>
        ),
    });

    const forwardTooltip = useTooltip({
        modalComponent: ({ onInactive, ref }) => (
            <div ref={ref} className="tooltip">{t('forward')}    <span className="shortcut">]</span></div>  /* 5초 앞으로 */
        ),
    });



    useEffect(() => {
        if (audioList.length > 0) {
            setTotalAudioTime(audioList.reduce((result: number, audio: RecordingSession) => result + audio.wholeAudioLength, 0))
        }
    }, [audioList]);

    useEffect(() => {
        let interval: NodeJS.Timeout

        if (isPlaying) {
            calculateCurrentAudioTime()
            interval = setInterval(() => {
                calculateCurrentAudioTime()
            }, 1000)
        }

        return () => clearInterval(interval)
    }, [calculateCurrentAudioTime, isPlaying])

    useEffect(() => {
        if (noteAudioState.audioStart) {
            setAudioPlayProps({
                targetAudioIndex: audioList.findIndex((audio) => audio.id === noteAudioState.targetAudioId),
                autoPlay: true,
                startTime: noteAudioState.startTime * 1000
            })
            startedNoteAudioAction()
        }
    }, [audioList, noteAudioState]);

    useEffect(() => {
        if (isPlaying) {
            return
        }
        if (!audioPlayProps.autoPlay) {
            return
        }
        play(Math.round(audioPlayProps?.startTime / 1000))
    }, [audioPlayProps, isPlaying, play])


    useEffect(() => {

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === ' ') {
                onSpace()
                spacebarReplayEvent();  // 스페이스바 트래킹
            } else if (e.key === '[') {
                onPrev()
                arrowKeyMoveEvent('left');  // 왼쪽 방향키 트래킹
            } else if (e.key === ']') {
                onNext()
                arrowKeyMoveEvent('right');  // 오른쪽 방향키 트래킹
            }
        }

        if (!isKeyboardBlock) {
            document.addEventListener('keydown', handleKeyDown)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [isKeyboardBlock, onNext, onPrev, onSpace, pause]);

    return (
        <div className={"note-audio-player"}>
            <div className={"note-audio-player-timeline"}
                 onMouseMove={(e) => {
                     getMousePosition(e.currentTarget?.offsetWidth ?? 0, e.clientX)
                 }}
                 onMouseUp={onClickTimeline}
                 onMouseLeave={() => {
                     setTimeout(() => {
                        setMousePosition(-1)
                     }, 200)
                 }}
            >
                {mousePosition >= 0 && <div className={"note-audio-player-timeline-tooltip"}
                                            style={{
                                                left: `${mousePosition}%`
                                            }}
                >
                    {secondToTimestamp(totalAudioTime * mousePosition / 100000)}
                </div>}
                <div className={"note-audio-player-timeline-bar"}
                     style={{
                         width: `${100 * currentAudioTime / totalAudioTime}%`,
                        ...(isSmoothingTimeline && {transition: 'width 1s linear'})
                     }}
                />
            </div>
            <div className={"note-audio-player-controller"}>

                <Button className={"note-audio-player-controller-to-recording-button"}
                        onClick={async () => {
                            continueRecordingEvent();  // '녹음 이어하기' 클릭 이벤트 트래킹
                            await continueRecord();
                        }}>
                    {t('re-recording')} {/* {'녹음 이어하기'} */}
                </Button>


                <button className={"note-audio-player-controller-button"}
                        onClick={onPrev}
                        onMouseEnter={backTooltip.onActive}
                        onMouseLeave={backTooltip.onInactive}
                >
                    {backTooltip.modalComponent}
                    <img className={"note-audio-player-controller-button-move-icon"}
                         src={back5secsIcon}
                         alt={"backward-5secs"}/>
                </button>

                <button className={"note-audio-player-controller-button"}
                        onClick={onSpace}
                        onMouseEnter={playTooltip.onActive}
                        onMouseLeave={playTooltip.onInactive}
                >
                    {playTooltip.modalComponent}
                    <img className={isPlaying ? "note-audio-player-controller-button-stop-icon" : "note-audio-player-controller-button-play-icon"}
                        src={isPlaying ? audio_stop : playIcon}
                        alt={isPlaying ? "stop" : "play"}/>
                </button>

                <button className={"note-audio-player-controller-button"}
                        onClick={onNext}
                        onMouseEnter={forwardTooltip.onActive}
                        onMouseLeave={forwardTooltip.onInactive}
                >
                    {forwardTooltip.modalComponent}
                    <img className={"note-audio-player-controller-button-move-icon"}
                         src={forward5secsIcon} alt={"forward-5secs"}/>
                </button>

                <div className={"note-audio-player-controller-time"}>
                    <span className={"note-audio-player-controller-time-current"}>
                        {secondToTimestamp(currentAudioTime / 1000)}
                    </span>
                    {" / "}
                    <span className={"note-audio-player-controller-time-total"}>
                        {secondToTimestamp(totalAudioTime / 1000)}
                    </span>
                </div>

            </div>
        </div>
    );
}

export default NoteAudioPlayer;

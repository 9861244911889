import React, {forwardRef, useRef} from 'react';
import NoteItem from "./NoteItem";
import {useDrag, useDrop} from "react-dnd";
import {updateNoteAction} from "../../../action/appNoteAction";
import {SubjectFromDB} from "../../../server/appNotes/type";

type DraggableNoteItemProps = {
    note: any,
}

const DraggableNoteItem = forwardRef<HTMLDivElement, DraggableNoteItemProps>(({ note }, ref) => {
    const innerRef = useRef<HTMLDivElement | null>(null);

    const [draggableStyle, drag] = useDrag(
        () => ({
            type: "note",
            item: { note },
            collect: (monitor: any) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        }),
        [note],
    )

    const [droppableStyle, drop] = useDrop({
        accept: ["subject"],
        drop: async (item: {subject: SubjectFromDB}) => {
            await updateNoteAction({noteId: note.id, subjectId: item.subject.id})
        },
        collect: (monitor) => ({
            opacity: monitor.isOver() ? 0.6 : 1,
        }),
    })

    // 내부 ref와 forwarded ref를 함께 처리
    React.useImperativeHandle(ref, () => innerRef.current as HTMLDivElement);

    return (
        <div ref={(node) => {
            drop(drag(node));
            innerRef.current = node;
        }} style={droppableStyle}>
            <div style={{ borderRadius: 15, ...draggableStyle }} data-testid={'noteItem'}>
                <NoteItem note={note} />
            </div>
        </div>
    );
});

export default DraggableNoteItem;
import React, {useCallback, useMemo, useState} from 'react';
import Modal from "../../component-library/modal/Modal";
import './SettingModal.scss';
import Button from "../../component-library/button/Button";
import {useTranslation} from "react-i18next";
import {googleLogoutAction} from "../../../action/appUserAction";
import cn from "classnames";
import SettingAccount from "./SettingAccount";
import SettingNote from "./SettingNote";
import SettingAlarm from "./SettingAlarm";
import {useNavigate} from "react-router-dom";

type SettingModalProps = {
    isOpen: boolean;
    onClose: () => void;
}

function SettingModal({isOpen, onClose}: SettingModalProps) {

    const {t: commonTranslation} = useTranslation('translation');
    const {t} = useTranslation('translation', {keyPrefix: "SettingModal"});

    const navigate = useNavigate()

    const [settingTab, setSettingTab] = useState<string>("account")

    const SETTING_TABS = useMemo(() => [
        {
            key: "account",
            label: t('my-account')
        },
        // {
        //     key: "alarm",
        //     label: t('alarm-setting')
        // },
    ], [t])

    const renderContent = useCallback(() => {
        switch (settingTab) {
            case "note":
                return <SettingNote />
            case "alarm":
                return <SettingAlarm />
            case "account":
            default:
                return <SettingAccount />
        }
    }, [settingTab])

    const onSignOut = useCallback(() => {
        googleLogoutAction()
        navigate('/')
    }, [navigate])

    return (
        <Modal isOpen={isOpen} onClose={onClose} className={"setting-modal"}>
            <div className={"setting-modal-nav"}>

                <div className={"setting-modal-nav-top"}>
                    {SETTING_TABS.map(tab => {
                        return <button className={cn("setting-modal-nav-tab-button", {active: settingTab === tab.key})}
                                        key={tab.key}
                                        onClick={() => setSettingTab(tab.key)}
                        >
                            {tab.label}
                        </button>
                    })}
                </div>

                <div className={"setting-modal-nav-bottom"}>
                    <Button className={"logout-button"} onClick={onSignOut}>{commonTranslation('logout')}</Button>
                </div>
            </div>
            <div className={"setting-modal-content"}>
                {renderContent()}
            </div>
        </Modal>
    );
}

export default SettingModal;

import React, {useEffect} from 'react';
import './ReferenceView.scss'
import {Document, pdfjs} from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import cn from "classnames";
import {BiArrowBack} from "react-icons/bi";
import DroppablePageView from "./DroppablePageView";
import {selectAllTextboxes} from "../../../redux/appNote";
import {DEFAULT_WIDTH} from "./ReferenceView";
import {useSelector} from "react-redux";
import useTextMemo from "../../../hook/useTextMemo";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type ReferenceFullscreenViewProps = {
    referenceFile?: File
    numPages: number
    focusedPageIndex: number
    onExitFullScreen: () => void
    pageRatio: number
    setIsKeyboardBlock: (args: any) => void
    innerWidth: number
}

function ReferenceFullScreenView({referenceFile, numPages, focusedPageIndex, onExitFullScreen, pageRatio, setIsKeyboardBlock, innerWidth}: ReferenceFullscreenViewProps) {

    const [pageWidth, setPageWidth] = React.useState<number>(DEFAULT_WIDTH)

    useEffect(() => {
        if (pageRatio * window.innerHeight < window.innerWidth) {
            setPageWidth(pageRatio * window.innerHeight)
        } else {
            setPageWidth(window.innerWidth)
        }
    }, [pageRatio, innerWidth]);

    const {
        ratio,
        editingTextBoxRef,
        onChangeTextboxSize,
        changeEditingTextBox,
        createTextBox,
        dragBind,
        editingTextBox,
        setEditingTextBox,
        movingVector,
        setLastFont,
        useColorPicker,
        setUseColorPicker
    } = useTextMemo({
        width: pageWidth,
    })

    const appNoteTextboxes = useSelector(selectAllTextboxes)

    return (
        <div className={"reference-fullscreen-view"} >
            <button className={"reference-fullscreen-view-back-button"}
                    onClick={onExitFullScreen}>
                <BiArrowBack />
            </button>
            <Document file={referenceFile}>
                {new Array(numPages).fill(0).map((_, index) => (
                    <div className={cn("reference-fullscreen-view-page", {focused: index === focusedPageIndex})}
                         key={`page_${index + 1}`}
                    >
                        <DroppablePageView
                            index={index}
                            pageWidth={pageWidth}
                            ratio={ratio}
                            textBoxes={[
                                ...appNoteTextboxes.filter(textbox => textbox.referenceFileIndex === index && textbox.id !== editingTextBox.id),
                                ...(editingTextBox.referenceFileIndex === index ? [{
                                    ...editingTextBox,
                                    isEdit: true
                                }] : [])
                            ]}
                            editingTextBoxRef={editingTextBoxRef}
                            setEditingTextBox={setEditingTextBox}
                            onChangeTextboxSize={onChangeTextboxSize}
                            changeEditingTextBox={changeEditingTextBox}
                            createTextBox={createTextBox}
                            movingVector={movingVector}
                            dragBind={dragBind}
                            focusedPageIndex={-1}
                            onDrop={(item) => {}}
                            setLastFont={setLastFont}
                            setPageHeight={(height) => {}}
                            setUseColorPicker={setUseColorPicker}
                            useColorPicker={useColorPicker}
                            useBorder={false}
                            usePageIndex={false}
                            setIsKeyboardBlock={setIsKeyboardBlock}
                        />
                    </div>
                ))}
            </Document>
        </div>
    );
}

export default ReferenceFullScreenView;

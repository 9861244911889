import React from 'react';
import './InfoTooltips.scss';
import i18n from "i18next"

function InfoTooltips({ onInactive, ref }: { onInactive: () => void; ref: any }) {

  return (
    <div className="tooltip-component" ref={ref}>
      <div className="tooltip-content">
        {i18n.t('SettingAccount.info')}
        {/* 학과를 입력하시면 더 높은 전공단어 인식률을 제공받을 수 있습니다. */}
      </div>
    </div>
  );
}

export default InfoTooltips;
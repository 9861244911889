import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import './NoteList.scss';
import {useTranslation} from "react-i18next";
import SearchBar from "../../component/SearchBar";
import DraggableNoteItem from "../../component/note/DraggableNoteItem";
import {clearNoteAction, selectNotesAction, updateSubjectAction} from "../../../action/appNoteAction";
import {useDispatch, useSelector} from "react-redux";
import {selectAllAppNotes} from "../../../redux/appNotes";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {selectAllSubjects, selectSubject} from "../../../redux/folderAndSubject";
import {RootState} from "../../../redux";
import {APP_USER_STATE} from "../../../redux/appUser";
import SignInModal from "../../component/modal/SignInModal";
import {useResponsive} from "../../style/Responsive";
import Skeleton from "react-loading-skeleton";
import Button from "../../component-library/button/Button";
import InputFit from "../../component-library/input/InputFit";
import {toast} from "react-toastify";
import {subjectRenamedEvent} from "../../../analytics/ga";
import addIcon from "../../asset/image/add-icon.png"
import Select from "../../component-library/input/Select";
import arrowDown from "../../asset/image/arrow-down.png";
import eventBanner from "../../asset/image/banner.jpg";


// 튜토리얼
import { setTutorialStep, skipAllTutorials } from "../../../redux/tutorialSlice";
import TutorialOverlay from "../../component/tutorial/TutorialOverlay";

const PAGING_LIMIT = 6;
const INITIAL_PAGING_OFFSET = 1;

const DUMMY_NOTE = {
    id: -999,
    name: "노티파이 시작하기",
    thumbnailS3Path: "https://notifai-prod-bucket.s3.ap-northeast-2.amazonaws.com/tutorial/thumbnail.png",
    subjectId: -999,
    createdAt: new Date(),
    updatedAt: new Date(),
}

function NoteList() {

    const { t: commonTranslation } = useTranslation('translation');
    const {t} = useTranslation('translation', {keyPrefix: "NoteList"});

    const {isMobile, isMobileDevice} = useResponsive()
    const {isOpen} = useOutletContext() as { isOpen: boolean };

    const navigate = useNavigate();

    const params = useParams()
    const subjectId = useMemo(() => {
        try {
            if (!params?.subjectId) return undefined
            return parseInt(params?.subjectId as string)
        } catch (e) {
            return undefined
        }
    }, [params])

    const appUser = useSelector((state: RootState) => state.appUser);
    const subjectList = useSelector(selectAllSubjects)
    const subject = useSelector((state: RootState) => selectSubject(state, subjectId as number))
    const allNotes = useSelector(selectAllAppNotes)

    const headerRef = useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = React.useState<number>(headerRef?.current?.offsetHeight ?? 300);

    const notes = useMemo(() => {
        return allNotes.filter((note) => {
            if (subjectId) {
                return note.subjectId === subjectId
            }
            return true
        })
    }, [allNotes, subjectId])

    // 튜토리얼
    const dispatch = useDispatch();

    // 튜토리얼 상태 가져오기
    const { currentStep, noteAdd, noteDrag } = useSelector((state: RootState) => state.tutorial);
    const newNoteButtonRef = useRef<HTMLButtonElement>(null);  // 새로운 노트 버튼을 참조하는 Ref (noteAdd 튜토리얼용)
    const firstNoteRef = useRef(null); // 첫 번째 노트 참조하는 Ref (noteDrag 튜토리얼용)

    // noteAdd 튜토리얼 완료 처리 함수
    const handleNoteAddCompleteTutorial = useCallback(() => {
        dispatch(setTutorialStep('noteAdd'));
      }, [dispatch]);

    // noteDrag 튜토리얼 완료 처리 함수
    const handleNoteDragCompleteTutorial = useCallback(() => {
        dispatch(setTutorialStep('noteDrag'));
    }, [dispatch]);

    // 모든 튜토리얼 건너뛰기 처리 함수
    const handleSkipTutorial = useCallback(() => {
        dispatch(skipAllTutorials());
    }, [dispatch]);

    const [pageNum, setPageNum] = React.useState<number>(INITIAL_PAGING_OFFSET)
    const [keyword, setKeyword] = React.useState<string>('')
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isLastPage, setIsLastPage] = React.useState<boolean>(false)
    const [isEmpty, setIsEmpty] = React.useState<boolean>(false)
    const [lastNotesLength, setLastNotesLength] = React.useState<number>(notes.length)
    const [subjectInputValue, setSubjectInputValue] = React.useState<string>('')

    useEffect(() => {
        if (subject?.name) {
            setSubjectInputValue(subject.name)
        }
    }, [subject?.name]);

    const onEditName = useCallback(async (e: any) => {

        e?.stopPropagation()
        e?.preventDefault()

        if (!subject?.name) {
            return
        }

        if (subjectInputValue === "" || subjectInputValue === "\n") {
            setSubjectInputValue(subject.name)
            toast.warning(t('empty-subject-name'));
            return
        }

        if (subjectInputValue.length > 20) {
            setSubjectInputValue(subject.name)
            toast.warning(t('subject-name-length'));
            return
        }

        if (subjectInputValue !== subject.name) {
            subjectRenamedEvent(subject.name, subjectInputValue);
            await updateSubjectAction({
                subjectId: subject.id,
                name: subjectInputValue,
                folderId: subject.folderId
            })
        }

    }, [subjectInputValue, subject, t])

    const selectNotes = useCallback(async () => {
        setIsLoading(true);
        if (isLastPage) return;
        if (appUser.state !== APP_USER_STATE.AUTHENTICATED) {
            setIsLoading(false);
            return;
        }
        const {numPages, notes: notesResult} = await selectNotesAction({
            ...(subjectId ? {subjectId} : {}),
            offset: pageNum,
            limit: PAGING_LIMIT,
            ...(keyword ? {keyword} : {}),
        });
        if (notesResult.length === 0 && pageNum === INITIAL_PAGING_OFFSET) {
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
        }
        if (pageNum >= numPages) {
            setIsLastPage(true);
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 100)
    }, [isLastPage, appUser, subjectId, pageNum, keyword])

    useEffect(() => {
        if (subjectId && !subject) {
            navigate('/')
        }
    }, [subjectId, subject, navigate]);


    useEffect(() => {
        setPageNum(INITIAL_PAGING_OFFSET);
        setIsLastPage(false);
        setIsLoading(false);
        setIsEmpty(false);
        setKeyword('');
    }, [subjectId]);

    useEffect(() => {
        setPageNum(INITIAL_PAGING_OFFSET);
        setIsLastPage(false);
        setIsLoading(false);
        setIsEmpty(false);
    }, [keyword]);

    useEffect(() => {

        const handleObserver = (entries: IntersectionObserverEntry[]) => {
            const target = entries[0];
            if (target.isIntersecting && !isLoading && !isLastPage) {
                setPageNum((prevPage) => prevPage + 1);
            }
        };

        const observer = new IntersectionObserver(handleObserver, {
            threshold: 0, //  Intersection Observer의 옵션, 0일 때는 교차점이 한 번만 발생해도 실행, 1은 모든 영역이 교차해야 콜백 함수가 실행.
        });
        // 최하단 요소를 관찰 대상으로 지정함
        const observerTarget = document.getElementById("observer");
        // 관찰 시작
        if (observerTarget) {
            observer.observe(observerTarget);
        }
        return () => observer.disconnect();
    }, [isLoading, isLastPage]);

    useEffect(() => {
        selectNotes();
    }, [selectNotes]);

    const [isSignInModalOpen, setIsSignInModalOpen] = React.useState<boolean>(false);

    const onCreateNote = useCallback(async () => {

        if (appUser.state !== APP_USER_STATE.AUTHENTICATED) {
            setIsSignInModalOpen(true);
            return;
        }

        // const newNote = (await createNoteAction("새로운 노트", subjectId)) as NoteFromDB;
        clearNoteAction();
        navigate(`/notes/new`, {state: {subjectId}});
    }, [appUser.state, navigate, subjectId])

    const onClickNewNote = useCallback(() => {
        if (subject) {
            // 과목이 있는 경우, 해당 과목에 새로운 노트를 할당하여 생성
            navigate(`/notes/new`, { state: { subjectId: subject.id } });
        } else {
            // 과목이 없는 경우 (all-notes), 과목이 할당되지 않은 새로운 노트를 생성
            navigate(`/notes/new`);
        }
    }, [navigate, subject]);  // subject와 navigate를 의존성 배열에 추가


    useEffect(() => {
        if (appUser.id) {
            setIsSignInModalOpen(false)
        }
        if (!appUser.id) {
            setIsSignInModalOpen(false)
        }
    }, [appUser])

    const [isRender, setIsRender] = React.useState<boolean>(true)

    useEffect(() => {
        if (isMobile) {
            setIsRender(false)
            setTimeout(() => {
                setIsRender(true)
            }, 10)
        }
    }, [isMobile, isOpen])

    useEffect(() => {
        setLastNotesLength(prev => {
            if (prev - 1 === notes.length && notes.length === 0) {
                setIsEmpty(true)
            }
            return notes.length
        })
    }, [notes]);

    useEffect(() => {
        const onResize = () => {
            setTimeout(() => {
                setHeaderHeight(headerRef?.current?.offsetHeight ?? 300)
            }, 1000)
        }

        onResize()
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, []);

    if (!isRender) {
        return null
    }

    return (
        <div id={"NoteList"} className={"note-list"}>
            {/* noteAdd 튜토리얼 */}
            {currentStep === 0 && !noteAdd && (
                <TutorialOverlay
                    content={t('note-add-tutorial')}  // 지금 바로 새로운 노트를 만들어 \\n 음성을 정리하세요
                    onComplete={handleNoteAddCompleteTutorial}
                    onSkip={handleSkipTutorial}
                    targetRef={newNoteButtonRef}
                    tutorialName={'noteAdd'}
                />
            )}
            <SignInModal isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)}/>

            <div className={"note-list-header"} ref={headerRef}>
                <div className={"note-list-title"}>
                    <div className="note-list-title-left">
                        <div className={"note-list-select-wrapper"}>
                            <img className={"note-list-select-icon"} src={arrowDown} alt={'select-subject'}/>
                            <Select className={"note-list-select"}
                                    onChange={(e) => {
                                        if (e.target.value === '0') {
                                            navigate(`/notes/list`)
                                            return
                                        }
                                        navigate(`/notes/list/subjects/${e.target.value}`)
                                    }}
                                    value={subjectId?.toString() ?? ''}
                                    options={[{id: 0, name: t("all-notes")}, ...subjectList]}
                                    valueKey={'id'}
                                    labelKey={'name'}
                            />
                        </div>
                        {subject ?
                            (<>
                                <div className={"note-list-title-dot"} style={{backgroundColor: subject.color}}/>
                                <InputFit
                                    className={"note-list-title-subject-input"}
                                    value={subjectInputValue}
                                    onChange={(e: any) => {
                                        const inputValue = e.target.value;
                                        // 20자 이상 입력하지 않도록 제한
                                        if (inputValue.length > 20) {
                                            return;
                                        }
                                        setSubjectInputValue(inputValue);
                                    }}
                                    onKeyDown={async (e: any) => {
                                        if (e.key === 'Enter') {
                                            await onEditName(e);
                                            e.target.blur();
                                        }
                                    }}
                                />
                            </>)
                            :
                            (<>
                                <span className={"note-list-title-icon"}>{"🗃"}</span>
                                <span className={"note-list-title-text"}>{t("all-notes")}</span>
                            </>)
                        }
                    </div>


                    <Button
                        onClick={onClickNewNote}
                        className={"new-note-button"}
                        ref={newNoteButtonRef}  // 새로운 노트 버튼에 Ref를 연결
                    >
                        <img src={addIcon} alt={'add'} className={"new-note-button-icon"}/>
                        <span className={"new-note-button-text"}>{commonTranslation("new-note")}</span>
                    </Button>
                </div>
                <a href={"https://forms.gle/edyz88b1hxALGrtq7"} target={"_blank"} rel={"noreferrer"}>
                    <img className={"note-list-banner"} src={eventBanner} alt={'survey-event-banner'} onLoad={() => {setHeaderHeight(headerRef?.current?.offsetHeight ?? 300)}}/>
                </a>
                <SearchBar keyword={keyword} setKeyword={setKeyword}/>
            </div>

            {appUser.state === APP_USER_STATE.AUTHENTICATED && notes.length === 0 && isLoading &&
                <div className={"note-list-body"}>
                    {new Array(6).fill(0).map((_, index) => <Skeleton key={`skul_${index}`} width={300} height={310}/>)}
                </div>}

            {((appUser.state === APP_USER_STATE.AUTHENTICATED && isEmpty)) && <div className={"note-list-empty"}>
                <div className={"note-list-empty-title"}>{t('empty-note')}</div>

                <Button className={"note-list-empty-new-note-button"} onClick={onCreateNote}>
                    {t('make-my-note')}
                </Button>
            </div>}

            {appUser.state === APP_USER_STATE.NOT_AUTHORIZED && <div className={"note-list-body"}>
                {[DUMMY_NOTE].map((note: any) => <DraggableNoteItem key={`note_${note.id}`} note={note}/>)}
            </div>}

            {notes.length > 0 && isRender && <div className={"note-list-body"} style={{
                ...(!isMobile && {height: `calc(100% - ${headerHeight}px)`})
            }}>
                {notes.map((note: any, index: number) => <DraggableNoteItem key={`note_${note.id}`} note={note} {...(index == 0 && {ref: firstNoteRef})}/>)}

                <div id="observer" style={{height: "10px"}}/>

                {/* noteDrag 튜토리얼 */}
                {currentStep === 3 && !noteDrag && (
                    <TutorialOverlay
                        content={t('note-drag-tutorial')} // 노트를 과목으로 \\n 드래그앤드롭 해주세요
                        onComplete={handleNoteDragCompleteTutorial}
                        onSkip={handleSkipTutorial}
                        targetRef={firstNoteRef}
                        tutorialName="noteDrag"
                    />
                )}
            </div>}
        </div>
    );
}

export default NoteList;

export const renameFile = (file: File) => {
    return new File([file], encodeURIComponent(file.name), {
        type: file.type,
        lastModified: file.lastModified,
    });
};

export const formatFileSize = (size: number): string => {
    if (size === 0) {
        return '0 B';
    }
    const i = Math.floor(Math.log(size) / Math.log(1000));
    // @ts-ignore
    return `${(size / Math.pow(1000, i)).toFixed(2) * 1} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
}

export const dataURItoBlob = (dataURI: string): Blob => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});
}

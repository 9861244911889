import dayjs from 'dayjs';

export const toLocalDateTime = (datetime: string | number) => {
    if (datetime === null) return dayjs().format('YYYY-MM-DDTHH:mm');
    return dayjs(datetime).format('YYYY-MM-DDTHH:mm');
};

export const UTCTimestamp = (date: string | number) => {
    // dayjs().utcOffset()
    const form = dayjs(date).format('YYYY-MM-DD HH:mm') + '(UTC)';
    if (form === 'Invalid date') return '';
    return form;
};

export const secondToTimestamp = (seconds: number) => {

    const dayjsObj = dayjs(dayjs().format("YYYY-MM-DD 00:00:00"))
        .add(seconds, 'second')

    if (seconds > 60 * 60) {
        return dayjsObj.format("HH:mm:ss")
    }

    return dayjsObj.format("mm:ss")
}

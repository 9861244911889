import React, {useEffect, useMemo, useState} from 'react';
import './NoteEditView.scss';
import ReferenceView from "../../component/note/ReferenceView";
import {animated} from "@react-spring/web";
import cn from "classnames";
import ScriptView from "../../component/note/ScriptView";
import {useDrag} from "@use-gesture/react";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {useSelector} from "react-redux";
import {RecordingSession, selectAllRecordingSessions} from "../../../redux/appNote";
import NoteAudioPlayer from "./NoteAudioPlayer";
import NoteRecorder from "./NoteRecorder";
import {Responsive, useResponsive} from "../../style/Responsive";
import NoteMemoView from "./NoteMemoView";
import {AudioFromDB} from "../../../server/appNotes/type";
import {selectAllAudios} from "../../../redux/noteAudio";

type NoteEditViewProps = {
    blocks: any[]
    setBlocks: (blocks: any) => void
    isRecording: boolean
    setIsRecording: (args: any) => void
    isKeyboardBlock: boolean
    setIsKeyboardBlock: (args: any) => void
    noteSourceLanguageId: number
    setNoteSourceLanguageId: (args: any) => void
}

const MIN_VIEW_WIDTH = 150;

function NoteEditView({
                          blocks,
                          setBlocks,
                          isRecording,
                          setIsRecording,
                          isKeyboardBlock,
                          setIsKeyboardBlock,
                          noteSourceLanguageId,
                          setNoteSourceLanguageId
                      }: NoteEditViewProps) {

    const {isMobile, isMobileDevice} = useResponsive()

    const note = useSelector((state: any) => state.appNote)
    const allAudios = useSelector(selectAllAudios)

    const initialLeftRatio = useMemo(() => (note?.referenceFileS3Path ? 0.66 : 0.5), [note])

    const [innerWidth, setInnerWidth] = useState(window.innerWidth)
    const [fixedLeftWidth, setFixedLeftWidth] = useState(
        Math.round(((window.innerWidth - 58) * initialLeftRatio))
    );
    const [leftWidth, setLeftWidth] = useState(
        Math.round(((window.innerWidth - 58) * initialLeftRatio))
    );

    const [focusedPageIndex, setFocusedPageIndex] = useState<any>(-1)

    const [isNewRecording, setIsNewRecording] = useState<boolean>(false)
    const [isLecture, setIsLecture] = useState<boolean>(false)

    const [recordingTimestamp, setRecordingTimestamp] = useState<number>(Math.round(allAudios
        .reduce((sum: number, audio: RecordingSession) => sum + audio.wholeAudioLength, 0) / 1000))

    const widthBind = useDrag(({active, movement}) => {

        const calculatedWidth = (left: number) => {
            return Math.max(Math.min(Math.round(left + movement[0]), window.innerWidth - MIN_VIEW_WIDTH), MIN_VIEW_WIDTH)
        }

        setLeftWidth(calculatedWidth(fixedLeftWidth))
        if (!active) {
            setFixedLeftWidth(prevState => calculatedWidth(prevState))
        }
    })

    const recordingSessionDataList = useSelector(selectAllRecordingSessions)

    useEffect(() => {
        if (note.id && recordingSessionDataList?.length === 0) {
            setIsLecture(true)
            setIsNewRecording(true)
        }
    }, [note, recordingSessionDataList]);

    useEffect(() => {
        const onResize = () => {
            setInnerWidth(window.innerWidth)
            setFixedLeftWidth(Math.round(window.innerWidth * initialLeftRatio))
            setLeftWidth(Math.round(window.innerWidth * initialLeftRatio))
        }

        onResize()
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, []);

    return (
        <div className={cn("note-edit-view", {"is-lecture": isLecture})}>
            <DndProvider backend={HTML5Backend}>
                <Responsive isDevice={false}
                            desktop={
                                <div className={"note-layout"}
                                     style={{gridTemplateColumns: `${leftWidth}px 6px 1fr`}}
                                     onClick={(e) => {
                                         e.stopPropagation()
                                     }}>
                                    {note?.referenceFileS3Path ?
                                        <ReferenceView referenceFile={note?.referenceFileS3Path}
                                                       blocks={blocks}
                                                       setBlocks={setBlocks}
                                                       width={fixedLeftWidth}
                                                       focusedPageIndex={focusedPageIndex}
                                                       setFocusedPageIndex={setFocusedPageIndex}
                                                       isKeyboardBlock={isKeyboardBlock}
                                                       setIsKeyboardBlock={setIsKeyboardBlock}
                                                       innerWidth={innerWidth}
                                        />
                                        :
                                        <NoteMemoView isRecording={isRecording}
                                                      setIsKeyboardBlock={setIsKeyboardBlock}
                                                      recordingTimestamp={recordingTimestamp}
                                        />
                                    }
                                    <animated.div
                                        className={cn("note-layout-vertical-border")}
                                        {...widthBind()}>
                                        <div className={"note-layout-vertical-border-line"}/>
                                    </animated.div>
                                    <ScriptView isRecording={isRecording}
                                                focusedPageIndex={focusedPageIndex}
                                                setFocusedPageIndex={setFocusedPageIndex}
                                                setIsKeyboardBlock={setIsKeyboardBlock}
                                                scriptWidth={innerWidth - fixedLeftWidth - 20}
                                    />
                                </div>
                            }
                            mobile={<ScriptView isRecording={isRecording}
                                                focusedPageIndex={focusedPageIndex}
                                                setFocusedPageIndex={setFocusedPageIndex}
                                                setIsKeyboardBlock={setIsKeyboardBlock}
                                                scriptWidth={innerWidth}
                            />}
                />
            </DndProvider>

            {
                isLecture ?
                    <NoteRecorder focusedPageIndex={focusedPageIndex}
                                  setIsLecture={setIsLecture}
                                  isRecording={isRecording}
                                  setIsRecording={setIsRecording}
                                  isNewRecording={isNewRecording}
                                  setIsNewRecording={setIsNewRecording}
                                  noteSourceLanguageId={noteSourceLanguageId}
                                  setNoteSourceLanguageId={setNoteSourceLanguageId}
                                  recordingTimestamp={recordingTimestamp}
                                  setRecordingTimestamp={setRecordingTimestamp}
                    />
                    :
                    <NoteAudioPlayer continueRecord={async () => {
                        setIsLecture(true)
                        setIsNewRecording(true)
                    }}
                                     isKeyboardBlock={isKeyboardBlock}
                    />
            }
        </div>
    )
        ;
}

export default NoteEditView;

import {useEffect} from 'react';

function useHideChannelTalk() {

    useEffect(() => {
        const ch = document.getElementById('ch-plugin-entry')
        if (ch) {
            ch.style.display = 'none';
        }

        return () => {
            if (ch) {
                ch.style.display = 'block';
            }
        }
    }, []);

    return
}

export default useHideChannelTalk;

import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";
import './Layout.scss';
import {refreshTokenAction} from "../../../action/appUserAction";
import {useSelector} from "react-redux";
import {selectAllLanguages} from "../../../redux/languages";
import {initLanguages} from "../../../action/languageAction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RootState} from "../../../redux";
import {APP_USER_STATE} from "../../../redux/appUser";
import MobileInfo from "../MobileInfo";

function Layout() {

    const languages = useSelector(selectAllLanguages)

    const appUser = useSelector((state: RootState) => state.appUser);

    useEffect(() => {
        if (appUser.state === APP_USER_STATE.DEFAULT) {
            refreshTokenAction();
        }
    }, [appUser]);

    useEffect(() => {
        if(languages.length === 0) {
            initLanguages()
        }
    }, [languages]);

    return (
        <div id={"Layout"}>
            <MobileInfo />
            {appUser.state !== APP_USER_STATE.DEFAULT && <Outlet />}
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                // transition: Bounce
            />
            {/*<OpenKakaoButton/>*/}
            {/*<Footer />*/}
        </div>
    );
}

export default Layout;

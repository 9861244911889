import React, {useCallback, useEffect, useState, useRef} from 'react';
import './NoteHeader.scss'
import backIcon from "../../asset/image/back-icon.png";
import InputFit from "../../component-library/input/InputFit";
import Button from "../../component-library/button/Button";
import NoteExportModal from "../modal/NoteExportModal";
import {useLocation, useNavigate} from "react-router-dom";
import {selectSubject} from "../../../redux/folderAndSubject";
import {toast} from "react-toastify";
import {updateNoteAction} from "../../../action/appNoteAction";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../redux";
import {selectAllRecordingSessions} from "../../../redux/appNote";
import "react-toggle/style.css";
import TutorialOverlay from "../../component/tutorial/TutorialOverlay";
import {setTutorialStep, skipAllTutorials} from "../../../redux/noteTutorialSlice";
import CommonButton from "../button/CommonButton";
import {Responsive} from "../../style/Responsive";
import {TbDots} from "react-icons/tb";
import useClickOutside from "../../../hook/useClickOutside";


type NoteHeaderProps = {
    setIsKeyboardBlock: (value: React.SetStateAction<boolean>) => void
    isRecording: boolean
    noteState?: number
    initialName?: string
    setName?: (value: React.SetStateAction<string>) => void
}


function NoteHeader({setIsKeyboardBlock, isRecording, noteState, initialName, setName}: NoteHeaderProps) {

    const {t, i18n} = useTranslation('translation', {keyPrefix: "NoteHeader"});

    // 튜토리얼
    const dispatch = useDispatch();

    // 튜토리얼 상태 가져오기
    const {currentStep, noteTitle} = useSelector((state: RootState) => state.noteTutorial);
    const noteTitleRef = useRef<HTMLInputElement>(null); // 노트 제목 입력창 ref

    // noteTitle 튜토리얼 완료 처리 함수
    const handlenoteTitleCompleteTutorial = useCallback(() => {
        dispatch(setTutorialStep('noteTitle'));
    }, [dispatch]);

    // 모든 튜토리얼 건너뛰기 처리 함수
    const handleSkipTutorial = useCallback(() => {
        // @ts-ignore
        dispatch(skipAllTutorials());
    }, [dispatch]);

    const navigate = useNavigate();

    const location = useLocation();

    const note = useSelector((state: RootState) => state.appNote)
    const subject = useSelector((state: RootState) => selectSubject(state, location?.state?.subjectId ?? note?.subjectId));
    const recordingSessionDataList = useSelector(selectAllRecordingSessions)

    const [editingName, setEditingName] = React.useState<string>(
        initialName ?
            initialName
            :
            note.name
    )
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const updateNote = useCallback(async () => {

        if (editingName === "" || editingName === "\n") {
            setEditingName(note.name)
            toast.warning(t('note-title-warn')); // "노트명을 입력해주세요."
            return
        }

        if (editingName.length > 30) {
            setEditingName(note.name)
            toast.warning(t('note-title-limit-warn')); // "노트명은 20자 이내로 입력해주세요."
            return
        }

        if (editingName === note.name) {
            return
        }

        await updateNoteAction({noteId: note.id, name: editingName})
    }, [editingName, note])

    const onChangeNoteName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEditingName(e.target.value)
    }, [])

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.nativeEvent.isComposing) {
            return;
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
        }
    };

    const dotMenuRef = useClickOutside({
        handler: () => {
            setIsMenuOpen(false)
        }
    })


    useEffect(() => {
        if (setName) {
            setName(editingName)
        }
    }, [editingName, setName]);

    useEffect(() => {
        if (note.name) {
            setEditingName(note.name)
        }
    }, [note.name]);

    return (
        <div className={"note-header"}>
            <div className={"note-header-front"}>
                <button className={"note-header-back-button"} onClick={() => {
                    navigate(-1)
                }}>
                    <img className={"note-header-back-button-icon"} src={backIcon} alt={"back"}/>
                </button>
                <div className={"note-header-path"}>
                    {subject && <div className={"note-header-path-subject"}>
                        <div className={"note-header-path-subject-dot"}
                             style={{backgroundColor: subject?.color}}/>
                        <div className={"note-header-path-subject-text"}>
                            {subject?.name ?? t('no-subject')} {/* "주제 없음" */}
                        </div>
                    </div>}
                    <InputFit className={"note-header-path-name"}
                              value={editingName}
                              onChange={onChangeNoteName}
                              onKeyDown={onKeyDown} // onKeyDown 핸들러 적용
                              onFocus={() => {
                                  setIsKeyboardBlock(true)
                              }}
                              onBlur={() => {
                                  setIsKeyboardBlock(false)
                                  updateNote()
                              }}
                              maxLength={30}
                              ref={noteTitleRef}

                    />
                </div>

                {/* noteTitle 튜토리얼*/}
                {!noteTitle && (
                    <TutorialOverlay
                        content={t('note-title-tutorial')} // "노트 제목을 바꾸려면 \\n 여기를 클릭해주세요"
                        onComplete={handlenoteTitleCompleteTutorial}
                        onSkip={handleSkipTutorial}
                        targetRef={noteTitleRef}
                        tutorialName="noteTitle"
                    />
                )}

            </div>
            <div className="note-header-back">

                <Responsive mobile={<div className={"note-header-mobile-menu"} ref={dotMenuRef}>
                                {recordingSessionDataList.length > 0 && !isRecording && <button onClick={() => {
                                    setIsMenuOpen(prev => !prev)
                                }}
                                        className={"note-header-mobile-menu-dots"}
                                >
                                    <TbDots/>
                                </button>}
                                {isMenuOpen && <div className={"note-header-mobile-menu-body"}>
                                    <NoteHeaderBack isRecording={isRecording}/>
                                </div>}
                            </div>}
                            desktop={<NoteHeaderBack isRecording={isRecording}/>}
                />
            </div>

        </div>
    );
}

export default NoteHeader;

type NoteHeaderBackProps = {
    isRecording: boolean
}

const NoteHeaderBack = ({isRecording}: NoteHeaderBackProps) => {

    const {t} = useTranslation('translation', {keyPrefix: "NoteHeader"});

    const navigate = useNavigate()

    const recordingSessionDataList = useSelector(selectAllRecordingSessions)

    const [isOptionsModalOpen, setIsOptionsModalOpen] = useState<boolean>(false);


    return (<>
        {recordingSessionDataList.length > 0 && !isRecording && <CommonButton
            className={"note-header-summary-button"}
            onClick={() => {
                navigate(`summary`)
            }}
        >
            {t('summary-note')}
        </CommonButton>}
        {recordingSessionDataList.length > 0 && !isRecording && <Button
            className={"note-header-export-button"}
            onClick={() => {
                setIsOptionsModalOpen(true)
            }}
        >
            {t('note-export')} {/* 노트 내보내기 */}
        </Button>}
        {isOptionsModalOpen && (
            <NoteExportModal
                isOpen={isOptionsModalOpen}
                onClose={() => setIsOptionsModalOpen(false)}
            />
        )}
    </>)
}

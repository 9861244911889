import * as pdfjsLib from 'pdfjs-dist';
import {getTextBoxesByPageIndexAction} from '../action/appNoteTextboxAction';
import {TextBoxFromDB} from '../server/appNotes/type';
import {selectAllTextboxes} from "../redux/appNote";
import {storeState} from "../redux";

const insertText = (
    ctx: CanvasRenderingContext2D,
    textBox: TextBoxFromDB,
    scaleFactors: { x: number; y: number; size: number; width: number; height: number }
) => {
    const {
        text,
        xLocation,
        yLocation,
        fontColor,
        fontSize,
        textBoxWidth,
        textBoxHeight,
        fontWeight,
    } = textBox;
    const defaultFontFamily = 'Nanum Gothic';

    const adjustedFontSize = fontSize * scaleFactors.size;
    const adjustedX = xLocation * scaleFactors.x;
    const adjustedY = yLocation * scaleFactors.y;
    const adjustedWidth = textBoxWidth * scaleFactors.width;
    const adjustedHeight = textBoxHeight * scaleFactors.height;

    ctx.font = `${fontWeight} ${adjustedFontSize}px ${defaultFontFamily}`;
    ctx.fillStyle = fontColor;
    ctx.textBaseline = 'top';
    ctx.textAlign = 'left';

    const lineHeight = adjustedFontSize * 1.2; // 줄 간격 조정
    let y = adjustedY;

    const paragraphs = text.split('\n'); // 텍스트 내의 줄바꿈 문자 처리

    for (let p = 0; p < paragraphs.length; p++) {
        const chars = paragraphs[p].split(''); // 글자 단위로 분할
        let line = '';

        for (let i = 0; i < chars.length; i++) {
            let testLine = line + chars[i];
            let metrics = ctx.measureText(testLine);
            let testWidth = metrics.width;

            if (testWidth > adjustedWidth && line !== '') {
                ctx.fillText(line, adjustedX, y);
                line = chars[i];
                y += lineHeight;

            } else {
                line = testLine;
            }
        }
        ctx.fillText(line, adjustedX, y);
        y += lineHeight;

        if (y - adjustedY + lineHeight > adjustedHeight) {
            return;
        }
    }
};


export const convertPdfToImages = async (
    pdfUrl: string,
    noteId: number
): Promise<{ pageImages: ArrayBuffer[]; validIndices: number[], width: number, height: number }> => {
    try {
        const pdfResponse: Response = await fetch(pdfUrl);
        if (!pdfResponse.ok) {
            throw new Error('PDF를 가져오는 데 실패했습니다.');
        }
        const pdfArrayBuffer: ArrayBuffer = await pdfResponse.arrayBuffer();

        const loadingTask: pdfjsLib.PDFDocumentLoadingTask = pdfjsLib.getDocument({
            data: pdfArrayBuffer,
        });
        const pdf: pdfjsLib.PDFDocumentProxy = await loadingTask.promise;

        const pageImages: ArrayBuffer[] = [];
        const validIndices: number[] = [];
        let width = 700;
        let height = 500;

        const allTextboxes = selectAllTextboxes(storeState())

        for (let pdfIndex = 1; pdfIndex <= pdf.numPages; pdfIndex++) {
            const page: pdfjsLib.PDFPageProxy = await pdf?.getPage(pdfIndex);
            const viewport: pdfjsLib.PageViewport = page.getViewport({scale: 2});
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            const context: CanvasRenderingContext2D | null = canvas.getContext('2d');

            if (!context) {
                throw new Error('캔버스 컨텍스트를 가져올 수 없습니다.');
            }

            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const requestIndex: number = pdfIndex - 1;
            width = viewport.width;
            height = viewport.height;

            validIndices.push(requestIndex);

            await page.render({canvasContext: context, viewport: viewport}).promise;

            const textBoxes: TextBoxFromDB[] = allTextboxes.filter((textbox: TextBoxFromDB) => textbox.referenceFileIndex === requestIndex)

            if (textBoxes) {
                const scaleFactors = {
                    x: (viewport.width / 960),
                    y: (viewport.width / 960),
                    size: canvas.width / 960,
                    width: canvas.width / 960,
                    height: canvas.width / 960,
                };

                for (const textBox of textBoxes) {
                    if (
                        textBox.text &&
                        typeof textBox.xLocation === 'number' &&
                        typeof textBox.yLocation === 'number' &&
                        textBox.fontColor &&
                        typeof textBox.fontSize === 'number' &&
                        typeof textBox.textBoxWidth === 'number' &&
                        typeof textBox.textBoxHeight === 'number'
                    ) {
                        insertText(context, textBox, scaleFactors);
                    } else {
                        console.warn('Invalid TextBoxFromDB:', textBox);
                    }
                }
            }

            const dataUrl: string = canvas.toDataURL('image/jpeg', 0.8);
            const res: Response = await fetch(dataUrl);
            const blob: Blob = await res.blob();
            const arrayBuffer: ArrayBuffer = await blob.arrayBuffer();

            pageImages.push(arrayBuffer);
        }

        return {pageImages, validIndices, width, height};
    } catch (error: unknown) {
        if (error instanceof Error) {
            throw new Error(
                error.message || 'PDF를 이미지로 변환하는 중 오류가 발생했습니다.'
            );
        } else {
            throw new Error(
                'PDF를 이미지로 변환하는 중 알 수 없는 오류가 발생했습니다.'
            );
        }
    }
};

import {API} from "../class";
import {NotifaiServer} from "../index";
import joi from "joi";
import {ActiveSettingFromDB, SettingFromDB} from "./type";


export const getSettingsAPI = new API<
      {},
      SettingFromDB[]
>({
    serverInstance: NotifaiServer,
    url: "/app-users/settings/",
    method: "get",
    requestValidator: joi.any(),
});


export const getActiveSettingsAPI = new API<
      {},
      ActiveSettingFromDB[]
>({
    serverInstance: NotifaiServer,
    url: "/app-users/settings/active/",
    method: "get",
    requestValidator: joi.any(),
});


export const activateSettingAPI = new API<
      {
        body: {
            setting_id: number,
        }
    },
      ActiveSettingFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-users/settings/active/",
    method: "post",
    requestValidator: joi.any(),
});


export const deactivateSettingAPI = new API<
      {
        params: {
            active_setting_id: number,
        }
    },
      ActiveSettingFromDB
>({
    serverInstance: NotifaiServer,
    url: "/app-users/settings/active/:active_setting_id/",
    method: "delete",
    requestValidator: joi.any(),
});
